// See documentation on doc/components/modal_dialogs.md
export default class Modal {
  constructor(options) {
    const id = options.id ?? `unique-modal-id-${Date.now()}`;
    const content = options.content ?? 'Missing content...';
    const title = options.title ?? 'Modal title';
    const headerContent = options.header ?? this.getDefaultHeaderHTML(title);
    const footerContent = options.footer ?? this.getDefaultFooterHTML();
    const size = options.size ?? '';
    const sizeClass = options.size !== '' ? `modal-${size}` : '';
    if (document.getElementById(id) === null) {
      $('body').append(this.getModalHTML(id, headerContent, content, footerContent, sizeClass));
    }
    return $(`#${id}`)
      .modal()
      .on('hidden.bs.modal', () => {
        $(`#${id}`).remove();
      });
  }

  // eslint-disable-next-line max-params
  getModalHTML(id, headerContent, bodyContent, footerContent, sizeClass) {
    const footer = footerContent === '' ? '' : `<div class="modal-footer">${footerContent}</div>`;
    const header = headerContent === '' ? '' : `<div class="modal-header">${headerContent}</div>`;
    return `
      <div id="${id}" class="modal fade" tabindex="-1" role="dialog">
        <div class="modal-dialog ${sizeClass}" role="document">
          <div class="modal-content">
            ${header}
            <div class="modal-body">
              ${bodyContent}
            </div>
            ${footer}            
          </div>
        </div>
      </div>
    `;
  }

  getDefaultFooterHTML() {
    return `
      <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
    `;
  }

  getDefaultHeaderHTML(title) {
    return `
      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
      <h4 class="modal-title">${title}</h4>
    `;
  }
}
