//Please consider reading doc/vue.md before reading this file (it is really fast to read !)

import Vue from 'vue';
import _ from 'lodash';

const getters = {
  getDraftBills(state) {
    return Object.keys(_.pickBy(state, (val) => val.status === 'draft'));
  },
  getBillTimesheetBasedPositions: (state) => (billId) => {
    const ts = _.pickBy(state[billId].positions, { type: 'timesheet-based' });
    const hidden = _.pickBy(state[billId].positions, { type: 'timesheet-hidden' });

    return { ...ts, ...hidden };
  },
};

const actions = {
  positionsReorderAction({ commit }, { positionsOrder, billId }) {
    return this.$publicApi.put(`/bills/${billId}/reorderPositions`, { positionsOrder: JSON.stringify(positionsOrder) }).then((response) => {
      commit('BILL_UPDATE', response.bill);
      return response;
    });
  },

  addPositionAction({ commit }, position) {
    return this.$publicApi.post('/positions', { ...position }).then((response) => {
      commit('BILL_UPDATE', response.bill);
      return response;
    });
  },

  deletePositionAction({ commit }, positionId) {
    return this.$publicApi.delete(`/positions/${positionId}`).then((response) => {
      commit('BILL_UPDATE', response.bill);
      return response;
    });
  },

  updatePositionAction({ commit }, position) {
    this.$publicApi.put(`/positions/${position.id}`, { ...position }).then((response) => {
      commit('BILL_UPDATE', response.bill);
      return response;
    });
  },

  transferPositionAction({ commit }, { positionId, destBillId }) {
    return this.$publicApi.put(`/positions/${positionId}/transferPosition`, { bill_id: destBillId }).then((response) => {
      for (const key in response.bills) {
        commit('BILL_UPDATE', response.bills[key]);
      }
      return response;
    });
  },

  refreshPositionAction({ commit }, position) {
    commit('POSITION_UPDATE', position);
  },

  refreshBillAction({ commit }, bill) {
    commit('BILL_UPDATE', bill);
  },
};

const mutations = {
  POSITIONS_REORDER(state, { posOrder, billId }) {
    for (const key in posOrder) {
      const pos = state[billId].positions[posOrder[key].id];
      pos.display_order = posOrder[key].display_order;
      Vue.set(state[billId].positions, posOrder[key].id, pos);
    }
  },

  POSITION_ADD(state, position) {
    if (!state[position.bill_id].hasOwnProperty('positions') || state[position.bill_id].positions.length === 0) {
      Vue.set(state[position.bill_id], 'positions', {});
    }
    Vue.set(state[position.bill_id].positions, position.id, position);
  },

  AMOUNT_UPDATE(state, position) {
    Vue.set(state[position.bill_id], 'amount_without_tax', position.parentBill.amount_without_tax);
    Vue.set(state[position.bill_id], 'amount_with_tax', position.parentBill.amount_with_tax);
  },

  POSITION_UPDATE(state, position) {
    Vue.set(state[position.bill_id].positions, position.id, position);
  },

  BILL_UPDATE(state, bill) {
    bill = _.cloneDeep(bill);
    Vue.set(state, bill.id, bill);
  },

  POSITION_REMOVE(state, position) {
    Vue.set(state[position.bill_id], 'amount', position.parentBill.amount);
    Vue.delete(state[position.bill_id].positions, position.id);
  },
};

export default {
  namespaced: true,
  getters,
  actions,
  state: function () {
    if (window.INITIAL_STATE === undefined) {
      return {};
    }
    return window.INITIAL_STATE.bills || {};
  },
  mutations,
};
