<template>
  <div>
    <div class="h6 media-heading">{{ item.entity_name }}</div>
    <ul class="list-inline">
      <li>{{ bill.project_name }}</li>
      <li>{{ bill.amount_without_tax | toCHF }}</li>
      <li>Status: {{ bill.status }}</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'BillItem',

  props: {
    item: {
      required: true,
    },
  },

  computed: {
    bill() {
      return this.item.entity_object;
    },
  },
};
</script>
