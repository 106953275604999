/* eslint-disable arrow-body-style */
export default class SalaryEvaluation {
  constructor() {
    $('[data-for]').click(function () {
      if ($(this).hasClass('inactive')) {
        $($(this).data('for')).prop('checked', true);
        $($(this).data('for')).trigger('change');
      }
    });

    $('input[type="radio"]').change(function () {
      const field = $(this).data('field');
      const fieldValue = $(this).data('value');
      const textField = document.getElementById(`${field}_comment`);
      textField.required = true;

      let cls = 'equal';
      if (fieldValue === '+') {
        cls = 'higher';
      }
      if (fieldValue === '-') {
        cls = 'lower';
      }

      $(`[data-field=${field}]`).addClass('inactive');
      $(`.${cls}[data-field=${field}]`).removeClass('inactive');

      const form = $('.evaluation-form');
      form.change(() => {
        $(window).bind('beforeunload', () => 'Are you sure you want to leave?');
      });
      form.submit(() => {
        $(window).unbind('beforeunload');
      });
    });

    const buttons = document.getElementsByClassName('show-more-liipers');
    for (const i in Object.keys(buttons)) {
      buttons[i].onclick = function () {
        $(`.${this.id}`).children('.hidden').slice(0, 4).removeClass('hidden');
      };
    }
  }
}
