export default class SatisfactionSurveyEvaluation {
  constructor(graphData) {
    const evalColor = '#ffb624';

    $('#evaluations-container').highcharts({
      chart: {
        alignTicks: false,
      },
      xAxis: {
        categories: graphData.months,
      },
      yAxis: [
        {
          min: 0,
          max: 5,
          gridLineWidth: 0,
          title: {
            text: 'Average Evaluations',
            style: {
              color: evalColor,
            },
          },
        },
        {
          min: 0,
          gridLineWidth: 0,
          title: {
            text: 'Feature reactions count',
          },
          opposite: true,
        },
      ],
      title: null,
      legend: {
        layout: 'horizontal',
        align: 'center',
        verticalAlign: 'bottom',
        borderWidth: 0,
      },
      plotOptions: {
        series: {
          marker: {
            enabled: false,
          },
        },
      },
      series: [
        {
          name: 'Avg. Evaluation',
          type: 'spline',
          data: graphData.average_score,
          yAxis: 0,
          color: evalColor,
          lineWidth: 5,
        },
        {
          name: 'Up reactions',
          type: 'column',
          data: graphData.up_reactions,
          yAxis: 1,
          pointWidth: 10,
        },
        {
          name: 'Love reactions',
          type: 'column',
          color: '#FF9BB2',
          data: graphData.love_reactions,
          yAxis: 1,
          pointWidth: 10,
        },
      ],
      credits: {
        enabled: false,
      },
    });
  }
}
