import icon from '@/legacy/common/templates/icon';

export default function (data) {
  const { id, name, adequacy, comment, product_id: productId } = data;

  return `
<tr data-tech-id="${id}">
  <td><a href="/technology/${id}">${name}</a></td>
  <td>
    <div class="tech-adequacy-rating thumb-rating" id="rateit_adequacy_${id}" data-rateit-value="${adequacy}"></div>
  </td>
  <td>
    <span class="editable" data-url="/technology/update-product-comment">${comment}</span>
  </td>
  <td class="text-nowrap">
    <a class="btn btn-link btn-xs table-action-remove"
      href="/technology/${id}/remove-from-product/${productId}"
      title="Remove technology from project">
      ${icon('trash')}
    </a>
    <a class="btn btn-link btn-xs table-action-move"
      href="/technology/${id}/archive-for-product/${productId}"
      title="Move to previously used">
      ${icon('arrow-right')}
    </a>
  </td>
</tr>
`;
}
