/**
 * Evaluate if a given timesheets can be accepted by the given position
 * WARNING Please be aware that this function share the exact same logic as the bill php
 * See localinc/zebra/model/project/bill.php
 */
export function isTimesheetAvailableForPosition(timesheet, position, bill)
{
  if (position.type !== 'timesheet-hidden' && bill.status !== 'draft') {
    return false;
  }
  return timesheet.bill_position_id === null && isTimesheetMatchPosition(timesheet, position);
}

/**
 * Evaluate if a given timesheets match the parameters of the given position
 * WARNING Please be aware that this function share the exact same logic as the bill php
 * See localinc/zebra/model/project/bill.php
 */
export function isTimesheetMatchPosition(timesheet, position) {
  if (timesheet.project_id !== position.project_id) {
    return false;
  }
  if (!['timesheet-based', 'timesheet-hidden'].includes(position.type)) {
    return false;
  }
  if (position.from !== null && timesheet.date < position.from) {
    return false;
  }
  if (position.to !== null && timesheet.date > position.to) {
    return false;
  }
  if (position.ignored_user_ids.includes(timesheet.userid)) {
    return false;
  }
  if (position.ignored_activity_ids.includes(timesheet.occupation_id)) {
    return false;
  }
  return true;
}
