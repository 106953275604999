export default function autoExpandTextarea(textarea) {
  adjustTextareaHeight(textarea);
  textarea.addEventListener('keyup', () => {
    adjustTextareaHeight(textarea);
  });
}

function adjustTextareaHeight(textarea) {
  // When an element is hidden, the size computation will be wrong, so we don't do anything
  if (!$(textarea).is(':visible')) {
    return;
  }

  textarea.style.height = 'inherit';
  const computed = window.getComputedStyle(textarea);
  const height =
    parseInt(computed.getPropertyValue('border-top-width'), 10) +
    parseInt(computed.getPropertyValue('padding-top'), 10) +
    textarea.scrollHeight +
    parseInt(computed.getPropertyValue('padding-bottom'), 10) +
    parseInt(computed.getPropertyValue('border-bottom-width'), 10);
  textarea.style.height = `${height}px`;
  textarea.style.minHeight = '30px';
}
