/* eslint-disable max-depth */
import Xhr from '@/vue/services/xhr';

(function ($) {
  'use strict';

  const ts = $.tablesorter;
  const output = (ts.output = {
    event: 'outputTable-',
    allowedTypes: ['json', 'csv', 'gsheet'],
    // Double click time is about 500ms; this value ignores double clicks
    // and prevents multiple windows from opening - issue in Firefox
    noDblClick: 600, // ms
    exportType: null,
    lastEvent: 0,
    busy: false,

    // wrap line breaks & tabs in quotes
    regexQuote: /([\n\t\x09\x0d\x0a]|<[^<]+>)/, // test if cell needs wrapping quotes
    regexBR: /(<br([\s/])?>|\n)/g, // replace
    regexIMG: /<img[^>]+alt\s*=\s*['"]([^'"]+)['"][^>]*>/i, // match
    regexHTML: /<[^<]+>/g, // replace

    replaceCR: '\x0d\x0a',
    replaceTab: '\x09',

    init: function (c) {
      for (let i = 0; i < this.allowedTypes.length; i++) {
        const type = this.allowedTypes[i];
        c.$table.off(`${output.event}${type}`).on(`${output.event}${type}`, (e) => {
          e.stopPropagation();
          // prevent multiple windows opening
          if (!output.busy && e.timeStamp - output.lastEvent > output.noDblClick) {
            output.lastEvent = e.timeStamp;
            output.exportType = type;
            output.busy = true;
            // explicitly use table.config.widgetOptions because we want
            // the most up-to-date values; not the 'wo' from initialization
            output.process(c, c.widgetOptions);
          }
        });
      }
    },

    processRow: function (c, $rows) {
      let $cell;
      let $cells;
      let cellsLen;
      let rowIndex;
      let row;
      let col;
      let index;
      let rowspanLen;
      let colspanLen;
      let txt;
      let cellIndex = 0;
      const wo = c.widgetOptions;
      const tmpRow = [];
      const dupe = wo.output_duplicateSpans;
      const rowsLength = $rows.length;

      for (rowIndex = 0; rowIndex < rowsLength; rowIndex++) {
        if (!tmpRow[rowIndex]) {
          tmpRow[rowIndex] = [];
        }
        cellIndex = 0;
        $cells = $rows.eq(rowIndex).children();
        cellsLen = $cells.length;
        for (index = 0; index < cellsLen; index++) {
          $cell = $cells.eq(index);
          // process rowspans
          if ($cell.filter('[rowspan]').length) {
            rowspanLen = parseInt($cell.attr('rowspan'), 10) - 1;
            txt = output.formatData(c, wo, $cell);
            for (row = 1; row <= rowspanLen; row++) {
              if (!tmpRow[rowIndex + row]) {
                tmpRow[rowIndex + row] = [];
              }
              tmpRow[rowIndex + row][cellIndex] = dupe ? txt : '';
            }
          }
          // process colspans
          if ($cell.filter('[colspan]').length) {
            colspanLen = parseInt($cell.attr('colspan'), 10) - 1;
            // allow data-attribute to be an empty string
            txt = output.formatData(c, wo, $cell);
            for (col = 0; col < colspanLen; col++) {
              // if we're processing the header & making JSON, the header names need to be unique
              if ($cell.filter('[rowspan]').length) {
                rowspanLen = parseInt($cell.attr('rowspan'), 10);
                for (row = 0; row < rowspanLen; row++) {
                  // eslint-disable-next-line max-depth
                  if (!tmpRow[rowIndex + row]) {
                    tmpRow[rowIndex + row] = [];
                  }
                  tmpRow[rowIndex + row][cellIndex + col] = dupe ? txt : '';
                }
              } else {
                tmpRow[rowIndex][cellIndex + col] = dupe ? txt : '';
              }
            }
          }

          // skip column if already defined
          while (typeof tmpRow[rowIndex][cellIndex] !== 'undefined') {
            cellIndex++;
          }

          tmpRow[rowIndex][cellIndex] = tmpRow[rowIndex][cellIndex] || output.formatData(c, wo, $cell, cellIndex);
          cellIndex++;
        }
      }
      return ts.output.removeColumns(c, wo, tmpRow);
    },

    // remove hidden/ignored columns
    removeColumns: function (c, wo, array) {
      let rowIndex;
      let row;
      let colIndex;
      const data = [];
      for (rowIndex = 0; rowIndex < array.length; rowIndex++) {
        row = array[rowIndex];
        data[rowIndex] = [];
        for (colIndex = 0; colIndex < c.columns; colIndex++) {
          data[rowIndex].push(row[colIndex]);
        }
      }
      return data;
    },

    process: function (c, wo, $rows) {
      let csvData;
      let tmp;
      let indx;
      const separator = 'json';
      const saveRows = wo.output_saveRows;
      const $el = c.$table;
      // regex to look for the set separator or HTML
      wo.output_regex = new RegExp(`(${/\\/.test(separator) ? '\\' : ''}${separator})`);

      // get header cells
      const $this = $el
        .children('thead')
        .children('tr')
        .not(`.${ts.css.filterRow || 'tablesorter-filter-row'}`)
        .filter(function () {
          return wo.output_hiddenColumns || $(this).css('display') !== 'none';
        });
      const headers = output.processRow(c, $this);

      // all tbody rows - do not include widget added rows (e.g. grouping widget headers)
      if (!$rows) {
        $rows = $el.children('tbody').children('tr').not(c.selectorRemove);
      }

      // check for a filter callback function first! because
      // /^f/.test(function() { console.log('test'); }) is TRUE! (function is converted to a string)
      /* eslint-disable no-nested-ternary */
      $rows =
        typeof saveRows === 'function'
          ? $rows.filter(saveRows)
          : // get (f)iltered, (v)isible, all rows (look for the first letter only), or jQuery filter selector
          /^f/.test(saveRows)
          ? $rows.not(`.${wo.filter_filteredRow || 'filtered'}`)
          : /^v/.test(saveRows)
          ? $rows.filter(':visible')
          : // look for '.' (class selector), '#' (id selector),
          // ':' (basic filters, e.g. ':not()') or '[' (attribute selector start)
          /^[.#:[]/.test(saveRows)
          ? $rows.filter(saveRows)
          : // default to all rows
            $rows;
      /* eslint-enable no-nested-ternary */

      // process to array of arrays
      csvData = output.processRow(c, $rows);
      if (wo.output_includeFooter) {
        // clone, to force the tfoot rows to the end of this selection of rows
        // otherwise they appear after the thead (the order in the HTML)
        csvData = csvData.concat(output.processRow(c, $el.children('tfoot').children('tr:visible')));
      }

      const len = headers.length;

      const tmpData = [];
      tmpData.push(headers[0]);
      const rowsLen = csvData.length;
      for (indx = 0; indx < rowsLen; indx++) {
        // multiple header rows & output_headerRows = true, pick the last row...
        tmp = headers[len > 1 && wo.output_headerRows ? indx % len : len - 1];
        tmpData.push(output.row2Hash(tmp, csvData[indx]));
      }

      output.download(c, wo, JSON.stringify(tmpData));

      output.busy = false;
    }, // end process

    row2Hash: function (keys, values) {
      let indx;
      const json = {};
      const len = values.length;
      for (indx = 0; indx < len; indx++) {
        if (indx < keys.length) {
          json[keys[indx]] = values[indx];
        }
      }
      return json;
    },

    formatData: function (c, wo, $el) {
      let txt = $el.attr('data-value') || $el.text();
      txt = txt.trim();
      let quotes = (wo.output_separator || ',').toLowerCase();
      const separator = quotes === 'json' || quotes === 'array';
      // replace " with “ if undefined
      let result = txt.replace(/"/g, wo.output_replaceQuote || '\u201c');
      // replace line breaks with \\n & tabs with \\t
      if (!wo.output_trimSpaces) {
        result = result.replace(output.regexBR, output.replaceCR).replace(/\t/g, output.replaceTab);
      } else {
        result = result.replace(output.regexBR, '');
      }
      // extract img alt text
      txt = result.match(output.regexIMG);
      if (!wo.output_includeHTML && txt !== null) {
        result = txt[1];
      }
      // replace/remove html
      result = wo.output_includeHTML ? result : result.replace(output.regexHTML, '');
      result = wo.output_trimSpaces ? $.trim(result) : result;
      // JSON & array outputs don't need quotes
      quotes = separator ? false : wo.output_wrapQuotes || wo.output_regex.test(result) || output.regexQuote.test(result);
      result = quotes ? `"${result}"` : result;

      return result;
    },

    download: function (c, wo, data) {
      const xhr = Xhr.getAPIInstance(true);

      data = JSON.parse(data);
      const output = Object.values(data).map((obj) => Object.keys(obj).map((key) => obj[key]));

      Zebra.notificationsCenter.show('info', 'Export creation, please wait...');
      if (this.exportType === 'gsheet') {
        xhr.post(`/exports?type=${this.exportType}&filename=${wo.output_saveFileName}`, { data: JSON.stringify(output) }).then((result) => {
          Zebra.notificationsCenter.show(
            'success',
            `Google sheet created in your personal Google Drive! Click <a href="${result}" target="_blank">here</a> to open it.`,
            { persistant: true, isHTML: true },
          );
        });
      } else {
        xhr
          .post(`/exports?type=${this.exportType}&filename=${wo.output_saveFileName}.${this.exportType}`, { data: JSON.stringify(output) })
          .then((result) => {
            let e;
            let blob;
            let gotBlob;
            let bom;
            const nav = window.navigator;
            const link = document.createElement('a');
            // test for blob support
            try {
              gotBlob = !!new Blob();
            } catch (err) {
              gotBlob = false;
            }

            // Use HTML5 Blob if browser supports it
            if (gotBlob) {
              window.URL = window.URL || window.webkitURL;
              // prepend BOM for UTF-8 XML and text/* types (including HTML)
              // note: your browser will automatically convert UTF-16 U+FEFF to EF BB BF
              // see https://github.com/eligrey/FileSaver.js/blob/master/FileSaver.js#L68
              bom = /^\s*(?:text\/\S*|application\/xml|\S*\/\S*\+xml)\s*;.*charset\s*=\s*utf-8/i.test(wo.output_encoding)
                ? ['\ufeff', result]
                : [result];
              blob = new Blob(bom, { type: wo.output_encoding });

              if (nav.msSaveBlob) {
                // IE 10+
                nav.msSaveBlob(blob, wo.output_saveFileName);
              } else {
                // all other browsers
                link.href = window.URL.createObjectURL(blob);
                link.download = `${wo.output_saveFileName}.${this.exportType}`;
                // Dispatching click event; using $(link).trigger() won't work
                if (document.createEvent) {
                  e = document.createEvent('MouseEvents');
                  // event.initMouseEvent(type, canBubble, cancelable, view, detail, screenX, screenY, clientX, clientY,
                  // ctrlKey, altKey, shiftKey, metaKey, button, relatedTarget);
                  e.initMouseEvent('click', true, true, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
                  link.dispatchEvent(e);
                }
              }
              return false;
            }
          });
      }

      return true;
    },

    remove: function (c) {
      c.$table.off(output.event);
    },
  });

  ts.addWidget({
    id: 'outputzebra',
    options: {
      output_separator: 'json', // set to 'json', 'array' or any separator
      output_includeFooter: false, // include footer rows in the output
      output_includeHeader: true, // include header rows in the output
      output_saveRows: 'filtered', // (a)ll, (v)isible, (f)iltered or jQuery filter selector
      output_duplicateSpans: true, // duplicate output data in tbody colspan/rowspan
      output_replaceQuote: '\u201c;', // left double quote
      output_includeHTML: false,
      output_trimSpaces: true,
      output_wrapQuotes: false,
      output_saveFileName: 'zebra-export',
    },
    init: function (table, thisWidget, c) {
      output.init(c);
    },
    remove: function (table, c) {
      output.remove(c);
    },
  });
})(jQuery);
