import { Textarea, Textcomplete } from 'textcomplete';
import _ from 'lodash';
import autoExpandTextarea from '@/js/utils/autoExpandTextarea';

export default class AutoUpdate {
  constructor(id, placeholders) {
    this.id = id;
    // TODO Maybe move icon definition into the PHP model
    Object.keys(placeholders).forEach((name) => {
      placeholders[name].icon = '⚙️';
      if (name.includes('_CHF')) {
        placeholders[name].icon = '💵';
      }
      if (name.includes('_H')) {
        placeholders[name].icon = '⏱';
      }
      if (name.includes('_WD')) {
        placeholders[name].icon = '📆';
      }
    });
    this.placeholders = placeholders;
    this.templateTextarea = document.querySelector(`#template-text-${id}`);
    this.previewDiv = document.querySelector(`#preview-zone-${id}`);
    this.addTextComplete(placeholders);
    this.templateTextarea.addEventListener('input', _.debounce(this.getPreview.bind(this), 300));
    autoExpandTextarea(this.templateTextarea);
  }

  getPreview() {
    $.post(`/auto-update/${this.id}/preview-text`, { text: this.templateTextarea.value }, (html) => {
      this.previewDiv.innerHTML = html;
    });
  }

  getMatchingPlaceholders(term) {
    term = term.toLowerCase();
    const results = [];
    Object.keys(this.placeholders).forEach((name) => {
      if (name.toLowerCase().includes(term) || this.placeholders[name].selector_name.toLowerCase().includes(term)) {
        results.push(this.placeholders[name]);
      }
    });
    return results;
  }

  addTextComplete() {
    const editor = new Textarea(this.templateTextarea);
    const textComplete = new Textcomplete(editor, { dropdown: { maxCount: 100 } });
    textComplete.register([
      {
        match: /\B{([-+\w]*)$/,
        search: (term, callback, match) => {
          term = match[1]; // I don't know why by the received term is always undefined
          callback(this.getMatchingPlaceholders(term));
        },
        replace(placeholder) {
          return `{${placeholder.name}} `;
        },
        template: (placeholder) =>
          `${placeholder.icon}&nbsp;&nbsp;${placeholder.name} | <b>${placeholder.selector_name}</b> | <small>${placeholder.value}</small>`,
      },
    ]);
  }
}
