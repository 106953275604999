import getColorForString from '@/js/utils/getColorForString';
import Highcharts from 'highcharts';

export default class CircleWorkExperience {
  constructor() {
    const rtData = [{ data: [] }];
    const rtTable = $('#role-type-evolution');
    const rtlength = rtTable.find('thead th').length - 1;

    rtTable.find('tbody tr').each(function () {
      const dateParts = $(this).find('td:nth-child(1)').text().split('-');
      const date = Date.UTC(dateParts[0], dateParts[1], 0);

      $(this)
        .find('td')
        .each(function (j) {
          const index = j - 1;

          // skip colunm Month
          if (j === 0) {
            return;
          }

          if (!rtData[index]) {
            rtData[index] = { data: [] };
          }

          rtData[index].data.push([date, parseFloat($(this).text())]);
        });
    });

    rtTable.find('thead th').each(function (i) {
      // skip column Month and Total
      if (i === 0 || i === rtlength) {
        return;
      }
      const index = i - 1;
      rtData[index].name = $(this).text();
      rtData[index].color = getColorForString($(this).text());
      rtData[index].data.sort();
    });

    this.rtData = rtData;
    this.initCumulativeHoursWorkGraph();
  }

  // based on https://www.highcharts.com/demo/area-stacked
  initCumulativeHoursWorkGraph() {
    const config = {
      chart: {
        type: 'area',
        events: {
          load: function () {
            const chart = this;
            const absButton = chart.renderer.button('Absolute [h]', chart.plotLeft, 10, () => buildChart('normal')).add();
            chart.renderer.button('Proportion [%]', chart.plotLeft + absButton.width + 10, 10, () => buildChart('percentage')).add();
          },
        },
      },
      title: {
        text: 'Role types evolution on 5 years',
      },
      subtitle: {
        text: 'On the top left, you can switch between absolute or percentage representation',
      },
      xAxis: {
        type: 'datetime',
      },
      yAxis: {
        title: {}, // Defined later in the buildChart function
        labels: {}, // Defined later in the buildChart function
      },
      plotOptions: {
        series: {
          stacking: null, // Defined later in the buildChart function
        },
        area: {
          marker: {
            enabled: false,
            symbol: 'circle',
            radius: 2,
            states: {
              hover: {
                enabled: true,
              },
            },
          },
        },
      },
      series: this.rtData,
      credits: false,
    };

    function buildChart(mode) {
      if (mode === 'percentage') {
        config.plotOptions.series.stacking = 'percent';
        config.yAxis.labels.format = '{value} %';
        config.yAxis.title.text = 'Proportion';
      }
      if (mode === 'normal') {
        config.plotOptions.series.stacking = 'normal';
        config.yAxis.labels.format = '{value} h';
        config.yAxis.title.text = 'Hours';
      }
      // https://stackoverflow.com/a/53704830
      setTimeout(function () {
        Highcharts.chart('role-types-evolution', config); // Redisplay all
      }, 20);
    }
    buildChart('normal');
  }
}
