<template>
  <div>
    <div class="h6 media-heading">{{ training.title }}</div>
    <div>Track: {{ training.track }}</div>
  </div>
</template>

<script>
export default {
  name: 'TrainingItem',

  props: {
    item: {
      required: true,
    },
  },

  computed: {
    training() {
      return this.item.entity_object;
    },
  },
};
</script>
