<template>
  <div>
    <modal title="Create a new product" append-to-body v-model="open" @hide="close()">
      <div v-if="!loading">
        <p>
          A product is a web/mobile object conceived/implemented/maintained by Liipers for a given client. It is bound to one or several
          implementation projects.
        </p>
        <div class="form-group">
          <label class="control-label label-required">Name</label>
          <input type="text" class="form-control label-required" v-model="formData.name" required />
        </div>
        <div class="form-group">
          <label class="control-label label-required">Description</label>
          <textarea v-model="formData.description" class="form-control"></textarea>
          <p class="help-block">Please give some detail such as: Main product goals, website urls, project wiki urls, etc...</p>
        </div>
      </div>
      <div v-else>
        <Loader></Loader>
      </div>
      <div slot="footer">
        <div v-if="!loading">
          <button class="btn btn-primary pull-right" @click="save()">Create</button>
          <button class="btn pull-right btn-default mrgr-" @click="close()">Cancel</button>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { Modal } from 'uiv';
import Loader from '../Loader';

export default {
  name: 'ProductCreation',

  components: { Loader, Modal },

  props: {
    name: {
      required: true,
    },
  },

  mounted() {
    this.formData.name = this.name;
  },

  data() {
    return {
      open: true,
      loading: false,
      formData: {
        name: '',
        description: '',
      },
    };
  },

  methods: {
    close() {
      this.open = false;
      this.$emit('close');
    },
    save() {
      this.loading = true;
      this.$publicApi
        .post('/products', {
          name: this.formData.name,
          description: this.formData.description,
        })
        .then((entity) => {
          this.open = false;
          this.$emit('created', entity);
        })
        .catch(() => {
          // There is nothing to do as the error message is automatically displayed
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>
