import icon from '@/legacy/common/templates/icon';

export default function (data) {
  const { id, name, category, expertise, feeling, comment } = data;

  return `
<tr data-tech-id="${id}">
  <td><a href="/technology/${id}">${name}</a></td>
  <td>${category}</td>
  <td>
    <div class="tech-expertise-rating star-rating" id="rateit_expertise_${id}" data-rateit-value="${expertise}"></div>
  </td>
  <td>
    <div class="tech-feeling-rating thumb-rating" id="rateit_feeling_${id}" data-rateit-value="${feeling}"></div>
  </td>
  <td>
    <span class="tech-comment" data-url="/technology/${id}/update-user-comment">${comment || ''}</span>
  </td>
  <td width="1">
    <a class="table-action-remove btn btn-link btn-xs" title="archive" href="/technology/${id}/archive-from-user">
      ${icon('archive')}
    </a>
  </td>
</tr>
`;
}
