import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    this.selectInstance({ target: this.element.querySelector('select') });
  }

  selectInstance(e) {
    const docButton = document.querySelector('.doc-button');
    const queryForm = document.querySelector('.js-queries');
    if (e.target.value === '') {
      docButton.style.visibility = 'hidden';
      queryForm.style.visibility = 'hidden';
    } else {
      docButton.style.visibility = 'visible';
      queryForm.style.visibility = 'visible';
    }
    const instanceType = e.target.options[e.target.selectedIndex].getAttribute('data-type');

    const urlTemplate = docButton.getAttribute('data-urlTemplate');
    const titleTemplate = docButton.getAttribute('data-titleTemplate');

    docButton.setAttribute('href', urlTemplate.replace('{type}', instanceType));
    docButton.setAttribute('data-modal-title', titleTemplate.replace('{type}', instanceType));
  }
}
