<template>
  <div>
    <div class="h6 media-heading">{{ item.entity_name }}</div>
    <div>{{ contact.company_name }}</div>
  </div>
</template>

<script>
export default {
  name: 'ContactItem',

  props: {
    item: {
      required: true,
    },
  },

  computed: {
    contact() {
      return this.item.entity_object;
    },
  },
};
</script>
