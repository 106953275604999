import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['type', 'status'];

  connect() {
    this.selectType({});
  }

  selectType() {
    const allTypes = JSON.parse(window.types);

    const selectedType = this.typeTarget.value;
    const selectedStatus = this.statusTarget.value;

    if (!allTypes[selectedType]) {
      return;
    }
    const selectedProperties = allTypes[selectedType];
    if (selectedStatus !== '') {
      const statusProperties = allTypes[selectedStatus];

      Object.keys(statusProperties).forEach((key) => {
        if (statusProperties[key] !== null) {
          selectedProperties[key] = statusProperties[key];
        }
      });
    }

    const fields = Object.keys(selectedProperties);

    const endDateMandatory = selectedProperties.needs_end_date;

    if (endDateMandatory) {
      document.querySelector('label[for="to-date"]').classList.add('label-required');
      document.getElementById('to-date').required = true;
    } else {
      document.querySelector('label[for="to-date"]').classList.remove('label-required');
      document.getElementById('to-date').required = false;
    }

    const responsibleMandatory = selectedProperties.needs_responsible;

    document.getElementById('responsible_id').required = !!responsibleMandatory;

    fields.forEach((field) => {
      const blocks = document.querySelectorAll(`[data-showif="${field}"]`);
      blocks.forEach((block) => {
        if (selectedProperties[field] === 0 || selectedProperties[field] === null) {
          block.style.display = 'none';
        } else {
          block.style.display = 'block';
        }
      });
    });
  }
}
