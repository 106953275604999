export default class HoursBreakdown {
  constructor(data) {
    this.breakdown = data;
    this.hoursBreakdownGraph('breakdown_container', 'normal');
  }

  hoursBreakdownGraph(container, stacking) {
    const $container = $(`#${container}`);
    $container.highcharts({
      title: {
        text: '',
      },
      chart: {
        type: 'area',
      },
      xAxis: {
        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        title: {
          text: 'month',
        },
        tickmarkPlacement: 'on',
      },
      yAxis: {
        min: 0,
        title: {
          text: 'Hours',
        },
      },
      tooltip: {
        split: true,
        valueSuffix: ' hours',
      },

      plotOptions: {
        area: {
          stacking: stacking,
          lineColor: '#666666',
          lineWidth: 1,
          marker: {
            lineWidth: 1,
            lineColor: '#666666',
          },
        },
      },
      series: Object.values(this.breakdown),
    });
  }
}
