import Highcharts from 'highcharts';
import _ from 'lodash';
import getColorForString from '@/js/utils/getColorForString';

export default class CircleComposition {
  constructor() {
    const data = _.map(document.querySelectorAll('.circle-composition-table tbody tr'), (node) => {
      const categoryLabel = node.querySelector('.category-label').textContent.replace('*', '');
      const percentEffectiveHours = node.querySelector('.percent-effective-hours').textContent.replace('%', '');

      return {
        name: categoryLabel,
        y: parseFloat(percentEffectiveHours),
        color: getColorForString(categoryLabel),
      };
    });
    if (data.length > 0) {
      Highcharts.chart('composition-chart', {
        chart: {
          type: 'pie',
          height: 400,
        },
        title: { text: null },
        plotOptions: {
          pie: {
            allowPointSelect: true,
          },
        },
        series: [
          {
            name: 'Percentage',
            colorByPoint: true,
            data,
          },
        ],
        credits: false,
      });
    }
  }
}
