export default class activityRateStats {
  constructor(data) {
    this.data = data;
    this.months = data.months;
    this.years = data.years;
    this.monthlyStats = data.monthlyStats;
    this.yearlyStats = data.yearlyStats;
    this.globalNumbers = data.globalNumbers;
    this.yearlyStats = data.yearlyStats;
    this.initActivityRateByMonthGraph();
  }

  initActivityRateByMonthGraph() {
    const months = this.months;
    const monthlyStats = this.monthlyStats;
    const yearlyStats = this.yearlyStats;
    const $container = $('#activity_rate_by_month_container');
    $container.highcharts({
      title: {
        text: 'Monthly activity rate',
      },
      xAxis: {
        categories: months,
        title: {
          text: 'month',
        },
        tickmarkPlacement: 'on',
      },
      yAxis: {
        min: 0,
        title: {
          text: '% of activity',
        },
      },
      tooltip: {
        pointFormat: '{point.y:.2f}%',
        useHTML: true,
      },

      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0,
        },
        spline: {
          marker: {
            enabled: false,
          },
        },
      },
      series: [
        {
          name: 'Average yearly rate',
          data: yearlyStats,
          step: 'center',
          type: 'areaspline',
          color: 'rgba(0,196,255,0.4)',
          fillColor: {
            linearGradient: {
              x1: 0,
              y1: 0,
              x2: 0,
              y2: 1,
            },
            dashStyle: 'shortdash', // Style of the plot line. Default to solid
            stops: [
              [0, 'rgba(0,196,255,0.2)'],
              [1, 'transparent'],
            ],
          },
        },
        {
          name: 'Monthly rate',
          color: 'rgba(0,0,0)',
          data: monthlyStats,
        },
      ],
    });
  }
}
