import { formatNumber } from '@/js/utils/formatter';

/* eslint-disable no-nested-ternary */
/* eslint-disable no-confusing-arrow */
export default class Circle {
  constructor(hasRecommendation) {
    this.initEditables();
    this.toggleRecommendations(hasRecommendation);
    this.addEventListeners();
  }

  addEventListeners() {
    $('.add-activity-selector').change(function () {
      $(this).closest('form').submit();
    });
    const findRecommendationsButton = document.querySelector('#display_recommendations');
    if (findRecommendationsButton !== null) {
      findRecommendationsButton.addEventListener('change', (e) => this.toggleRecommendations(e.target.checked));
    } else {
      this.toggleRecommendations(false);
    }
  }

  toggleRecommendations(checked) {
    document.querySelectorAll('.js-recommendation').forEach((r) => (checked ? r.classList.remove('hidden') : r.classList.add('hidden')));
    document.querySelectorAll('.js-recommendation-colspan-3').forEach((r) => {
      r.colSpan = checked ? 0 : 3;
      checked ? r.classList.remove('col-separator') : r.classList.add('col-separator');
    });
  }

  /* WARNING this logic is duplicated into /templates/holacracy/_diff_label.html.twig */
  updateDiffLabel(element, planned, current) {
    if (isNaN(planned) || isNaN(current) || planned === 0) {
      return;
    }
    const diff = current - planned;
    const percentage = (diff / planned) * 100;
    const classSuffix = Math.abs(percentage) < 20 ? 'info' : Math.abs(percentage) < 40 ? 'warning' : 'danger';
    const newText = `${(percentage > 0 ? '+' : '') + formatNumber(percentage)}%`;
    if (element.text().trim() !== newText) {
      element.text(newText).removeClass('label-info label-warning label-danger').addClass(`label-${classSuffix}`).closest('td').highlight();
    }
  }

  initEditables() {
    $('.editable').each((i, el) => {
      /* Why on hidden? See https://github.com/vitalets/x-editable/issues/958 */
      $(el).on('hidden', this.handleEditableChange.bind(this));
    });
  }

  handleEditableChange(e, reason) {
    if (reason !== 'save') {
      return;
    }

    const $field = $(e.currentTarget);
    let sum = 0;

    /* Update the total of a role */
    if ($field.hasClass('role-assignment-fte')) {
      $field
        .closest('tr')
        .find('.role-assignment-fte')
        .each(function () {
          const val = parseFloat($(this).text());
          if (val > 0) {
            sum += val;
          }
        });

      $field.closest('tr').find('.role-fte').text(formatNumber(sum)).closest('td').highlight();
    }

    /* Update all table diffs */
    $('.circle-children tr').each((i, el) => {
      const $el = $(el);
      const planned = parseFloat($el.find('.role-fte, .circle-fte').text());
      const effective = parseFloat($el.find('.effective-fte').text());

      this.updateDiffLabel($el.find('.label-diff-fte'), planned, effective);
    });

    /* Update the total of the circle */
    sum = 0;

    $('.role-fte, .circle-fte').each(function () {
      const val = parseFloat($(this).text());
      if (val > 0) {
        sum += val;
      }
    });

    $('.circle-planned-fte').text(formatNumber(sum)).closest('td').highlight();

    /* Update the circle diff */
    const recommended = parseFloat($('.upper-circle-recommendation-fte').text());
    const planned = parseFloat($('.circle-planned-fte').text());
    const effective = parseFloat($('.circle-effective-fte').text());

    this.updateDiffLabel($('.diff-recommended-planned'), recommended, planned);
    this.updateDiffLabel($('.diff-planned-effective'), planned, effective);
  }
}
