export default class AutoUpdateSettings {
  refreshForm() {
    $('[name="email_subject"]').toggleClass('hidden', $('[name="email_subject_from_first_line"]').is(':checked'));
    $('.auto-delivery-email-settings').toggleClass('hidden', !$('[name="email_autosend"]').is(':checked'));
    $('.auto-delivery-slack-settings').toggleClass('hidden', !$('[name="slack_autosend"]').is(':checked'));
  }

  constructor() {
    this.refreshForm();
    $('input[type="checkbox"]').on('change', this.refreshForm);
  }
}
