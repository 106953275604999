export default class OfferRead {
  constructor() {
    let reasonListLoaded = false;

    $('#lost-modal').on('show.bs.modal', () => {
      if (reasonListLoaded) {
        return;
      }
      $.get('/api/v2/dealLostReason', (xhr) => {
        xhr.data.list.forEach((element) => {
          $(`<option value="${element.label}">${element.label}</option>`).insertBefore('#other_reason_option');
        });
        reasonListLoaded = true;
      });
    });

    const otherReasonFormGroup = document.querySelector('.other-reason');
    document.querySelector('.lost-reason-select').addEventListener('change', (event) => {
      otherReasonFormGroup.hidden = event.target.value !== 'other_reason_option';
    });
  }
}
