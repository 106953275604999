<template>
  <div class="h6 media-heading">{{ item.entity_name }}</div>
</template>

<script>
export default {
  name: 'GroupItem',

  props: {
    item: {
      required: true,
    },
  },
};
</script>
