export default class TimeAccount {
  constructor() {
    this.initHighCharts();
    this.initEvents();
  }

  filter(category) {
    $.tablesorter.setFilters($('#time-accounts'), ['', '', '', '', category]);
  }

  initEvents() {
    document.querySelector('#open-period-button').addEventListener('click', (e) => {
      e.target.classList.add('hidden');
      document.querySelector('#clear-period-button').classList.remove('hidden');
      document.querySelector('.range-dates').classList.remove('hidden');
    });
  }

  initHighCharts() {
    $('#employee-hours-status-graph').highcharts({
      title: null,
      legend: { enabled: false },
      chart: { type: 'column', height: 300 },
      xAxis: { visible: false },
      yAxis: { title: { text: null }, labels: { enabled: false } },
      series: [
        {
          name: 'liipers',
          data: $('.nb-liipers').map((index, cell) => ({
            y: parseInt($(cell).text()),
            events: {
              click: () => this.filter($(cell).data('trigger-filter')),
            },
          })),
        },
      ],
      credits: false,
    });
  }
}
