<template>
  <component :is="htmlElement" :class="htmlClass">
    <slot></slot>
  </component>
</template>
<script>
export default {
  props: {
    htmlClass: String,
    htmlElement: { type: String, default: 'div' },
  },
};
</script>
