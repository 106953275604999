<template>
  <div>
    <div class="h6 media-heading">{{ item.entity_name }}</div>
    <div>
      Status:
      <span v-if="project.status === 0" class="text-success">Offer</span>
      <span v-else-if="project.status === 1 && new Date(project.start_date) > Date.now()" class="text-success">Not started</span>
      <span v-else-if="project.status === 1 && new Date(project.start_date) <= Date.now()" class="text-success">Active</span>
      <span v-else-if="project.status === 2" class="text-success">Finished</span>
      <span v-else-if="project.status === 3" class="text-danger">Aborted</span>
      <span v-else-if="project.status === 4" class="text-danger">Lost</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProjectItem',

  props: {
    item: {
      required: true,
    },
  },

  computed: {
    project() {
      return this.item.entity_object;
    },
  },
};
</script>
