import Xhr from '@/vue/services/xhr';
import { createPopper } from '@popperjs/core';

const xhr = Xhr.getAPIInstance(false);
export default function issueTooltip(element) {
  element.addEventListener('mouseover', () => {
    issueTooltipLoad(element);
  });

  element.addEventListener('mouseleave', () => {
    issueTooltipUnload();
  });
}

async function issueTooltipLoad(element) {
  let tt = document.getElementById('issue-tooltip');

  if (!tt) {
    tt = document.createElement('div');
    tt.setAttribute('id', 'issue-tooltip');
    tt.setAttribute('class', 'issue-tooltip');
    element.appendChild(tt);

    tt.innerHTML = 'Loading...';

    const key = element.getAttribute('data-issue-key').toUpperCase();

    xhr
      .get(`issues/${key.toUpperCase()}`)
      .then((result) => {
        const story = result.data.data;
        tt.innerHTML = `
      <h3 class="popover-title">${story.issue_key} ${story.summary}</h3>
      <div class="popover-content text-small">
        <span class="label label-default pull-right">${story.status}</span>
        <ul class="list-unstyled">
          <li>Type: ${story.issue_type || '-'}</li>
          <li>Epic: ${story.epic_summary || '-'}</li>
          <li>Assignee: ${story.assignee || '-'}</li>
        </ul>
        <a href="${story.url}" class="pull-right" target="_blank">Open...</a>
      </div>`;
      })
      .catch((e) => {
        let errorMsg = '[empty server response]';
        if (e.response) {
          errorMsg = e.response.data.error || e;
        }
        tt.innerHTML = `
      <h3 class="popover-title">${key}</h3>
      <div class="popover-content text-small">
        Failed to load details: ${errorMsg}
      </div>`;
      });
    createPopper(element, tt, {
      placement: 'auto-start',
      modifiers: [
        {
          name: 'preventOverflow',
          options: {
            mainAxis: true, // we want to prevent overflow on the mainAxis (see https://popper.js.org/docs/v2/modifiers/prevent-overflow/#mainaxis)
            altAxis: true,
            tether: true, // keep the tooltip linked with his element (issue here) so that if we scroll horizontally it will follow the element. (see https://popper.js.org/docs/v2/modifiers/prevent-overflow/#tether)
            Boundary: document.querySelector('.table-responsive'), // this will check the overflow from this element. This is kind of the reference for our tooltip.(see https://popper.js.org/docs/v2/modifiers/prevent-overflow/#boundary)
            rootBoundary: 'document', // in case of the Boundary is not defined, it will take the rootBoundary as reference to prevent overflow
          },
        },
        {
          name: 'flip', // change the placement of the tooltip when scrolling. If the tooltip would be hidden by our panel it will instead be repositioned. (see https://popper.js.org/docs/v2/modifiers/flip/)
          options: {
            fallbackPlacements: ['auto'],
          },
        },
      ],
    });
  }
}

function issueTooltipUnload() {
  const tt = document.getElementById('issue-tooltip');
  if (tt) {
    tt.remove();
  }
}
