<template>
  <div>
    <label :for="fieldId" v-if="fieldLabel !== false">
      {{ fieldLabel }}
    </label>

    <template>
      <MultiSelect
        ref="vms"
        v-model="selection"
        :multiple="false"
        :showLabels="false"
        :options="results"
        track-by="entity_id"
        label="entity_name"
        noOptions="Type to search"
        :optionHeight="80"
        :placeholder="placeholder()"
        :searchable="true"
        :internal-search="false"
        :preserveSearch="false"
        :closeOnSelect="true"
        :allow-empty="true"
        :show-no-results="!allowCreate"
        :open-direction="this.openDirection"
        @search-change="asyncFind"
        :id="fieldId"
        @select="selectAction"
      >
        <template slot="option" slot-scope="props">
          <SearchResult :option="props.option" :currentSearch="currentSearch" :showImage="false" />
        </template>

        <template slot="noOptions">
          Type to search
          <span class="btn btn-link" v-if="selection !== null && selection.entity_id !== ''" @click="clearSelection"
            >Remove [{{ selection.entity_name }}] selection</span
          >
        </template>

        <template slot="noResult" slot-scope="props"> No results found for "{{ props.search }}". </template>

        <template slot="afterList" v-if="allowCreate && this.currentSearch !== '' && (!exactMatch || allowCreateOnExactMatch)">
          <div class="pdg--">
            <button @click.stop.prevent="showCreationModal()" class="btn btn-default">Create new: "{{ currentSearch }}"</button>
          </div>
        </template>
      </MultiSelect>
    </template>
    <template v-if="creationModalVisible">
      <component
        :is="`${entityType}-creation`"
        :name="currentSearch"
        :filters="filters"
        :title="fieldTitle === 'services' ? 'service activity' : 'technology'"
        @close="creationModalVisible = false"
        @created="
          (entity) => {
            this.newValue(entity);
          }
        "
      ></component>
    </template>

    <input type="hidden" :name="fieldName" :value="selection ? selection.entity_id : ''" />
  </div>
</template>

<script>
import MultiSelect from 'vue-multiselect';
import TechnologyCreation from './EntityCreation/TechnologyCreation';
import ProductCreation from './EntityCreation/ProductCreation';
import axios from 'axios';
import SearchResult from './search/SearchResult';

export default {
  components: { SearchResult, MultiSelect, TechnologyCreation, ProductCreation },

  name: 'EntitySelector',

  props: {
    title: {
      type: String,
      default: '',
    },
    entityType: {
      required: true,
    },
    fieldName: {
      type: String,
      default: '',
    },
    allowCreate: {
      default: false,
    },
    allowCreateOnExactMatch: {
      default: false,
    },
    id: {
      default: null,
    },
    filters: {
      type: Object,
      default: () => {},
    },
    disabledIds: {
      type: Array,
      default: () => [],
    },
    // For now, our EntitySelector allow only a single select. There is a ticket to allow multiple selection
    // https://jira.liip.ch/browse/ZBR-5742. But until this initialObject is the prop to be used to set the
    // current selection
    initialObject: {
      type: Object,
      default: null,
    },
    label: {
      default: null,
    },
    openDirection: {
      default: 'bottom',
    },
    // By default, this widget just set emit a change event, but here you can choose another action
    legacyAction: {
      type: String,
      default: null,
    },
    // If you set the legacyAction to 'update_input', here you can set a html input element that will be filled
    // by the current selection id
    inputElement: {
      type: Element,
      default: null,
    },
  },

  data() {
    return {
      focused: false,
      selection: {},
      api: null,
      source: null,
      creationModalVisible: false,
      currentSearch: '',
      results: [],
      exactMatch: false,
    };
  },

  mounted() {
    this.source = axios.CancelToken.source();

    this.api = axios.create({
      baseURL: '/',
      headers: {
        post: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      },
    });

    if (this.initialObject) {
      this.selection = this.initialObject;
    }
  },

  computed: {
    fieldTitle() {
      return this.title;
    },
    fieldId() {
      return this.id || `${this.entityType}-selector`;
    },
    fieldLabel() {
      if (this.label === false) {
        return false;
      }
      return this.label || `${this.entityType}`;
    },
  },

  methods: {
    async asyncFind(searchTerms) {
      this.focused = true;
      searchTerms = searchTerms === null ? '' : searchTerms;
      this.currentSearch = searchTerms;
      this.source.cancel();
      this.source = axios.CancelToken.source();
      if (searchTerms === '') {
        return;
      }

      this.api
        .post(`/search/entity/${this.entityType}`, { searchTerms, filters: this.filters }, { cancelToken: this.source.token })
        .catch(() => {
          /* we don't do anything with canceled queries */
        })
        .then((response) => {
          if (response !== undefined) {
            this.results = Array.from(response.data);
            this.results.map((r) => (r.$isDisabled = this.disabledIds.includes(r.entity_id)));
            this.exactMatch = this.results.reduce((acc, r) => acc || r.entity_name.toUpperCase() === searchTerms.toUpperCase(), false);
          }
        });
    },

    showCreationModal() {
      this.creationModalVisible = true;
    },

    newValue(entity) {
      // Let's complete the entity so that it looks like a Solr result
      entity.entity_id = entity.id;
      entity.entity_name = entity.name;
      entity.entity_type = this.entityType;
      this.selection = entity;
      this.selectAction(entity);
    },

    placeholder() {
      return '';
    },

    selectAction(item) {
      // Normal behavior, just emit the event
      this.selection = item;
      this.$emit('select', this.selection);

      // Legacy behaviors
      // -> updating the initial input
      if (this.legacyAction === 'update_input') {
        if (this.inputElement === null) {
          throw 'Missing element';
        }
        this.inputElement.setAttribute('value', item.entity_id);
        this.results = [];
      }
      // -> behavior for the techDB
      else if (this.legacyAction === 'tech_table') {
        Zebra.addTechnoToTechTable(item.entity_id, {
          id: item.entity_id,
          text: item.entity_name,
          category: item.category_text,
        });
        this.results = [];
        this.$refs.vms.$el.focus();
      }
      // -> behavior for searching through the documentation
      else if (this.legacyAction === 'documentation') {
        window.location.href = `/doc/${item.entity_name}`;
      }
    },
    clearSelection() {
      // Normal behavior, just emit the event
      this.selectAction({ entity_id: '', entity_name: '', entity_type: this.entityType });
    },
  },
};
</script>
