import Xhr from '@/vue/services/xhr';

const ApiPlugin = {
  install(Vue, { store } = {}) {
    const xhr = Xhr.getInstance('/api/v2', {
      post: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      put: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });

    const xhrInternal = Xhr.getInstance('/', {
      post: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });

    Vue.prototype.$publicApi = xhr;
    Vue.prototype.$internalApi = xhrInternal;

    if (store) {
      store.$publicApi = xhr;
      store.$internalApi = xhrInternal;
    }
  },
};

export default ApiPlugin;
