import { isTimesheetAvailableForPosition } from '@/legacy/helpers/billing';

export default {
  getUserById: (state) => (id) => {
    if (!state.users.hasOwnProperty(id)) {
      throw new Error(`Invalid user [${id}]`);
    }
    return state.users[id];
  },

  getActivityById: (state) => (id) => {
    if (!state.activities.hasOwnProperty(id)) {
      throw new Error(`Invalid activity [${id}]`);
    }
    return state.activities[id];
  },

  /**
   * return a map with every timesheets and the potential position where they could fit
   *
   * Example:
   * {
   *     timesheetId: [postion1Id, postion2Id],
   *     timesheetId: [],
   * }
   */
  timesheetToPositionMapping: (state) => (billId) => {
    const positions = Object.values(state.bills[billId].positions);
    const timesheets = Object.values(state.timesheets);
    const mapping = {};
    for (const ts of timesheets) {
      mapping[ts.id] = [];
      for (const p of positions) {
        if (isTimesheetAvailableForPosition(ts, p, state.bills[billId])) {
          mapping[ts.id].push(p);
        }
      }
    }
    return mapping;
  },
};
