import Vue from 'vue';

import ZebraVue from '@/vue/components/common/ZebraVue';
import LoadingComponent from '@/vue/components/common/LoadingComponent';
import store from '@/vue/store/store';
import '@/vue/filters';
import EntitySelector from '@/vue/components/common/EntitySelector';

import ApiPlugin from '@/vue/plugins/api';

Vue.use(ApiPlugin, { store });
Vue.prototype.Zebra = window.Zebra;

/**
 * Init the ZebraVue components
 */
function initZebraVueComponents(parent = null) {
  const defaultComponentLoadingParams = {
    loading: LoadingComponent,
    delay: 100,
    timeout: 3000,
  };
  (parent || document).querySelectorAll('ZebraVue').forEach((vueElem) => {
    new Vue({
      el: vueElem,
      store,
      components: {
        zebravue: ZebraVue,
        satisfactionsurveywidget: () => ({
          component: import(/* webpackChunkName: "satisfaction-widget" */ '@/vue/components/satisfaction-survey/SatisfactionSurveyWidget.vue'),
          ...defaultComponentLoadingParams,
        }),
        entityselector: () => ({
          component: import(/* webpackChunkName: "entity-selector" */ '@/vue/components/common/EntitySelector.vue'),
          ...defaultComponentLoadingParams,
        }),
        searchbox: () => ({
          component: import(/* webpackChunkName: "search-box" */ '@/vue/components/common/SearchBox.vue'),
          ...defaultComponentLoadingParams,
        }),
        billcontainer: () => ({
          component: import(/* webpackChunkName: "bill-container" */ '@/vue/components/bills/BillContainer.vue'),
          ...defaultComponentLoadingParams,
        }),
        dashboardbilllist: () => ({
          component: import(/* webpackChunkName: "dashboard-bill-list" */ '@/vue/components/bills/DashboardBillList.vue'),
          ...defaultComponentLoadingParams,
        }),
        techradar: () => ({
          component: import(/* webpackChunkName: "tech-radar" */ '@/vue/components/radar/TechRadar.vue'),
          ...defaultComponentLoadingParams,
        }),
        salaryeditableform: () => ({
          component: import(/* webpackChunkName: "salary-amount-editable-form" */ '@/vue/components/common/editable/SalaryEditableForm.vue'),
          ...defaultComponentLoadingParams,
        }),
      },
    });
  });
}
$(document).ready(() => {
  initZebraVueComponents();
});
Zebra.initZebraVueComponents = initZebraVueComponents; // To allow activation from the legacy bundle
/**
 * Init the Vue EntitySelectors
 * See https://zebra.liip.ch/doc/4_view/components/selectors.md
 */
function activateInputEntitySelector(parent = null) {
  (parent || document).querySelectorAll('input.entity-selector').forEach((element) => {
    //
    // Collect the initialisation data and props
    let type;
    if ((type = element.getAttribute('data-entity-type')) === undefined) {
      throw 'Missing type';
    }
    const props = JSON.parse(element.getAttribute('data-props')) || {};
    props.entityType = type;
    props.legacyAction = 'update_input';
    props.inputElement = element;

    // Create the Vue Entity Selector, inspired by https://css-tricks.com/creating-vue-js-component-instances-programmatically/
    const ComponentClass = Vue.extend(EntitySelector);
    const selector = new ComponentClass({ propsData: props });
    selector.$mount();
    element.parentNode.insertBefore(selector.$el, element.nextSibling);
    element.style.display = 'none';
  });
}
activateInputEntitySelector();
Zebra.activateInputEntitySelector = activateInputEntitySelector; // To allow activation from the legacy bundle
