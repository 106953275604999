import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    max: Number,
    warning: Number,
  };
  static targets = ['input', 'counter'];

  connect() {
    this.counter();
    this.inputTarget.addEventListener('input', this.counter.bind(this));
  }
  counter() {
    if (!this.hasInputTarget) return;
    if (!this.hasCounterTarget) return;
    const characterCount = this.inputTarget.value.length;

    this.counterTarget.classList.remove('text-danger', 'text-warning');

    if ((this.maxValue || 0) === 0) {
      this.counterTarget.textContent = `${characterCount} characters`;
      return;
    }

    if (characterCount > this.maxValue) {
      this.inputTarget.value = this.inputTarget.value.substring(0, this.maxValue);
      this.counter();
      return;
    }

    if (characterCount === this.maxValue) {
      this.counterTarget.classList.add('text-danger');
    }

    if ((this.warningValue || 0) > 0 && characterCount >= this.warningValue) {
      this.counterTarget.classList.add('text-warning');
    }

    this.counterTarget.textContent = `${characterCount}/${this.maxValue}`;
  }
}
