// General formatter function
export const numberFormatter = (number, digits = 2, currency = false) => {
  if (number === null) {
    return '-';
  }
  return parseFloat(number)
    .toFixed(digits)
    .replace(' ', "'")
    .replace(/\B(?=(\d{3})+(?!\d))/g, `'`)
    .replace('.', currency ? '.' : ',');
};

//  Format a number to CHF
export const toCHF = (number) => {
  if (number === null) {
    return '-';
  }
  if (isNaN(number)) {
    return number;
  }
  return `${numberFormatter(number, 2, true)}\xA0CHF`;
};

export const toHours = (hours) => {
  if (hours === null) {
    return '-';
  }
  if (isNaN(hours)) {
    return hours;
  }
  return `${numberFormatter(hours)}\xA0h`;
};

export const toPercent = (number) => {
  if (number === null) {
    return '-';
  }
  if (isNaN(number)) {
    return number;
  }
  number *= 100;
  return `${numberFormatter(number, 1)}\xA0%`;
};

// Format a number to a given number of digits
export const formatNumber = (number, digits) => {
  if (number === null) {
    return '-';
  }
  if (isNaN(number)) {
    return number;
  }
  return numberFormatter(number, digits);
};

// Join strings together with a space if they are not null, undefined or empty
export const join = (...args) => {
  return args.filter((arg) => arg !== null && arg !== undefined && arg !== '').join('\xA0');
};
