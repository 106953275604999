/* eslint-disable object-shorthand */
/* eslint-disable camelcase */
/* eslint-disable indent */

export default function (data) {
  const { alias, current_activity, new_activity, take_over_url } = data;
  return `
    <div id="editable-alias" class="modal fade" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                    <h4 class="modal-title">Alias already used</h4>
                </div>
                <div class="modal-body">
                    <p>
                        Alias <strong>${alias}</strong> is currently used on activity
                        <a href="/project/${current_activity.project.id}#activity-${current_activity.id}" target="_blank">
                        ${current_activity.project.name}: ${current_activity.name}
                        </a>
                    </p>
                    <p>
                        Do you want to force a transfer on
                        <a href="/project/${new_activity.project.id}#activity-${new_activity.id}" target="_blank">
                        ${new_activity.project.name}: ${new_activity.name} ?
                        </a>
                    </p>
                </div>
                <div class="modal-footer">
                    <a type="button" class="btn btn-default" data-dismiss="modal">Cancel</a>
                    <a type="button" class="btn btn-primary" href="${take_over_url}">
                      Yes, take over
                    </a>
                </div>
            </div>
        </div>
    </div>
`;
}
