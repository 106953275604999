/* eslint-disable object-shorthand */

import getColorForString from '@/js/utils/getColorForString';
import decodeHTMLEntities from '@/js/utils/decodeHTMLEntities';

import { toCHF, toPercent, toHours } from '@/js/utils/formatter';

// WARNING: THIS SCRIPT IS SHARED BETWEEN THE TRACKING PAGE AND THE DASHBOARD
export default class Tracking {
  constructor(mode, data) {
    this.data = data;

    if (mode === 'dashboard') {
      this.data.currentProjects.forEach((project) => {
        project.name = decodeHTMLEntities(project.name);
      });
      this.initPersonalDashboard();
    } else {
      this.initTrackingPage();
    }
  }

  initTrackingPage() {
    this.initStoryChart();
    this.initBurnupChart();
    this.initTimesheetActivityEdition();
    this.initTimesheetStoryEdition();
    this.initTimesheetActions({
      showWarning: true,
      removeTableOnLast: false,
    });
    this.initAcceptAllAction(this.emptyTrackingReviewSection);
    this.initHourDispatcherForBurnupConfig();
    this.bulkStoryAssignments();
  }

  initPersonalDashboard() {
    this.initTimesheetActions({
      showWarning: false,
      removeTableOnLast: true,
    });
    this.initTimesheetActivityEdition();
    this.initTimesheetStoryEdition();
    this.initAcceptAllAction(this.hideTrackingReviewSection);
    this.initMyProjectsPieChart();
  }

  showAjaxWarning(text) {
    Zebra.notificationsCenter.show(
      'warning',
      `${text} The current page is not up to date anmoyre, <a href="${window.location.href}">refresh it</a> a soon as possible.`,
      { isHTML: true },
    );
  }

  displayData(tooltip, point, data) {
    let extra = '';
    let context = '';
    if (tooltip && point.seriesType !== 'Planned') {
      extra = '<p class="mrg--">Click for timesheet details </p>';
    }
    const summary = `<h5>${point.seriesName} ${data.stories[point.seriesName].summary}</h5>`;

    if (point.seriesType === 'Planned') {
      context = `${summary}${extra}<b>Estimation: ${point.y.toFixed(1)}[h]</b><br>${data.stories[point.seriesName].estimation}[SP] / velocity (${
        data.sprint.velocity
      })`;
    }

    if (point.seriesType === 'Related' || point.seriesName === 'Other') {
      context = '<table class="story-graph-tooltip">';
      let sum = 0;
      const reKey = new RegExp(`^${point.seriesName}`);
      const temp = data.stories[point.seriesName].timesheet_ids
        .map((id) => {
          const t = data.timesheets[id];
          t.description = t.description.replace(reKey, '');
          return t;
        })
        .sort((a, b) => {
          if (a.dev < b.dev) {
            return -1;
          }
          if (a.dev > b.dev) {
            return 1;
          }
          return a.time - b.time;
        });

      let currentDev = null;
      let devSum = 0;
      temp.forEach((t) => {
        if (currentDev !== t.dev) {
          if (currentDev !== null) {
            context += `<tr class="subtotal"><td colspan="2">Total for ${currentDev}</td><td>${devSum}h</td></tr>`;
            devSum = 0;
          }
          currentDev = t.dev;
        }
        if (!tooltip) {
          context += `<tr><td>${t.dev}</td><td>${t.description}</td><td>${t.time}h</td></tr>`;
        }
        devSum += t.time;
        sum += t.time;
      });
      context += `<tr class="subtotal"><td colspan="2">Total for ${currentDev}</td><td>${devSum}h</td></tr>`;
      return `${summary}${context}<tr class="total"><td colspan="2">Total</td><td>${sum}h</td></table>${extra}`;
    }
    if (point.seriesType === 'Not related') {
      return `${summary}${parseInt((point.y / data.stories.Other.time) * 100)}% of the other section`;
    }
    return context;
  }

  initStoryChart() {
    const data = this.data;
    const $container = $('#result-by-stories-container');
    if (!$container.length) {
      return;
    }

    const labels = [];
    const estims = [];
    const works = [];
    const unrelated = [];
    let totalEstim = 0;

    for (const name in data.stories) {
      if (name !== 'Other') {
        const story = data.stories[name];
        labels.push(name);
        works.push(story.time);
        estims.push((story.estimation * 8) / data.sprint.velocity);
        totalEstim += story.estimation;
      }
    }

    const unrelatedByPoint = data.stories.Other.time / totalEstim;
    for (const storyName in data.stories) {
      if (storyName !== 'Other') {
        unrelated.push(data.stories[storyName].estimation * unrelatedByPoint);
      }
    }

    labels.push('Other');
    unrelated.push(data.stories.Other.time);

    $container.highcharts({
      exporting: {
        enabled: true,
      },
      title: { text: data.sprint.name },
      subtitle: { text: 'Results by stories' },
      chart: {
        type: 'column',
      },
      xAxis: {
        categories: labels,
        labels: {
          formatter: function () {
            if (data.stories[this.value].close_date !== null && data.stories[this.value].name !== 'Other') {
              return `<span style="color:#6ea644;font-weight: bold">${this.value}</span>`;
            } else {
              return `<span>${this.value}</span>`;
            }
          },
        },
      },
      yAxis: {
        min: 0,
        title: { text: 'Hours' },
      },
      plotOptions: {
        series: {
          point: {
            events: {
              mouseOver: function () {
                const $highchartsTooltip = $('div').filter('.highcharts-tooltip');
                const $span = $('.highcharts-tooltip > span');

                $highchartsTooltip.css('width', '100%');
                $highchartsTooltip.css('height', '100%');

                $span.css('max-height', '70%');

                //BUG with the linux version of firefox, a horizontal scrollbar appears anyway
                //https://bugzilla.mozilla.org/show_bug.cgi?id=397367
                $span.css('overflow-y', 'auto');

                //Mouse not transforming into IBeam when in text
                $span.css('user-select', 'none');
              },
              click: function (e) {
                document.getElementById('title').innerHTML = e.point.category;
                const currentPoint = { seriesType: this.series.name, seriesName: e.point.category, y: this.y };
                document.getElementById('content').innerHTML = Tracking.prototype.displayData(false, currentPoint, data);
                $('#stories-modal').modal('show');
              },
            },
          },
        },
        column: {
          stacking: 'normal',
          dataLabels: {
            enabled: true,
            useHTML: true,
            formatter: function () {
              if (this.series.name === 'Related' && data.stories[this.key].time !== 0) {
                return data.stories[this.key].close_date !== null
                  ? '<img src="/static/images/accept.png" />'
                  : '<img src="/static/images/brick_edit.png" />';
              }
            },
          },
        },
      },
      series: [
        {
          name: 'Planned',
          data: estims,
          stack: 'planned',
        },
        {
          name: 'Related',
          data: works,
          stack: 'effective',
        },
        {
          name: 'Not related',
          data: unrelated,
          stack: 'effective',
        },
      ],
      credits: {
        enabled: false,
      },
      tooltip: {
        crosshairs: [false, true],
        useHTML: true,
        formatter: function () {
          const $highchartsTooltip = $('div').filter('.highcharts-tooltip');
          const $span = $('.highcharts-tooltip > span');

          //If the tooltip has a scrollbar, allow it to react to pointer event
          if ($span.get(0).offsetHeight < $span.get(0).scrollHeight) {
            $highchartsTooltip.css('pointer-events', 'all');
          } else {
            $highchartsTooltip.css('pointer-events', 'none');
          }
          const currentPoint = { seriesType: this.series.name, seriesName: this.key, y: this.y };
          return Tracking.prototype.displayData(true, currentPoint, data);
        },
      },
    });
  }

  initBurnupChart() {
    const data = this.data;
    const $container = $('#burndown-container');
    if (!$container.length) {
      return;
    }

    const dates = [];
    const points = [];
    const works = [];
    const planned = [];
    const hoursPerDay = 8;

    // Reformat all in WD
    for (const name in data.periods) {
      dates.push(name);
      works.push((data.periods[name].work + data.periods[name].forced_work) / hoursPerDay);
      points.push((data.periods[name].point + data.periods[name].forced_point) / data.sprint.velocity);
      planned.push(data.periods[name].planned / hoursPerDay);
    }

    // Cumulative chart
    for (let i = 1; i < works.length; i++) {
      works[i] = works[i] + works[i - 1];
      points[i] = points[i] + points[i - 1];
      planned[i] = planned[i] + planned[i - 1];
    }

    $container.highcharts({
      exporting: {
        enabled: true,
      },
      title: { text: data.sprint.name },
      subtitle: { text: `Burnup chart from ${data.sprint.start_date} to ${data.sprint.end_date}` },
      chart: {
        defaultSeriesType: 'line',
      },
      xAxis: {
        categories: dates,
        tickInterval: dates.length < 20 ? 2 : 5,
        // a vertical line to mark today
        plotLines: [
          {
            color: '#FFBB66',
            width: 1,
            value: (function () {
              let pos = 0;
              for (const date in data.periods) {
                if (date === data.sprint.now) {
                  return pos;
                }
                pos++;
              }
            })(),
          },
        ],
      },
      yAxis: {
        title: {
          text: 'Work Days',
        },
        plotLines: [
          {
            color: '#00BB00',
            width: 3,
            value: data.sprint.storyPoints / data.sprint.velocity,
          },
        ],
      },
      legend: {
        layout: 'horizontal',
        verticalAlign: 'bottom',
      },
      series: [
        {
          name: 'Story points',
          data: points,
          color: 'blue',
        },
        {
          name: 'Work',
          data: works,
          color: 'black',
        },
        {
          name: 'Planned work',
          data: planned,
          color: 'grey',
        },
        {
          name: 'Goal',
          type: 'scatter',
          marker: { enabled: false },
          data: [data.sprint.storyPoints / data.sprint.velocity],
          color: '#00BB00',
        },
      ],
      tooltip: {
        crosshairs: [true, true],
        shared: false,
        formatter: function () {
          let text = '';
          let sum = 0;
          if (this.series.name === 'Story points') {
            data.periods[this.x].closed_story_names.forEach((name) => {
              text += `<br> * ${name} (${data.stories[name].estimation.toFixed(1)}[SP])`;
              sum = sum + data.stories[name].estimation;
            });
            text = `<b>${(this.y * data.sprint.velocity).toFixed(1)}[SP] done<br/>On ${this.x}: ${sum}[SP]</b> closed:${text}`;
          } else if (this.series.name === 'Work') {
            data.periods[this.x].timesheet_ids.forEach((id) => {
              text += `<br> * ${data.timesheets[id].dev} ${data.timesheets[id].description} (${data.timesheets[id].time}[h])`;
              sum = sum + data.timesheets[id].time;
            });
            text = `<b>Total time: ${this.y}[WD]<br>${sum}[h]</b> burned on ${this.x}:${text}`;
          } else {
            text = `<b>${this.y}[WD]</b> should have been burned<br>${data.periods[this.x].planned} [h] planned for ${this.x}`;
          }
          return text;
        },
      },
      credits: {
        enabled: false,
      },
      plotOptions: {
        series: {
          marker: {
            radius: 3,
          },
          lineWidth: 4,
        },
      },
    });
  }

  initTimesheetActivityEdition() {
    const that = this;
    const projectActivities = {};
    Object.keys(this.data.activities).forEach((key) => {
      const activity = this.data.activities[key];
      if (!(activity.project_id in projectActivities)) {
        projectActivities[activity.project_id] = [];
      }
      projectActivities[activity.project_id].push({ value: key, text: activity.name });
    });
    $('.editable-activity').each(function () {
      const $element = $(this);
      const id = $element.data('id');
      $element.editable({
        type: 'select2',
        showbuttons: false,
        inputclass: 'editable-min-width-150',
        source: projectActivities[$element.data('project-id')],
        url: `/timesheet/${id}/update-field/activity_id`,
        value: $element.data('value'),
        select2: {
          minimumResultsForSearch: 10,
        },
        success: function (response, newValue) {
          that.showAjaxWarning('Story updated');
          return { newValue };
        },
      });
    });
  }

  initTimesheetStoryEdition() {
    const that = this;
    const sprintStories = {};
    Object.keys(this.data.stories).forEach((key) => {
      const story = this.data.stories[key];
      if (!(story.sprint_id in sprintStories)) {
        sprintStories[story.sprint_id] = [];
      }
      sprintStories[story.sprint_id].push({ id: key, text: key });
    });
    const globalOptions = [{ id: '__unassign__', text: 'Un-assign' }];
    $('.editable-story').each(function () {
      const $element = $(this);
      const id = $element.data('id');
      const sprintId = $element.data('sprint-id');
      const current = $element.data('value');
      const options = [...globalOptions];
      if ($element.data('create') === 'yes') {
        options.push({ id: `__create__${current}`, text: `Create ${current}` });
      }
      $element.editable({
        type: 'select2',
        showbuttons: false,
        inputclass: 'editable-min-width-150',
        source: [
          { text: 'Force to', children: sprintStories[sprintId] },
          { text: 'Other options', children: options },
        ],
        url: `/sprint/${sprintId}/timesheet/${id}/force-story`,
        value: current,
        select2: {
          minimumResultsForSearch: 10,
        },
        success: function (response, newValue) {
          if (response.refresh === true) {
            window.location.reload();
          }
          that.showAjaxWarning('Story updated');
          return { newValue };
        },
      });
    });
  }

  initTimesheetActions(options) {
    // Post some timesheets links silently by ajax
    $('[data-action="timesheet"]').click((e) => {
      const $link = $(e.currentTarget);
      $.post($link.attr('href'), () => {
        if (options.showWarning) {
          this.showAjaxWarning('Timesheet status updated!');
        }
        if (options.removeTableOnLast) {
          if ($link.closest('tbody').find('tr').length === 1) {
            this.hideTrackingReviewSection($link);
          }
        }
        $link.closest('tr').fadeOut();
      });
      e.preventDefault();
    });
  }

  initHourDispatcherForBurnupConfig() {
    $('button[name="hours_dispatcher_button"]').click(() => {
      const hoursToDispatch = parseInt($('input[name="hours_dispatcher_hours"]').val());
      if (isNaN(hoursToDispatch) || hoursToDispatch < 1) {
        alert('Enter a positive number of hours to dispatch');
        return;
      }
      $('input.allow-dispatch').val(0);
      const candidates = $('input.allow-dispatch.active');
      const splittedPart = Math.floor(hoursToDispatch / candidates.length);
      let remainder = hoursToDispatch % candidates.length;
      $.each(candidates, function () {
        $(this).val(splittedPart + (remainder-- > 0 ? 1 : 0));
      });
    });

    // Allow to exclude some days from the dispatching
    function updateDispatchDay() {
      const day = $(this).val();
      const active = $(this).is(':checked');
      $('input.allow-dispatch').each(function () {
        if ($(this).closest('tr').hasClass(`weekday-${day}`)) {
          $(this).toggleClass('active', active);
        }
      });
    }
    $('input[name="hours_dispatcher_day"]').each(updateDispatchDay).click(updateDispatchDay);

    // Automatic update of the periods table totals
    function updatePeriodTotal(fieldName) {
      let total = 0;
      $(`#configure-periods-form input[name$="${fieldName}]"]`).each(function () {
        const $val = $(this).val();
        total += isNaN(parseInt($val)) ? 0 : parseInt($val);
      });
      $(`.total-${fieldName}`).html(total);
    }
    $.each(['pl', 'fw', 'fp'], (index, fieldName) => {
      updatePeriodTotal(fieldName);
      $(`#configure-periods-form input[name$="${fieldName}]"]`).on('keyup mouseup', () => {
        updatePeriodTotal(fieldName);
      });
    });
  }

  bulkStoryAssignments() {
    // Enable the select/unselect all
    $(':checkbox#timesheet-selection-all').click(function () {
      const checked = this.checked;
      $(':checkbox.timesheet-selection:visible').each(function () {
        this.checked = checked;
      });
      $(':checkbox.timesheet-selection').first().trigger('change'); // Needed to trigger the next block
    });

    // Adjust the form when selection change
    $(':checkbox.timesheet-selection').on('change', () => {
      const ids = [];
      $(':checkbox.timesheet-selection:checked').each(function () {
        ids.push($(this).attr('value'));
      });
      $('#timesheet-bulk-actions [name="timesheet_ids"]').attr('value', ids.join(','));
      $('#with-selection').toggleClass('hidden', ids.length === 0);
      $('#with-no-selection').toggleClass('hidden', ids.length > 0);
      $('#selection-counter').html(`${ids.length} selected: `);
    });

    // Auto post on story selection
    $('#timesheet-bulk-actions .story-selector').on('change', function () {
      $(this).closest('form').submit();
    });
  }

  hideTrackingReviewSection(child) {
    child.closest('.tracking-review-section').find('.nothing-to-review-confirmation').removeClass('hidden');
    child.closest('.new-timesheets').slideUp(function () {
      $(this).remove();
    });
  }

  emptyTrackingReviewSection() {
    const $table = $('.new-timesheets');
    $table.find('tbody').empty();
    $table.find('tfoot').hide();
  }

  initAcceptAllAction(callback) {
    $('.accept-all').click((e) => {
      const $link = $(e.currentTarget);
      e.preventDefault();
      $.post($link.attr('href'), () => {
        if (callback && typeof callback === 'function') {
          callback.call(this, $link);
        }
        Zebra.notificationsCenter.show('success', 'All timesheets successfully accepted.');
      });
    });
  }

  initMyProjectsPieChart() {
    if (!this.data.currentProjects) {
      return;
    }

    this.data.currentProjects.forEach((project) => {
      const color = getColorForString(project.name);
      $(`.color-box[data-project-id='${project.id}']`).css('background-color', color);
      project.color = color;
    });

    $('#current-projects-pie').highcharts({
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
      },
      title: false,
      series: [
        {
          type: 'pie',
          name: 'Time [h]',
          data: this.data.currentProjects,
        },
      ],
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: false,
          },
        },
      },
      credits: { enabled: false },
    });
  }

  static initSyncStoriesWithJira() {
    const updateLinks = [...document.querySelectorAll('.update-story')];

    updateLinks.map((updateLink) => {
      updateLink.addEventListener('click', (event) => {
        event.preventDefault();
        $.ajax(updateLink.href);
      });
    });
  }

  static initSplitting(groups) {

    /*
     * WARNING This logic is duplicated with zebra_model_sprint::getSplitTimeAndTotals, please adjust PHP too you you do some changes
     */
    function updateNumbers() {
      let sumSplitted = 0;
      let sumProrata = 0;
      const sumGroups = {};
      groups.forEach((group) => (sumGroups[group] = 0));
      // First iteration, collect numbers and output normally splited hours
      $('tr.story').each(function () {
        const $line = $(this);
        const hours = $line.data('hours');
        const $groups = $line.find('input.group-selection');
        // Reset
        $line.removeClass('warning');
        $groups.prop('disabled', false);

        const storyHours = $line.data('hours');
        const checkecCount = $groups.filter(':checked').length;
        if ($line.find('input.prorata-selection').is(':checked')) {
          sumProrata += hours;
          $groups.prop('disabled', true);
        } else if (checkecCount === 0) {
          $line.addClass('warning');
        } else {
          sumSplitted += hours;
          const splittedHours = storyHours / checkecCount;
          $groups.each(function () {
            const $textZone = $(this).closest('td').find('.hours');
            const group = $(this).attr('value');
            if ($(this).is(':checked')) {
              $textZone.text(toHours(splittedHours));
              sumGroups[group] += splittedHours;
            } else {
              $textZone.text('0h');
            }
          });
        }
      });
      $('#split-sum').text(toHours(sumSplitted));
      $('#prorata-sum').text(toHours(sumProrata));
      $('#total-sum').text(toHours(sumSplitted + sumProrata));
      const percentGroups = {};
      groups.forEach((group) => {
        percentGroups[group] = sumGroups[group] / sumSplitted;
        const percent = Math.round(percentGroups[group] * 1000) / 10;
        $(`#split-sum-${group}`).text(`${toHours(sumGroups[group])} (${percent}%)`);
        $(`#prorata-sum-${group}`).text(toHours(sumProrata * percentGroups[group]));
        let totalTime = sumGroups[group] + sumProrata * percentGroups[group];
        totalTime = Math.round(totalTime * 100) / 100;
        const budget = $(`input[name="budgets[${group}]"]`).val() ?? 0;
        const rate = $(`input[name="rates[${group}]"]`).val() ?? 0;
        const totalChf = totalTime * rate;
        $(`#total-sum-${group}, #bill-time-${group}`).text(toHours(totalTime));
        $(`#total-sum-chf-${group}`).text(toCHF(totalChf));
        $(`input[name="times[${group}]"]`).prop('value', totalTime);
        const $progressBar = $(`.progress.budget-progress-${group} .progress-bar`);
        if (budget > 0) {
          $progressBar.parent().removeClass('hidden');
          $progressBar.css("width", `${totalChf/budget*100}%`);
          $progressBar.text(toPercent(totalChf/budget));
          $progressBar.toggleClass('progress-bar-success', totalChf <= budget);
          $progressBar.toggleClass('progress-bar-warning', totalChf > budget);
        }
        else {
          $progressBar.parent().addClass('hidden');
        }
      });

      // Second iteration add the prorata values on every lines
      $('tr.story').each(function () {
        const $line = $(this);
        if ($line.find('input.prorata-selection').is(':checked')) {
          const $groups = $line.find('input.group-selection');
          const hours = $line.data('hours');
          $groups.each(function () {
            const $textZone = $(this).closest('td').find('.hours');
            const group = $(this).attr('value');
            $textZone.text(toHours(hours * percentGroups[group]));
          });
        }
      });
    }

    $('input.group-selection, input.prorata-selection').change(() => updateNumbers());

    $('a.select-all').click(function (e) {
      e.preventDefault();
      const $line = $(this).closest('tr');
      $line.find('input.group-selection').prop('checked', true);
      updateNumbers();
    });

    $('a.select-none').click(function (e) {
      e.preventDefault();
      const $line = $(this).closest('tr');
      $line.find('input.group-selection').prop('checked', false);
      updateNumbers();
    });

    $('.budget-input').on('change', function () { updateNumbers(); });

    function updateBillLinks(selector) {
      const id = $(selector).val();
      const $btn = $(selector).closest('tr').find('.btn-bill');
      if (id > 0) {
        $btn.attr('href', `/bill/${id}`).removeAttr('disabled');
      } else {
        $btn.attr('href', `#`).attr('disabled', true);
      }
    }

    $('.bill-selector')
      .on('change', function () {
        updateBillLinks(this);
      })
      .each(function () {
        updateBillLinks(this);
      });

    updateNumbers();
  }
}
