import { format } from 'date-fns';

import icon from '@/legacy/common/templates/icon';

export default class Timesheet {
  constructor() {
    this.$activitySelect = $('#activity');
    this.$roleSelect = $('#role');
    this.$addTsToFavorite = $('#add-timesheet-to-favorite');

    this.$activitySelect
      .select2({
        placeholder: 'Select an activity…',
        sorter: (data) => {
          const query = this.$activitySelect.data('select2').search.val();
          return data.sort((a, b) => {
            // We try to favor aliases exact match. So if the content of the search match the content of the parenthesis
            // We return it first, otherwise we still use the original sorting
            const matchParentheses = /\((.*)\)/;
            const textA = a.text.match(matchParentheses);
            const textB = b.text.match(matchParentheses);
            if (textA !== null && textB !== null) {
              return textB.indexOf(query) - textA.indexOf(query);
            }
          });
        },
        matcher: (term, text) => {
          const terms = term.split(' ');
          for (let i = 0; i < terms.length; i++) {
            if (text.toUpperCase().indexOf(terms[i].toUpperCase()) === -1) {
              return false;
            }
          }
          return true;
        },
      })
      .on('change', (e) => {
        $('.alert-feedback').addClass('hidden');

        // Adjust the role field according to previous user timesheeting
        if (this.updateRoleField()) {
          // Role needed
          const forceIA = e.target[e.target.selectedIndex].dataset.forceIa;
          if (forceIA === 'true') {
            this.$roleSelect.val('individual_action').trigger('change');
          } else {
            const previousActivity = e.target[e.target.selectedIndex].dataset.roleId;
            if (previousActivity !== '') {
              this.$roleSelect.val(previousActivity).trigger('change');
            } else {
              this.$roleSelect.focus();
            }
          }
        } else {
          // No need a role
          // avoid the previous role to be taken even if the role selector is hidden
          this.$roleSelect.val(null).trigger('change');
          $('#time').focus();
        }

        // Generate a link to the project page
        const $link = $('#link-to-project-page');
        const selection = e.target[e.target.selectedIndex];
        const [projectId, activityId] = selection.value.split('-');

        $link.attr('href', `/project/${projectId}#activity-${activityId}`);
        $link.removeClass('hidden');

        const description = $('#activity-description');
        if (selection.dataset.activityDescription !== '') {
          description.text(selection.dataset.activityDescription);
          description.removeClass('hidden');
        } else {
          description.addClass('hidden');
        }
      });

    this.$roleSelect.on('change', () => {
      $('.alert-individual-action').toggleClass('hidden', this.$roleSelect.val() !== 'individual_action');
      this.$addTsToFavorite.toggleClass('hidden', this.$roleSelect.val() === 'individual_action');
      if (this.$roleSelect.val()) {
        $('#time').focus();
      }
    });

    this.updateRoleField();

    this.addEventListeners();
  }

  updateRoleField() {
    const selectedActivity = this.$activitySelect.find('option:selected');
    let roleNeeded = true;
    if (selectedActivity.val()) {
      roleNeeded = selectedActivity.data('role-needed') === true;
    }
    if (roleNeeded) {
      this.$roleSelect.attr('required', 'required').removeAttr('disabled').closest('.form-group').find('.control-label').addClass('label-required');
    } else {
      this.$roleSelect
        .removeAttr('required')
        .attr('disabled', 'disabled')
        .closest('.form-group')
        .find('.control-label')
        .removeClass('label-required');
      $('#select2-chosen-1').text('Off role');
    }
    return roleNeeded;
  }

  addEventListeners() {
    this.$addTsToFavorite.on('click', this.addToFavorites.bind(this));
    $('#add-client-desc').on('click', this.saveClientDescription.bind(this));
    $(document).on('click', '.edit-favorite', this.editFavorite.bind(this));
    $(document).on('click', '.remove-favorite', this.removeFavorite.bind(this));
    $(document).on('click', '.set-favorite', this.setFromFavorite.bind(this));
  }

  saveClientDescription(e) {
    e.preventDefault();

    const $form = $(e.currentTarget).closest('form');

    $.post($form.attr('action'), $form.serialize()).done(() => {
      Zebra.notificationsCenter.show('success', 'Client description successfully added');
    });
  }

  setFromFavorite(e) {
    e.preventDefault();

    const $el = $(e.currentTarget);
    const activityId = $el.attr('data-activity-id');
    const roleId = $el.attr('data-role-id');

    this.$activitySelect.select2('val', activityId).trigger('change');
    this.$roleSelect.select2('val', roleId).trigger('change');
  }

  editFavorite(e) {
    e.preventDefault();

    const favoriteId = $(e.currentTarget).attr('data-id');
    const actionDiv = $(`.favorites-list-item[data-id='${favoriteId}'] .favorites-list-actions`);
    const $favoriteName = $(`.favorites-list-item[data-id='${favoriteId}'] .set-favorite`);

    e.stopPropagation();
    $favoriteName.editable('toggle');
    $favoriteName.off('click');
    $favoriteName.removeClass('editable-click');
    $favoriteName.removeClass('editable');
    $(actionDiv).addClass('hidden');

    $favoriteName.on('hidden', () => {
      $(actionDiv).removeClass('hidden');
    });
  }

  removeFavorite(e) {
    e.preventDefault();

    const favoriteId = $(e.currentTarget).attr('data-id');

    let isActive = '0';
    if ($(e.currentTarget).attr('data-active')) {
      isActive = $(e.currentTarget).attr('data-active').toString();
    }

    let confirmValue = true;
    if (isActive === '1') {
      confirmValue = confirm('You will delete the selected favorite. Continue ?');
    }

    if (confirmValue) {
      $.post(`/timesheet/favorites/${favoriteId}/remove`, {}).done(() => {
        $(`.favorites-list-item[data-id='${favoriteId}']`).remove();

        if ($('.favorites-list-item').length === 0) {
          $('.no-favorite').removeClass('hidden');
        }
      });
    }
  }

  /**
   * Add the current activity to users favorite activities
   */
  addToFavorites(e) {
    e.preventDefault();

    const activityDetail = this.$activitySelect.select2('data');
    const roleDetail = this.$roleSelect.select2('data');

    if (!activityDetail) {
      Zebra.notificationsCenter.show('warning', 'Please select an activity to add to your favorites.');
      return;
    }

    let instructions = `Choose a label for your favorite activity “${activityDetail.text}”`;
    if (roleDetail) {
      instructions += ` using the role “${roleDetail.text}”`;
    }

    const name = prompt(instructions, activityDetail.text.trim().substr(0, 32));

    if (!name) {
      Zebra.notificationsCenter.show('warning', 'Please select a name for your favorite entry');
      return;
    }

    const projectActivity = activityDetail.id;
    const roleId = roleDetail ? roleDetail.id : null;
    const $noFavorite = $('.no-favorite');

    $.post('/timesheet/favorites/create', {
      name,
      time: $('#time').val(),
      description: $('#description').val(),
      project_id: projectActivity.split('-')[0],
      activity_id: projectActivity.split('-')[1],
      role_id: roleId,
    }).done((favId) => {
      if (favId) {
        const $favoriteDiv = jQuery('<div/>', {
          class: 'favorites-list-item list-group-item',
          'data-id': favId,
        });

        const $favoriteName = jQuery('<a/>', {
          class: 'set-favorite',
          'data-activity-id': projectActivity,
          'data-role-id': roleId,
          'data-url': `/timesheet/favorites/${favId}/update`,
          html: name,
        });

        const $favoriteActions = jQuery('<div/>', {
          class: 'favorites-list-actions',
          html: `
            <ul class="list-inline">
              <li class="pdg0">
                <button type="button" class="btn-link edit-favorite pdg0" data-id="${favId}">
                  ${icon('pencil')}
                </button>
              </li>
              <li class="pdg0">
                <button type="button" class="btn-link remove-favorite pdg0" data-id="${favId}">
                  ${icon('trash')}
                </button>
              </li>
            </ul>`,
        });

        if ($noFavorite) {
          $noFavorite.addClass('hidden');
        }

        $favoriteDiv.append($favoriteName, $favoriteActions).appendTo($('.favorites-list'));
      }
    });
  }

  addDateChangeListener() {
    const $dateField = $('#date');

    $dateField.on('change', () => {
      $.get(`/timesheets/me/events?date=${$dateField.val()}`).done((result) => {
        $('#events').html(result);
        $('#events').find('[data-toggle="popover"]').popover({
          html: true,
        });
      });

      const date = $('#date').val();
      const [day, month, year] = date.split('.');

      const dayDate = format(new Date(year, month - 1, day), 'YYYY/MM/DD');
      $('#day_view').attr('href', `/timesheet/day/${dayDate}`);
    });

    $('#events').on('click', '[data-action="add-event"]', function (e) {
      e.preventDefault();
      $('#description').val($(this).attr('data-description'));
      $('#time').val($(this).attr('data-time'));
    });
  }
}
