export default function (data) {
  const { id, name, product_id: productId } = data;

  return `
  <tr>
    <td><a href="/project/${id}">${name}</a></td>
    <td colspan="8">You can <a href="/product/${productId}">reload this page</a> 
    for projects details and contributors update</td>
    <td><a class="table-action table-action-remove" href="/product/${productId}/remove-project?project_id=${id}">
    Remove</a></td>
  </tr>
`;
}
