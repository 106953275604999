import { formatNumber, toCHF, numberFormatter, toHours, join } from '@/js/utils/formatter';

/* eslint-disable object-shorthand */
/* eslint-disable max-params */
/* eslint-disable camelcase */
// Allow tablesorter to sort by rating value found in the data attribute "data-rateit-value"
$.tablesorter.addParser({
  id: 'rateit',
  // return false so this parser is not auto detected
  is: function () {
    return false;
  },
  format: function (s, table, cell) {
    const $rateIt = $(cell).find('div[data-rateit-value!=""]div[data-rateit-value]');
    return $rateIt.length > 0 ? $rateIt.data('rateit-value') : '';
  },
  type: 'numeric',
});

$.tablesorter.addParser({
  id: 'datasort',
  is: function () {
    return false;
  },
  format: function (s, table, cell) {
    return $(cell).data('sort-value');
  },
  type: 'numeric',
});

$.tablesorter.characterEquivalents = {
  a: 'ÀÁÂÃÄÅàáâãäåĀāąĄ',
  c: 'ÇçćĆčČ',
  d: 'đĐďĎ',
  e: 'ÈÉÊËèéêëěĚĒēęĘ',
  i: 'ÌÍÎÏìíîïĪī',
  l: 'łŁ',
  n: 'ÑñňŇńŃ',
  o: 'ÒÓÔÕÕÖØòóôõöøŌō',
  r: 'řŘ',
  s: 'ŠšśŚ',
  t: 'ťŤ',
  u: 'ÙÚÛÜÜùúûüůŮŪū',
  y: 'ŸÿýÝ',
  z: 'ŽžżŻźŹ',
};

/**
 * Small helper to share a table with the current settings from the local storage
 *
 * Just call $.tablesorter.generateShareableLink($myTable) to get the link
 */
(function () {
  const HASH_PREFIX = 'tablesorter-settings';
  const LOCAL_STORAGE_FILTER_KEY = 'tablesorter-filters';
  const LOCAL_STORAGE_SORT_KEY = 'tablesorter-savesort';

  function getTablesorterSettingsForTable(page, tableId, storageKey) {
    const json = localStorage.getItem(storageKey) || '{}';
    const settings = JSON.parse(json);
    if (!(page in settings)) {
      return {};
    }
    if (!(tableId in settings[page])) {
      return {};
    }
    return settings[page][tableId];
  }

  function updateTablesorterSettingsForTable(page, tableId, tableSettings, storageKey) {
    const json = localStorage.getItem(storageKey) || '{}';
    const settings = JSON.parse(json);
    if (!(page in settings)) {
      settings[page] = {};
    }
    settings[page][tableId] = tableSettings;
    localStorage.setItem(storageKey, JSON.stringify(settings));
  }

  // Handle potential access with a sharable table link
  // This must be executed before the tablesorter initialisation
  if (location.hash.includes(HASH_PREFIX)) {
    let tableId;
    let tableFilterSettings;
    let tableSortSettings;
    const encodedSettings = location.hash.replace(`#${HASH_PREFIX}=`, '');
    // eslint-disable-next-line prefer-const
    [tableId, tableFilterSettings, tableSortSettings] = JSON.parse(decodeURIComponent(encodedSettings));
    updateTablesorterSettingsForTable(location.pathname, tableId, tableFilterSettings, LOCAL_STORAGE_FILTER_KEY);
    updateTablesorterSettingsForTable(location.pathname, tableId, tableSortSettings, LOCAL_STORAGE_SORT_KEY);
    location.hash = tableId;
  }

  // Allow to put a link to the current table into the clipboard
  $.tablesorter.generateShareableLink = function (tableId) {
    const tableFilterSettings = getTablesorterSettingsForTable(location.pathname, tableId, LOCAL_STORAGE_FILTER_KEY);
    const tableSortSettings = getTablesorterSettingsForTable(location.pathname, tableId, LOCAL_STORAGE_SORT_KEY);
    const encodedSettings = encodeURIComponent(JSON.stringify([tableId, tableFilterSettings, tableSortSettings]));
    return `${location.protocol}//${location.host}${location.pathname}${location.search}#${HASH_PREFIX}=${encodedSettings}`;
  };

  $.tablesorter.resetLocalStorage = function () {
    localStorage.removeItem(LOCAL_STORAGE_FILTER_KEY);
    localStorage.removeItem(LOCAL_STORAGE_SORT_KEY);
  };

  $.tablesorter.calculateFooter = function ($table, $tfoot) {
    const footerCells = $tfoot.find('th[data-calculation]');
    const definitions = [];
    Object.values(footerCells).forEach((cell) => {
      if ($(cell).attr('data-calculation')) {
        definitions.push({
          index: cell.cellIndex,
          type: $(cell).attr('data-calculation'),
          format: $(cell).attr('data-format'),
          prefix: $(cell).attr('data-prefix'),
          suffix: $(cell).attr('data-suffix'),
          empty: $(cell).attr('data-emptyvalue'),
        });
      }
    });

    const tfootRow = $tfoot.find('tr');

    Object.values(definitions).forEach((definition) => {
      let vals = $table.find(`tbody>tr:not(.filtered) > td:nth-child(${definition.index + 1})`);
      vals = vals
        .map((x, y) => {
          if ($(y).attr('data-value') !== 'undefined' && $(y).attr('data-value') !== undefined) {
            return $(y).attr('data-value');
          }
          return $(y).text().trim();
        })
        .toArray();

      const prefix = definition.prefix || '';
      const suffix = definition.suffix || '';
      let finalText = null;
      switch (definition.type) {
        case 'sum':
          const formattedSum = formatValue(
            definition.format,
            vals.reduce((partialSum, cellValue) => partialSum + readNumericValue(cellValue), 0),
          );
          finalText = join(prefix, formattedSum, suffix);
          break;
        case 'count':
          const formattedCount = formatValue(definition.format, vals.length);
          finalText = join(prefix, formattedCount, suffix);
          break;
        case 'distinctcount':
        case 'distinctlist':
          const counts = {};
          for (let i = 0; i < vals.length; i++) {
            counts[vals[i]] = 1 + (counts[vals[i]] || 0);
          }
          if (definition.type === 'distinctcount') {
            const formattedCount = formatValue(definition.format, Object.keys(counts).length);
            finalText = join(prefix, formattedCount, suffix);
          }
          if (definition.type === 'distinctlist') {
            const lines = [];
            for (const key in counts) {
              let legend = key.trim();

              if (legend === '') {
                // eslint-disable-next-line max-depth
                if (definition.empty === undefined) {
                  continue;
                }
                legend = definition.empty;
              }
              const line = `${legend}: ${join(prefix, formatValue(definition.format, counts[key]), suffix)}`;
              lines.push(line.replace(' ', '\xA0'));
            }
            finalText = lines.join('\n');
          }
          break;
        case 'average':
          const formattedAverage = formatValue(
            definition.format,
            Math.round(vals.reduce((partialSum, cellValue) => partialSum + readNumericValue(cellValue), 0) / vals.length),
          );
          finalText = join(prefix, formattedAverage, suffix);
          break;
        default:
          throw new Error('Invalid data-calculation attribute');
      }
      tfootRow[0].cells[definition.index].innerText = finalText;

      function formatValue(format, value) {
        if (format === undefined || format === null) {
          return value;
        }
        switch (format) {
          case 'integer':
            return formatNumber(value, 0);
          case 'float':
            return formatNumber(value, 2);
          case 'chf':
            return toCHF(value);
          case 'percentage':
            return join(numberFormatter(value, 1), '%');
          case 'hour':
            return toHours(value);
          default:
            throw new Error('Invalid data-format attribute');
        }
      }

      function readNumericValue(value) {
        const floatVal = parseFloat(value.replaceAll("'", '').replaceAll(',', '.'));
        if (isNaN(floatVal)) {
          return 0.0;
        }
        return floatVal;
      }
    });
  };
})();
