export default class SalaryUpdateForm {
  constructor(options={}) {
    this.options = {...{
      autoUpdatePoints: true,
      autoUpdateSalary: true,
    }, ...options};
    this.$activeDate = $('#active_date');
    this.$activeConf = $('#active_conf');
    this.$calculatedSalary = $('#calculated_salary');

    this.currentDate = null; //this is the current date, either from the conf or the selected date
    this.conferenceId = null;

    this.$form = $('form[data-calculate-points][data-calculate-salary]');
    this.calculatedPointsUrl = this.$form.data('calculate-points');
    this.calculatedSalaryUrl = this.$form.data('calculate-salary');
    this.systemId = this.$form.data('system');

    // Init the validity selector
    this.updateSelectValidity();
    $('input[name="validity_selector"]').change(() => {
      this.updateSelectValidity();
    });

    if (this.$activeDate) {
      this.$activeDate.change(() => {
        this.currentDate = $('#active_from').val();
        this.getCalculatedPoints();
      });
    }

    $('#start_date').change(() => {
      this.currentDate = $('#start_date').val();
      this.getCalculatedPoints();
    });

    if (this.$activeConf) {
      this.$activeConf.change(() => {
        this.currentDate = null;
        this.getCalculatedPoints();
      });
    }

    $('[id^="criteria_"]').change(() => {
      this.getCalculatedSalary();
    });
    this.getCalculatedPoints();
    this.getCalculatedSalary();
  }

  updateSelectValidity() {
    if ($('select[name="conference_id"] option').length === 0) {
      $('input[name="validity_selector"]').val('date').attr('disabled', 'disabled');
    }

    const validitySelector = $('input[name="validity_selector"]:checked');
    if (validitySelector.length > 0) {
      switch (validitySelector.val()) {
        case 'date':
          this.$activeDate.show();
          this.$activeConf.hide();
          this.currentDate = $('#active_from').val();
          this.conferenceId = null;
          break;
        case 'conference':
          this.$activeConf.show();
          this.$activeDate.hide();
          const confId = this.$activeConf.children('select').val();
          this.conferenceId = confId || null;
          break;
        default:
          throw 'No values has been set for this value';
      }
    }

    this.getCalculatedPoints();
  }

  getCalculatedPoints() {
    if (this.options.autoUpdatePoints === false) {
      return;
    }
    $.post(this.calculatedPointsUrl, { date: this.currentDate, conference_id: this.conferenceId, systemId: this.systemId }, (data) => {
      $.each(data, (index, value) => {
        $(`#criteria_${index}`).val(value.value);
      });
    });
  }

  getCalculatedSalary() {
    if (this.options.autoUpdateSalary === false) {
      return;
    }
    if (this.$calculatedSalary.length > 0) {
      const values = {};
      $.each($('[id^="criteria_"]'), function (index, value) {
        values[$(this).data('criteria-id')] = value.value;
      });
      const systemId = this.systemId;
      $.post(this.calculatedSalaryUrl, { systemId, values }, (data) => {
        this.$calculatedSalary.val(data.salary ?? null);
      });
    }
  }
}
