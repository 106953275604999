$(() => {
  'use strict';

  $(document).on('click', '.toggle-favorite', function (e) {
    const href = $(this).attr('href');

    $.post(href, () => {
      // Apply the change on all fav links of the page
      $(`[href="${href}"]`).each(function () {
        const favorited = $(this).hasClass('favorited');
        $(this)
          .toggleClass('favorited')
          .find('.icon')
          .toggleClass('icon-star icon-star-alt')
          .find('use')
          .attr('xlink:href', (i, val) => val.replace(/(?!#)([-\w])+$/, favorited ? 'star' : 'star-alt'));
      });
    });

    e.preventDefault();
  });
});
