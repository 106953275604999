/* eslint-disable object-shorthand */
/* eslint-disable max-params */
/* eslint-disable camelcase */
/**
 * Small jquery plugin to display a text helper that convert the CHF amount of an input to a WD equivalent
 */
(function ($) {
  $.fn.WDEquivalentHelper = function (options) {
    const $el = this;

    function _update(input) {
      const options = input.WDEquivalentHelperOptions;
      const amount = $(input).val();
      const rate = typeof options.rate === 'function' ? options.rate(input) : options.rate;
      const what = typeof options.what === 'function' ? options.what(input) : options.what;

      let help = '';
      if (amount > 0 && rate > 0) {
        const wd = Math.round(amount / rate / 0.8) / 10;
        help = `${wd} WD equivalent with ${what} at ${rate} CHF/h`;
      }
      $(input).closest('.form-group').find('.wd-equivalent-help-text').remove();
      $(input).closest('.form-group').append(`<div class="wd-equivalent-help-text ${options.extra_class} help-block">${help}</div>`);
    }

    if (options && typeof options === 'string') {
      if (options === 'update') {
        return $el.each(_update);
      }
    }

    const defaults = {
      rate: function (input) {
        return $(input).data('rate');
      },
      what: function (input) {
        return $(input).data('rate-what');
      },
      extra_class: 'col-sm-8 col-sm-offset-4',
    };

    options = $.extend({}, defaults, options);

    return $el.each((i, input) => {
      input.WDEquivalentHelperOptions = options;

      $(input).on('keyup change', () => {
        _update(input);
      });

      _update(input);
    });
  };
})(jQuery);
