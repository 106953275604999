/* eslint-disable object-shorthand */
/* eslint-disable max-params */
/* eslint-disable camelcase */

export function initExpertiseRatingWidget(line, classElement, readOnly) {
  const techId = line.data('tech-id');

  line.find(classElement).ratingWidget({
    min: 0,
    max: 5,
    url: '/technology/update-user-expertise',
    legend: function (val) {
      return $(`.legend .expertise li.val${val}`).text();
    },
    parameters: { technology_id: techId },
    readOnly: readOnly,
  });
}

export function initFeelingRatingWidget(line, classElement, readOnly) {
  const techId = line.data('tech-id');

  line.find(classElement).ratingWidget({
    min: -3,
    max: 2,
    url: '/technology/update-user-feeling',
    legend: function (val) {
      return $(`.legend .feeling li.val${val}`).text();
    },
    parameters: { technology_id: techId },
    readOnly: readOnly,
  });
}

export function initEditableComment(line, classElement, readOnly) {
  const techId = line.data('tech-id');
  // Custom init of edit in place for comment
  if (!readOnly) {
    line.find(classElement).editable({
      params: (params) => ({
        technology_id: techId,
        value: params.value,
      }),
    });
  }
}
