/* eslint-disable object-shorthand */
/* eslint-disable prefer-arrow-callback */
/* eslint-disable camelcase */
export default class UserJobTitles {
  constructor() {
    // Replace the job title selection with a select2 that allow to create new title
    const $select = $('select[name="job_title_ids[]"]');
    const allJobs = $.map($select.find('option'), function (option) {
      return {
        id: option.value,
        text: $(option).text(),
      };
    });
    const userJobIds = $.map($select.find('option:selected'), function (option) {
      return option.value;
    });
    $select.hide();
    $(`<input type="text" name="job_title_ids_and_new" value="${userJobIds.join('🍺')}" />`)
      .insertAfter($select)
      .select2({
        createSearchChoice: function (term, data) {
          if (
            $(data).filter(function () {
              return this.text.toUpperCase().localeCompare(term.toUpperCase()) === 0;
            }).length === 0
          ) {
            return {
              id: `new_"${term}"`,
              text: `"${term}" (un-existing job title, will be created)`,
            };
          }
        },
        separator: '🍺',
        width: '100%',
        createSearchChoicePosition: 'bottom',
        data: allJobs,
        multiple: true,
        maximumSelectionSize: 2,
        formatSelectionTooBig: 'MAX two job title, sorry :(',
      });
  }
}
