import axios from 'axios';
import objectToURLSearchParams from '@/js/utils/objectToURLSearchParams';

export default class Xhr {
  constructor(baseURL, additionalHeaders = {}, interceptErrorMessage = true) {
    this.client = axios.create({
      baseURL: baseURL,
      headers: {
        ...{
          common: {
            'X-Requested-With': 'XMLHttpRequest',
          },
        },
        ...additionalHeaders,
      },
      transformRequest: [objectToURLSearchParams],
    });

    if (interceptErrorMessage) {
      this.client.interceptors.response.use(
        (response) => {
          if (response.data.messages) {
            response.data.messages.forEach((message) => {
              Zebra.notificationsCenter.show(message.type, message.text, { persistant: message.persistant, isHTML: message.isHTML });
            });
          } else if (response.headers.hasOwnProperty('x-flash-messages')) {
            JSON.parse(response.headers['x-flash-messages']).forEach((message) => {
              Zebra.notificationsCenter.show(message.type, message.text, { persistant: message.persistant, isHTML: message.isHTML });
            });
          }
          return response.data.data;
        },
        (error) => {
          if (error.response.status === 500) {
            const id = error.response.data.bug_id || '???';
            const msg = error.response.data.error.message || 'Unknown error';
            const message = encodeURIComponent(`BUG #${id}: ${msg}`);

            if (Zebra === undefined || !Zebra.debug) {
              location.href = `/error?message=${message}`;
            } else {
              Zebra.notificationsCenter.show('error', `[DEBUG] Error during XHR call: ${msg}. Check the console for more info…`);
            }
          } else {
            Zebra.notificationsCenter.show('error', error.response.data.error);
          }

          return Promise.reject(error);
        },
      );
    }
  }

  static getInstance(baseURL = '/', additionalHeaders = {}, interceptErrorMessage = true) {
    const instances = {};
    const key = `${baseURL}-${interceptErrorMessage ? 'withInterceptor' : ''}`;
    if (!(key in instances)) {
      instances[key] = new Xhr(baseURL, additionalHeaders, interceptErrorMessage);
    }
    return instances[key];
  }

  get(url, data = {}) {
    return this.client.get(url, data);
  }

  put(url, data = {}) {
    return this.client.put(url, data);
  }

  post(url, data = {}) {
    return this.client.post(url, data);
  }

  delete(url) {
    return this.client.delete(url);
  }

  static getAPIInstance(interceptErrorMessage = true) {
    const baseURL = '/api/v2';
    const additionalHeaders = {
      post: { 'Content-Type': 'application/x-www-form-urlencoded' },
      put: { 'Content-Type': 'application/x-www-form-urlencoded' },
    };
    return this.getInstance(baseURL, additionalHeaders, interceptErrorMessage);
  }
}
