export default class ViewMore {
  static defaultLength = 192;
  constructor() {
    document.querySelectorAll('[data-view-more]').forEach((node) => {
      const button = document.createElement('a');
      button.innerText = 'View more';
      button.addEventListener('click', () => {
        node.classList.toggle('view-more-collapsed');
        node.classList.toggle('view-more-expanded');
        button.textContent = node.classList.contains('view-more-collapsed') ? 'View more' : 'View less';
      });

      const length = parseInt(node.getAttribute('data-view-more-length')) || ViewMore.defaultLength;
      //we add 10% of the wanted length to the compared length to make sure the view more is really needed
      const length10percent = length + length * 0.1;
      const strippedString = node.textContent;
      if (strippedString.length > length10percent) {
        node.classList.add('view-more-content', 'view-more-collapsed', 'mrgb-');
        node.parentNode.insertBefore(button, node.nextSibling);
      }
    });
  }
}
