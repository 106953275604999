export default class Project {
  constructor() {
    $(document).on('click', '.forecast-delete', this.deleteForecast.bind(this));
    $('button[name="activity_alias_update"], button[name="activity_target_update"]').click(this.updateActivity.bind(this));

    $('.edit-project-forecast').click(() => {
      $('#forecast-tab').trigger('click');
    });

    $('input[data-rate]').WDEquivalentHelper();
    $('#has-budget').on('click', this.updateBudgetFieldsVisibility);
    $('#budget').on('keyup', this.updateBudgetFieldsVisibility);
    $('#budget-top-up-submit-btn').on('click', function () {
      $(this).parent().siblings('.modal-body').children('form').submit();
    });
    this.updateBudgetFieldsVisibility();

    this.initFloatField();
  }

  updateBudgetFieldsVisibility() {
    if ($('#has-budget').length === 1) {
      const withBudget = $('#has-budget').is(':checked');
      const budgetChanged = $('#budget').data('current-budget') !== parseInt($('#budget').val());
      $('.budget-inputs').toggleClass('hidden', !withBudget);
      $('.budget-change-reason').toggleClass('hidden', !withBudget || !budgetChanged);
    }
  }

  deleteForecast(e) {
    const $target = $(e.currentTarget);

    e.preventDefault();
    $.post($target.data('url')).done(() => {
      $target.closest('tr').remove();
    });
  }

  updateActivity(e) {
    e.preventDefault();
    const $form = $(e.currentTarget).closest('form');
    $.post($form.attr('action'), $form.serialize());
  }

  initFloatField() {
    const $pushFloatGroup = $('.push-float-group');
    if ($pushFloatGroup.length === 0) {
      return;
    }

    const $circleIdSelect = $('#circle-id');
    if ($circleIdSelect.val() !== '' && this.hasCircleFloat($circleIdSelect.val())) {
      $pushFloatGroup.removeClass('hidden');
    }

    $circleIdSelect.on('change', (event) => {
      $pushFloatGroup.toggleClass('hidden', event.val === '' || !this.hasCircleFloat(event.val));
    });
  }

  hasCircleFloat(circleId) {
    return $('.push-float-group').data('float-circle-ids').split(',').includes(circleId.toString());
  }
}
