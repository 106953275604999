export default class UserWorkContract {
  constructor() {
    const moneyLabel = document.querySelector('#money');
    const durationForm = document.querySelector('#has-duration-wrapper');
    const durationInput = document.querySelector('#contract-duration');
    const durationCheckbox = document.querySelector('#has-duration-checkbox');

    $('input[type=radio][name=work_as]').change(function () {
      if (this.value === 'employee') {
        moneyLabel.innerHTML = 'Salary per month';
        durationForm.classList.remove('hidden');
        durationInput.classList.add('hidden');
      } else {
        if (this.value === 'freelancer') {
          moneyLabel.innerHTML = 'CHF per hours';
        } else {
          moneyLabel.innerHTML = 'Salary per month';
        }
        durationCheckbox.checked = false;
        durationForm.classList.add('hidden');
        durationInput.classList.remove('hidden');
      }
    });

    durationCheckbox.addEventListener('click', () => {
      if (durationCheckbox.checked) {
        durationInput.classList.remove('hidden');
      } else {
        durationInput.classList.add('hidden');
      }
    });

    new Zebra.UserJobTitles();
  }
}
