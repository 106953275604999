import icon from '@/legacy/common/templates/icon';

/*
  @param key = key to press on the keyboard
  @param label = explanation of the shortcut for the modal
  @param action = action of the key
  @param category = category of the action

  more information : see keyboard_shortcuts.md
*/

const shortcutsMap = new Map();

export default class ShortcutManager {
  init() {
    this.collectShortcutsDefinedByDataAttributes();
    this.register('?', { label: 'Display shortcuts help', action: () => this.showHelpModal(), hint: false });
    this.startKeyboardListener();
  }

  register(key, value) {
    if (shortcutsMap.has(key)) {
      throw `Shortcut '${key}' already registered`;
    }
    if (['Tab', 'Space', 'Enter'].includes(key)) {
      throw `You cannot use '${key}' as a shortcut, it's too generic`;
    }
    const newShortcutDetails = {
      label: value.label || '',
      action: value.action,
      category: value.category || '',
      showInfoModal: value.hint === undefined ? true : value.hint,
    };
    shortcutsMap.set(key, newShortcutDetails);
  }

  collectShortcutsDefinedByDataAttributes() {
    const self = this;
    $('*[data-key]').each(function () {
      const key = this.getAttribute('data-key');
      const label = this.getAttribute('data-label') || this.innerText || this.getAttribute('value') || this.getAttribute('title');
      const hint = !(this.getAttribute('data-no-modal') === '' || false);
      const action = () => {
        this.click();
      };
      const category = this.getAttribute('data-category');
      self.register(key, { label, action, category, hint });
    });
  }

  startKeyboardListener() {
    document.body.addEventListener('keydown', (e) => {
      // If it's a combination of keys, like ctrl+v
      if (e.ctrlKey || e.altKey || e.metaKey) {
        // Submit forms with `data-submit-shortcut` attribute. See keyboard_shortcuts.md
        // TODO, it's a bit ugly to have this here, to be moved in a separate JS module like Form.js
        if (e.key === 'Enter') {
          const form = e.target.closest('form');
          if (form && ['', 'true'].includes(form.dataset.submitShortcut)) {
            form.requestSubmit();
          }
        }

        return;
      }

      // If the keydown is on an element accepting keypress, we don't do anything, too risky to catch
      // unwanted actions. Piece of code form https://stackoverflow.com/a/72779721
      const nonTypingInputTypes = new Set(['button', 'reset', 'submit', 'file']);
      if (
        (e.target.tagName === 'INPUT' && !nonTypingInputTypes.has(e.target.type)) ||
        e.target.tagName === 'TEXTAREA' ||
        e.target.tagName === 'SELECT' ||
        e.target.isContentEditable
      ) {
        return;
      }

      // Check if a shortcut exist
      const shortcut = shortcutsMap.get(e.key);
      if (shortcut === undefined) {
        return;
      }

      e.preventDefault();

      // Display the hint box
      if (document.getElementById('infoModal') === null) {
        $('body').append(this.getInfoModalHTML(e.key, shortcut.label));
      } else {
        document.getElementById('infoKey').innerText = e.key;
        document.getElementById('infoAction').innerText = shortcut.label;
      }
      if (shortcut.showInfoModal) {
        $('#infoModal').modal('show');
      }

      // Process the action
      shortcut.action();
    });
  }

  showHelpModal() {
    if (document.getElementById('helpModal') === null) {
      $('body').append(this.getHelpModalHTML());
    }
    $('#helpModal').modal();
  }

  createHelpModal() {
    function fetchCategories(values) {
      if (!availableCategories.includes(values.category)) {
        values.category === '' ? availableCategories.unshift(values.category) : availableCategories.push(values.category);
      }
    }
    let body = '';
    const availableCategories = [];
    if (shortcutsMap.size <= 1) {
      body = '<p> no shortcuts available in here</p>';
    } else {
      shortcutsMap.forEach(fetchCategories);
      for (const category of availableCategories) {
        if (category) {
          body += `<h4 class="mrgt">${category}</h4>`;
        }

        body += '<ul class="list-stacked">';
        for (const [key, value] of shortcutsMap) {
          if (value.category === category) {
            body += `<li>
            <kbd>${key}</kbd>
            ${icon('arrow-right', { class: 'icon-75 mrgh-' })}
            ${value.label}
            </li>`;
          }
        }
        body += '</ul>';
      }
    }
    return body;
  }

  getInfoModalHTML(key, action) {
    if (key === 'ArrowRight') {
      key = '->';
    }
    if (key === 'ArrowLeft') {
      key = '<-';
    }
    if (key === 'Escape') {
      key = 'Esc';
    }
    const markup = `
        <div id="infoModal" class="modal fade" role="dialog">
           <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Keyboard shortcut</h5>
              </div>
              <div class="modal-body info-body text-center">
                <div style="font-size: 200px;" id="infoKey" class="text-capitalize">${key}</div>
                <div style="font-size: 50px;"><span id="infoAction">${action}</span></div>
              </div>
            </div>
          </div>
        </div> `;
    return markup;
  }

  getHelpModalHTML() {
    const modalBodyContent = this.createHelpModal();
    const markup = `
        <div class="modal" tabindex="-1" id="helpModal">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <div style="display:flex; flex-direction: row; justify-content: space-between;">
                            <h5 class="modal-title" style="font-size: large">Keyboard shortcuts:</h5>
                            <button class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    </div>
                    <div class="modal-body">${modalBodyContent}</div>
                </div>
            </div>
        </div> `;
    return markup;
  }
}
