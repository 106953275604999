<template>
  <div>
    <div class="h6 media-heading">{{ item.entity_name }}</div>
    <div>
      Status:
      <span v-if="role.archive" class="text-danger">Removed</span>
      <span v-else class="text-success">Active</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RoleItem',

  props: {
    item: {
      required: true,
    },
  },

  computed: {
    role() {
      return this.item.entity_object;
    },
  },
};
</script>
