import { updateDiff } from '@/legacy/helpers/diff';
import _ from 'lodash';

export default class DocumentAcknowledgement {
  updatePreview() {
    $.get('/document/preview', $('#document-form').serialize(), (html) => {
      document.querySelector('#preview').innerHTML = html;
      // Render potential diff
      // This must be done manually here as it does apply to content loaded async
      $('[data-diff-base]').each(function () {
        updateDiff($(this).data('diff-base'), $(this).data('diff-new'), $(this));
      });
    });
  }

  enableFormDocPreview() {
    this.updatePreview();
    $('.update-preview-input').on('change keyup', _.debounce(this.updatePreview, 300));
  }

  enableDoubleApprovalConfirmation() {
    $('.approval-btn').on('submit', (e) => {
      e.preventDefault();
      const form = event.currentTarget;
      $('body').append(this.getApprovalConfirmModal(form.dataset.document, form.action));
      $('#approval-modal')
        .modal('toggle')
        .on('hidden.bs.modal', () => {
          $('#approval-modal').remove();
        });
      return false;
    });
  }

  getApprovalConfirmModal(documentName, url) {
    return `
    <div id="approval-modal" class="modal fade" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                    <h4 class="modal-title">Confirmation</h4>
                </div>
                <div class="modal-body">
                    This action is final. Are you really ok to acknowledge <b>${documentName}</b>?
                </div>
                <div class="modal-footer">
                    <a type="button" class="btn btn-default" data-dismiss="modal">Cancel</a>
                    <form action="${url}" style="display: inline;" method="post">
                        <button class="btn btn-primary inline">Yes, I acknowledge!</button>
                    </form>                
                </div>
            </div>
        </div>
    </div>`;
  }

  enableConcernModal() {
    $('.concern-btn').on('submit', (e) => {
      e.preventDefault();
      const form = event.currentTarget;
      $('body').append(this.getConcernModal(form.dataset.document, form.dataset.mode, form.action));
      $('#concern-modal')
        .modal('toggle')
        .on('hidden.bs.modal', () => {
          $('#concern-modal').remove();
        });
      return false;
    });
  }

  getConcernModal(documentName, mode, url) {
    const title = mode === 'approval' ? 'Raising a concern' : 'Send a comment';
    const label = mode === 'approval' ? 'Concern' : 'Comment';
    return `
    <div id="concern-modal" class="modal fade" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <h4 class="modal-title">${title}</h4>
                </div>
                <form action="${url}" method="post">    
                    <div class="modal-body">
                        <p>If you have worries about "<b>${documentName}</b>" please let us know below</p>
                        <label for="concern-comment" class="label-required">${label}:</label>
                        <textarea type="text" id="concern-comment" name="concern-comment" placeholder="Add a comment" class="form-control" rows="3" required></textarea>
                    </div>
                    <div class="modal-footer">
                        <a type="button" class="btn btn-default" data-dismiss="modal">Cancel</a>
                        <button class="btn btn-primary" type="submit">Send</button>
                    </div>
                </form>                
            </div>
        </div>
    </div>`;
  }
}
