/**
 * Small plugin to allow display text diff information, check http://zebra.docker.test/components 080
 */
import { diffWords } from 'diff';
import icon from '@/legacy/common/templates/icon';

export function updateDiff(initial, current, $outputZone) {
  $outputZone.html('');
  const diff = diffWords(initial, current);
  const fragment = document.createDocumentFragment();
  const span = document.createElement('span');
  span.appendChild(document.createTextNode(''));
  fragment.appendChild(span);
  diff.forEach((part) => {
    let color = '';
    if (part.added) {
      color = 'green';
    } else if (part.removed) {
      color = 'red';
    } else {
      color = 'grey';
    }
    const span = document.createElement('span');
    span.style.color = color;
    span.appendChild(document.createTextNode(part.value));
    fragment.appendChild(span);
  });
  $outputZone.get(0).appendChild(fragment);
}

export function updateOnInput($element) {
  updateDiff($element.data('diff-base'), $($element.data('diff-input')).val(), $element);
  $($element.data('diff-input')).on('input', () => {
    updateDiff($element.data('diff-base'), $($element.data('diff-input')).val(), $element);
  });
}

export function createPopover($element) {
  const $temp = $('<span></span>');
  updateDiff($element.data('diff-base'), $element.data('diff-popover'), $temp);
  $element.after(
    $(
      `<a class="help-popover-button btn-link " data-container="body" data-toggle="popover" data-placement="top" data-trigger="focus" tabindex="0">${icon(
        'info',
      )}</a>`,
    ),
  );
  $element.siblings('a').popover({ sanitize: false, html: true, content: $temp.html() });
}
