const getters = {};

const actions = {};

const mutations = {};

export default {
  namespaced: true,
  state: function () {
    if (window.INITIAL_STATE === undefined) {
      return {};
    }
    return window.INITIAL_STATE.users || {};
  },
  getters,
  actions,
  mutations,
};
