export default class SalaryList {
  constructor() {
    $(document).ready(() => {
      $('form.auto-adjust').on('submit', function (e) {
        e.preventDefault();
        $.post($(this).attr('action'), [], (htmlCell) => {
          // Insert the new HTML for effective level
          const $tr = $(this).parents('tr');

          const $children = $tr.children('.effective-level');

          if ($children.length > 0) {
            const $effectiveLevel = $children[0];

            $($effectiveLevel).replaceWith(htmlCell);

            // Activate the popover
            $($effectiveLevel).children('.help-popover-button').popover({ html: true });

            // Update the level delta cell
            const newLevel = $(htmlCell).data('csv-value');
            const calculatedLevel = parseFloat($tr.children('.calculated-level').text());
            const newDelta = (newLevel - calculatedLevel).toFixed(2);
            $(this).closest('td').text(newDelta);
          }
        });
      });

      $('input[type=checkbox][name=need-review]').change(function (e) {
        e.preventDefault();
        $.post($(this.parentNode).attr('action'), {}, (data) => {
          $(this).prop('checked', data.need_review);
          $(this)
            .siblings('span')
            .html(data.need_review ? 'yes' : 'no');
          $('#points').trigger('update');
        });
      });
      const hash = window.location.hash.substr(1);
      if (hash !== '') {
        this.scrollToView($(`#${hash}`));
      }
    });
  }
  scrollToView(element) {
    setTimeout(() => {
      if (!element.is(':visible')) {
        element.css({ visibility: 'hidden' }).show();
        offset = element.offset().top;
        element.css({ visibility: '', display: '' });
      }

      const visibleAreaStart = $(window).scrollTop();
      const visibleAreaEnd = visibleAreaStart + window.innerHeight;

      if (offset < visibleAreaStart || offset > visibleAreaEnd) {
        // Not in view so scroll to it
        $('html,body').animate({ scrollTop: offset - window.innerHeight / 3 }, 500);
        return false;
      }
      return true;
    }, 1000);
    let offset = element.offset().top;
  }
}
