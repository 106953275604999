<template>
  <div class="search-result search-result--inline media">
    <div class="media-left" v-if="showImage && option.entity_icon">
      <div
        :class="`search-result-icon search-result-icon--small search-result-icon-${option.entity_type}`"
        :style="`background-image: url(${option.entity_icon})`"
      ></div>
    </div>
    <div class="media-body">
      <div v-if="showImage && option.entity_type !== 'custom'" class="label label-default pull-right">{{ option.entity_type }}</div>
      <component :is="`${option.entity_type}-item`" :item="option" :currentSearch="currentSearch"></component>
    </div>
  </div>
</template>

<script>
import ActivityItem from './ActivityItem.vue';
import BillItem from './BillItem.vue';
import CircleItem from './CircleItem.vue';
import CompanyItem from './CompanyItem.vue';
import ContactItem from './ContactItem.vue';
import CustomItem from './CustomItem.vue';
import GroupItem from './GroupItem.vue';
import PageItem from './PageItem.vue';
import ProjectItem from './ProjectItem.vue';
import ProductItem from './ProductItem.vue';
import RoleItem from './RoleItem.vue';
import TechnologyItem from './TechnologyItem.vue';
import TrackingItem from './TrackingItem.vue';
import UserItem from './UserItem.vue';
import TrainingItem from './TrainingItem.vue';
import DocumentationItem from '@/vue/components/common/search/DocumentationItem';

export default {
  components: {
    ActivityItem,
    TechnologyItem,
    BillItem,
    CircleItem,
    CompanyItem,
    ContactItem,
    CustomItem,
    GroupItem,
    PageItem,
    ProjectItem,
    ProductItem,
    RoleItem,
    TrackingItem,
    UserItem,
    TrainingItem,
    DocumentationItem,
  },

  name: 'SearchResult',

  props: {
    option: {
      required: true,
    },
    showImage: {
      default: true,
    },
    currentSearch: {
      required: false,
      default: '',
    },
  },

  data() {
    return {
      focused: false,
      search: {},
      source: null,
      results: [],
    };
  },
};
</script>
