import _ from 'lodash';

const getters = {
  getTimesheetByPositionId: (state) => (positionId) => _.pickBy(state, (t) => t.bill_position_id === positionId),
};

const actions = {
  linkTimesheetsAction({ commit, dispatch }, { positionId, timesheetIds }) {
    return this.$publicApi.put(`/positions/${positionId}/linkTimesheets`, { timesheet_ids: timesheetIds }).then((result) => {
      for (const key in result.timesheets) {
        commit('UPDATE_TIMESHEET', { timesheet: result.timesheets[key] });
      }

      //TODO when refactor branch will be merged (ZBR-3022), delete the refreshPositionAction
      // (The positions will be all refreshed when we refresh the bill)
      dispatch('bills/refreshPositionAction', result.position, { root: true });
      dispatch('bills/refreshBillAction', result.bill, { root: true });
    });
  },

  unlinkTimesheetsAction({ commit, dispatch }, { positionId, timesheetIds }) {
    return this.$publicApi.put(`/positions/${positionId}/unlinkTimesheets`, { timesheet_ids: timesheetIds }).then((result) => {
      for (const key in result.timesheets) {
        commit('UPDATE_TIMESHEET', { timesheet: result.timesheets[key] });
      }

      //TODO when refactor branch will be merged (ZBR-3022), delete the refreshPositionAction
      // (The positions will be all refreshed when we refresh the bill)
      dispatch('bills/refreshPositionAction', result.position, { root: true });
      dispatch('bills/refreshBillAction', result.bill, { root: true });
    });
  },

  updateTimesheet({ commit }, { timesheet }) {
    commit('UPDATE_TIMESHEET', { timesheet });
  },
};

const mutations = {
  UPDATE_TIMESHEET(state, { timesheet }) {
    // We can't override the whole timesheet since the default once contains more information
    // Than the one we get here, let's override key by key instead for now
    // TODO: use the same Timesheet schema everywhere
    Object.keys(timesheet).forEach((key) => {
      state[timesheet.id][key] = timesheet[key];
    });
  },

  UNLINK_BY_POSITION_ID(state, positionId) {
    _.each(state, (timesheet) => {
      if (timesheet.bill_position_id === positionId) {
        state[timesheet.id].bill_position_id = null;
      }
    });
  },
};

export default {
  namespaced: true,
  state: function () {
    if (window.INITIAL_STATE === undefined) {
      return {};
    }
    return window.INITIAL_STATE.timesheets || {};
  },
  actions,
  mutations,
  getters,
};
