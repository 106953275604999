/* eslint-disable object-shorthand */
/* eslint-disable max-params */
/* eslint-disable camelcase */
/* eslint-disable eqeqeq */
import technologyTableRow from '@/legacy/apps/products/templates/technologyTableRow';
import _ from 'lodash';

/**
 * Editing capabilities for technologies tables
 */
$(() => {
  $.fn.techTable = function (options) {
    const config = $.extend(
      {},
      {
        allTechnologies: {},
        tableTechnologies: {},
        readOnly: false,
        postAdd: null,
        newLineValues: {},
        template: technologyTableRow,
        extraLineData: {},
      },
      options,
    );

    const $table = $(this);

    function initRemoveButtonForLine($line) {
      $line.find('.table-action-remove').bind('click', function (e) {
        e.preventDefault();
        const $line = $(this).closest('tr');
        const techId = $line.data('tech-id');
        const $link = $(this);
        $.post($link.attr('href'), () => {
          // Remove the line
          $line.animate(
            {
              opacity: 0,
            },
            500,
            () => {
              $line.remove();
            },
          );
          // Enable it in the list
          $.each(config.allTechnologies, (i, t) => {
            if (t.id === techId) {
              delete config.allTechnologies[i].disabled;
              return false;
            }
          });
        });
      });
    }

    // Add a techno to the user list
    function addNewTechno(id, values, postToServer) {
      // Find it in the main list
      let techIndex;
      $.each(config.allTechnologies, (i, t) => {
        if (t.id === id) {
          techIndex = i;
          return false;
        }
      });
      if (postToServer) {
        $.post(config.addUrl, { technology_id: id });
        values.category = config.allTechnologies[techIndex].category;
      }
      // Mark it as disabled, so it's no more selectable in select2
      config.allTechnologies[techIndex].disabled = true;
      // Render the template and add it to the table
      const data = $.extend(
        {
          id: config.allTechnologies[techIndex].id,
          name: config.allTechnologies[techIndex].text,
          category: config.allTechnologies[techIndex].category,
        },
        values,
        config.extraLineData,
      );
      const $line = $(config.template(data));
      $table.find('tbody').append($line);

      // Init widgets
      if (config.postAdd) {
        config.postAdd($line);
      }
      if (!config.readOnly) {
        initRemoveButtonForLine($line);
      }
    }

    // Now the dropdown is a Vue component, so we need to globally expose a method for the Vue widget to be able to add
    // a new line to the table
    if (!config.readOnly) {
      Zebra.addTechnoToTechTable = function (id, fullObject) {
        const existingIds = _.map(config.allTechnologies, 'id');
        if (!existingIds.includes(id)) {
          config.allTechnologies.push(fullObject);
        }
        addNewTechno(id, config.newLineValues, true);
      };
    }

    return this.each(function () {
      const $table = $(this);

      $table.find('tbody').empty();
      $.each(config.tableTechnologies, (pos, t) => {
        addNewTechno(t.id, t, false);
      });

      if (config.readOnly) {
        $table.find('a.table-action-remove').remove();
      }
    });
  };
});
