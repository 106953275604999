/* eslint-disable object-shorthand */
/* eslint-disable max-params */
/* eslint-disable camelcase */
/* eslint-disable indent */
import getColorForString from '@/js/utils/getColorForString';
import Highcharts from 'highcharts';

export default class TechnologyTrends {
  constructor(data) {
    this.data = data;

    this.initField();
    this.buildCharts();
  }

  initField() {
    $('#ids').select2({
      placeholder: 'Type technology name…',
      formatSelection: function (tech) {
        const techUrl = `/technology/${tech.id}`;
        return `
          <span class="color-box" style="background-color: ${getColorForString(tech.text)}"></span>
          <a href="${techUrl}" onclick="window.location='${techUrl}'">${tech.text}</a>
        `;
      },
    });
  }

  buildCharts() {
    const charts = {};

    if (this.data.length === 0) {
      return;
    }

    $('.trends-graph').each((i, graph) => {
      const $graph = $(graph);

      charts[$graph.data('serie')] = new Highcharts.Chart({
        chart: {
          renderTo: $graph.attr('id'),
        },
        credits: {
          enabled: false,
        },
        plotOptions: {
          line: {
            marker: {
              enabled: false,
            },
          },
        },
        title: {
          text: $graph.data('title'),
        },
        xAxis: {
          categories: this.data[Object.keys(this.data)[0]].months,
          labels: {
            enabled: false,
          },
        },
        yAxis: {
          title: {
            enabled: false,
          },
        },
        legend: { enabled: false },
      });
    });

    $.each(this.data, (name, data) => {
      $.each(data, (serie, data) => {
        if (serie !== 'months') {
          charts[serie].addSeries({
            name: name,
            data: data,
            color: getColorForString(name),
          });
        }
      });
    });
  }
}
