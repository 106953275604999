/* eslint-disable arrow-body-style */ /* eslint-disable object-shorthand */
/* eslint-disable max-params */
/* eslint-disable camelcase */

import '@/js/icons';
import '@/legacy/ui/tablesorterExtension';
import '@/legacy/ui/jquery.ratingWidget';
import '@/legacy/ui/jquery.weekSparkline';
import '@/legacy/ui/jquery.techTable';
import '@/legacy/ui/jquery.highlight';
import '@/legacy/ui/jquery.WDEquivalentHelper';
import '@/legacy/ui/favorite';
import 'x-editable/dist/bootstrap3-editable/js/bootstrap-editable.js';
import activityTransfer from '@/legacy/apps/activities/templates/activityTransfer';
import { updateDiff, updateOnInput, createPopover } from '@/legacy/helpers/diff';
import autoExpandTextarea from '@/js/utils/autoExpandTextarea';
import icon from '@/legacy/common/templates/icon';

jQuery(($) => {
  /**
   * Initialize Bootstrap popovers
   */
  $('[data-toggle="popover"]').popover({
    html: true,
  });
  /**
   * Initialize Table Sorter
   */
  function activateTablesorter($parent = null) {
    const selector = 'table.tablesorter';
    const $elements = $parent !== null ? $parent.find(selector) : $(selector);
    $elements.each(function () {
      const $table = $(this);
      // Tablesorter already initialized on this table
      if ($table.hasClass('tablesorter-default')) {
        return;
      }

      const options = {
        dateFormat: 'ddmmyyyy',
        // debug: 'core filter', // Uncomment to plenty of logs in the browser console
        sortLocaleCompare: true,
        ignoreCase: true,
        widthFixed: true,
        showProcessing: true,
        widgets: ['outputzebra', 'stickyHeaders', 'saveSort'],
        // Used to tell tablesorter what value to use for sorting and filtering
        textExtraction: function (node) {
          return $(node).data('tablesorter-value') ? $(node).data('tablesorter-value') : node.textContent;
        },
      };
      const widgetOptions = {
        output_saveFileName: 'zebra_table_export',
        stickyHeaders: 'table-sticky-header',
        stickyHeaders_offset: 60, //Offset to avoid being covered by header
        filter_formatter: {},
        filter_selectSource: {},
        saveSort: true,
      };

      if (typeof $table.data('tablefilter') !== 'undefined') {
        options.widgets.push('filter');
        widgetOptions.filter_cssFilter = 'form-control input-sm';
        widgetOptions.filter_saveFilters = true;
        // Highlight the inputs used for filtering
        $table.bind('filterEnd', (event) => {
          $(event.currentTarget)
            .find('.tablesorter-filter')
            .each(function () {
              $(this).toggleClass('active', $(this).val() !== '');
            });
        });
      }
      $table.find('thead>tr>th').each(function (key) {
        if ($(this).data('filter') === 'select') {
          $(this).addClass('filter-select');
        } else if ($(this).data('filter') === 'multiselect') {
          const $match = $(this).data('multiselect-split');
          widgetOptions.filter_formatter[key] = function ($cell, indx) {
            return $.tablesorter.filterFormatter.select2($cell, indx, {
              match: !!$match,
            });
          };

          if (!!$match) {
            //If we specified a separator, we split all values in the cells with that separator
            widgetOptions.filter_selectSource[key] = function (table, column) {
              const items = [];
              const $match = $(table)
                .find(`thead tr th:nth-child(${column + 1})`)
                .data('multiselect-split');

              $(table)
                .find(`tbody tr td:nth-child(${column + 1})`)
                .each(function () {
                  let elements = $(this).attr('data-value') || $(this).text();
                  elements = elements.replace(/(<([^>]+)>)/gim, '');
                  elements = elements.replace(/\r?\n|\r/gim, '');
                  elements = elements.split($match);
                  elements.forEach((element) => {
                    if (element.trim() !== '') {
                      items.push(element.trim());
                    }
                  });
                });
              return items;
            };
          }
        }
      });

      widgetOptions.output_saveFileName = $table.data('export-filename') || $table.attr('id');

      options.widgetOptions = widgetOptions;

      $('.export-btn').click(function (e) {
        const currenttable = $(this).data('table');
        e.preventDefault();
        const type = $(this).data('export-type') || 'csv';
        $(`#${currenttable}`).trigger(`outputTable-${type}`);
      });

      // Reset the table by triggering a sortReset and filterReset events on the table
      if ($table.data('reset-filter') !== undefined) {
        $($table.data('reset-filter')).click(() => {
          $table.trigger('filterReset').trigger('sortReset');
          return false;
        });
      }

      $table.tablesorter(options);

      const $autoFooter = $(this).find('tfoot[data-autocalculations]');
      $table.on('filterEnd', function () {
        if ($autoFooter) {
          $.tablesorter.calculateFooter($table, $autoFooter);
        } else {
          // This hide the tablefooter, beacause when we use filters, the numbers are not dynamic.
          if ($(this).find('tr.filtered').length > 0) {
            $table.find('tfoot').addClass('hidden');
          } else {
            $table.find('tfoot').removeClass('hidden');
          }
        }
      });
      if ($autoFooter) {
        $.tablesorter.calculateFooter($table, $autoFooter);
      }
    });

    // Activate the share buttons
    const $buttons = $parent !== null ? $parent.find('.share-btn') : $('.share-btn');
    $buttons.on('click', function (e) {
      e.preventDefault();
      const tableId = $(this).data('table');
      const text = $.tablesorter.generateShareableLink(tableId);
      $(this).attr('data-url', text);
      const clipboard = new Zebra.Clipboard(`#${$(this).attr('id')}`, {
        text: function (trigger) {
          return trigger.getAttribute('data-url');
        },
      });
      clipboard.on('success', () => {
        Zebra.notificationsCenter.show('success', 'Copied!');
        clipboard.destroy();
      });
      clipboard.on('error', () => Zebra.notificationsCenter.show('error', 'Copy not possible 😢'));
    });

  }
  activateTablesorter();

  /**
   * Initialize Select2
   */
  function betterSelect2Matcher(term, text) {
    const terms = term.split(' ');
    for (let i = 0; i < terms.length; i++) {
      if (text.toUpperCase().indexOf(terms[i].toUpperCase()) === -1) {
        return false;
      }
    }
    return true;
  }

  function activateSelect2($parent = null) {
    const selector = 'select.select2';
    const $elements = $parent !== null ? $parent.find(selector) : $(selector);
    $elements.each(function () {
      const $select = $(this);

      if ($select.hasClass('shortcut-links')) {
        const $selectAllLink = $('<a href="#" class="mrgl-- small">select all</a>');
        const $removeAllLink = $('<a href="#" class="small">remove all</a>');
        $select.after($removeAllLink);
        $select.after(' | ');
        $select.after($selectAllLink);

        $selectAllLink.click((e) => {
          const selected = [];
          $select.find('option').each((i, e) => {
            selected[selected.length] = $(e).attr('value');
          });
          $select.select2('val', selected);
          e.preventDefault();
        });
        $removeAllLink.click((e) => {
          $select.select2('val', []);
          e.preventDefault();
        });
      }

      if ($select.hasClass('autosubmit')) {
        $select.on('change', () => {
          $select.closest('form').submit();
        });
      }

      $select.select2({
        allowClear: true,
        matcher: betterSelect2Matcher,
      });
    });
  }
  activateSelect2();

  function activateSelect2Async($parent = null) {
    const selector = '.select2-async';
    const $elements = $parent !== null ? $parent.find(selector) : $(selector);
    $elements.each(function () {
      const $select = $(this);
      $select
        .select2({
          allowClear: true,

          multiple: $select.attr('multiple'),
          minimumInputLength: 0,
          ajax: {
            // instead of writing the function to execute the request we use Select2's convenient helper
            url: $select.data('url'),
            dataType: 'json',
            quietMillis: 250,
            /* eslint-disable-next-line */
            data(term, page) {
              return {
                query: term, // search term
              };
            },
            /* eslint-disable-next-line */
            results(data, page) {
              // parse the results into the format expected by Select2.
              // since we are using custom formatting functions we do not need to alter the remote JSON data
              return { results: data };
            },
            cache: true,
          },
          initSelection(element, callback) {
            const selText = $(element).data('selected');
            $(element).val('');
            if (selText.length === 0) {
              return;
            }
            callback(selText);
          },
          escapeMarkup: function (m) {
            return m; // we do not want to escape markup since we are displaying html in results
          },
          formatResult: function (item) {
            return item.text; // Results are already formated server side, nothing to do here
          },
          matcher: betterSelect2Matcher,
        })
        .select2('val', []); // this allow to preselect when (multiple = false) https://github.com/select2/select2/issues/2086#issuecomment-33515436
    });
  }
  activateSelect2Async();

  /**
   * Initialize edit in place
   */
  $.fn.editable.defaults.mode = 'inline';
  $.fn.editable.defaults.send = 'always';
  $.fn.editable.defaults.params = function (params) {
    // Send only the value by default
    const obj = {
      value: params.value,
    };

    // Append the object ID for the rare cases it’s not in the URL
    if (params.pk) {
      obj.pk = params.pk;
    }

    return obj;
  };
  $.fn.editable.defaults.display = function (value, sourceData) {
    // Display the content coming back from the server
    // to benefit from the layout (Markdown for example)
    $(this).html(sourceData);
  };
  $.fn.editableform.template =
    '<form class="editableform">' +
    '<div class="control-group"><div>' +
    '<div class="editable-input"></div>' +
    '<div class="editable-buttons"></div></div>' +
    '<div class="editable-error-block"></div></div>' +
    '</form>';
  $.fn.editableform.buttons = `<button type="submit" class="btn btn-primary btn-sm pdgh-- editable-submit">
    ${icon('check')}
  </button>
  <button type="button" class="btn btn-default btn-sm pdgh-- editable-cancel">
    ${icon('remove')}
  </button>`;

  $('.editable').each(function () {
    let mode = $.fn.editable.defaults.mode;

    if ($(this)[0].hasAttribute('data-mode')) {
      mode = $(this).data('data-mode');
    }

    $(this).editable({
      select2: {
        /* eslint-disable-next-line */
        escapeMarkup: function (m) {
          return m;
        },
        allowClear: true,
        placeholder: ' ',
      },
      onblur: mode === 'inline' ? 'ignore' : 'submit',
    });

    // Allow to enter edition mode by pressing enter when focused
    $(this).on('keypress', (e) => {
      if (e.key === 'Enter') {
        $(this).trigger('click');
      }
    });

    $(this).on('click', 'a', (e) => {
      if (e.target === e.currentTarget) {
        e.stopPropagation();
      }
    });
  });

  $('.editable-multiple').each(function () {
    let mode = $.fn.editable.defaults.mode;

    if ($(this)[0].hasAttribute('data-mode')) {
      mode = $(this).data('data-mode');
    }

    const data = $(this).data('source');
    $(this).editable({
      type: 'select2',
      mode: mode,
      source: data,
      display: function (value) {
        const html = [];
        const checked = $.fn.editableutils.itemsByValue(value, data, 'id');

        if (checked.length) {
          $.each(checked, function (i, v) {
            html.push($.fn.editableutils.escape(v.text));
          });
          $(this).html(html.join(', '));
        } else {
          $(this).empty();
        }
      },
      inputclass: 'editable-min-width-300',
      select2: {
        allowClear: true,
        multiple: true,
        tokenSeparators: [',', ' '],
        matcher: betterSelect2Matcher,
      },
    });
  });

  $('.editable-tags').each(function () {
    const tags = $(this).data('availabletags');
    $(this).editable({
      type: 'select2',
      mode: 'popup',
      source: $(this).data('source'),
      inputclass: 'editable-min-width-300',
      select2: {
        allowClear: true,
        multiple: true,
        tags: tags,
        tokenSeparators: [',', ' '],
        matcher: betterSelect2Matcher,
        formatResultCssClass: function (obj) {
          if (!tags.includes(obj.id)) {
            obj.text = `${obj.text} (new! tag)`;
          }
        },
      },
    });
  });

  $('.editable-role').each(function () {
    const source = jQuery.makeArray(
      $('#hidden_role_selector')
        .find('option')
        .map((i, op) => ({
          id: parseInt($(op).val()),
          text: $(op).text(),
        })),
    );

    $(this).editable({
      type: 'select2',
      mode: 'popup',
      source: source,
      inputclass: 'editable-min-width-300',
      select2: {
        placeholder: 'Select a role',
        allowClear: true,
        matcher: betterSelect2Matcher,
      },
    });
  });

  $('.editable-alias').each(function () {
    $(this).editable({
      error: function (data) {
        const errorDetail = data.responseJSON;
        if (!('reason' in errorDetail) || errorDetail.reason !== 'already_used') {
          throw errorDetail;
        }
        $('body').append(activityTransfer(errorDetail));
        $('#editable-alias').modal('toggle');
        $('#editable-alias').on('hidden.bs.modal', () => {
          $('#editable-alias').remove();
        });
      },
    });
  });

  $('.editable-budget').each(function () {
    $(this).editable({
      success: function () {
        $('.budget-sum').html('');
      },
    });
  });

  $('.editable-toggle-button').click(function (e) {
    e.preventDefault();
    e.stopPropagation();
    $(this).siblings('.editable').editable('show');
  });

  /**
   * Initialize bootstrap datepicker
   * {@link https://bootstrap-datepicker.readthedocs.io/en/latest/}
   */
  function activateDatepickers($parent = null) {
    const selector = 'input.bootstrap-datepicker';
    const $elements = $parent !== null ? $parent.find(selector) : $(selector);
    $elements.datepicker({
      format: 'dd.mm.yyyy',
      autoclose: true,
      todayBtn: 'linked',
      clearBtn: true,
      weekStart: 1,
      zIndexOffset: 10000,
    });
  }
  activateDatepickers();

  /**
   * Initialize markdown editor
   */
  async function activateMarkdownEditor($parent = null) {
    const selector = 'textarea[data-markdown-editor]';
    const $elements = $parent !== null ? $parent.find(selector) : $(selector);
    const $isDarkTheme = document.querySelector('html').classList.contains('theme-dark');
    $.each($elements, async function () {
      // Download the editor files
      const { default: Editor } = await import(/* webpackChunkName: "markdown-editor" */ '@toast-ui/editor');
      await import(/* webpackChunkName: "markdown-editor-css" */ '@toast-ui/editor/dist/toastui-editor.css');
      await import(/* webpackChunkName: "markdown-editor-dark-css" */ '@toast-ui/editor/dist/theme/toastui-editor-dark.css');
      // Hide the textarea field
      const $textarea = $(this);
      $textarea.hide();
      // Create dom element for editor
      const $markdownContainer = $('<div></div>');
      $markdownContainer.insertBefore($textarea);
      const editor = new Editor({
        el: $markdownContainer.get(0),
        height: 'auto',
        initialEditType: 'wysiwyg',
        previewStyle: 'vertical',
        theme: $isDarkTheme ? 'dark' : 'default',
        initialValue: $textarea.val(),
        toolbarItems: [
          ['heading', 'bold', 'italic', 'strike'],
          ['hr', 'quote'],
          ['ul', 'ol', 'indent', 'outdent'],
        ],
      });
      // When changing content in editor put the new value in the hidden textarea
      $markdownContainer.children($("div[contenteditable='true']")).on('keyup', () => {
        $textarea.text(editor.getMarkdown());
      });
    });
  }
  activateMarkdownEditor();

  /**
   * Save current tab in URL
   */
  $('a[data-toggle="tab"]').on('shown.bs.tab', (e) => {
    const id = e.target.getAttribute('href');
    if (id) {
      window.history.replaceState({}, '', id);
    }
  });

  /**
   * Show potential tab in current URL
   */
  const hash = window.location.hash;
  if (hash.indexOf('/') === -1) {
    // We do not want this thing to be active on JS routed hashes
    $(`a[data-toggle="tab"][href="${hash}"]`).tab('show');
  }

  /**
   * Allow to post a form via ajax and to redirect the user to a new page in case of success
   */
  $(document.body).on('submit', '.ajax-form', function (e) {
    e.preventDefault();
    const $form = $(this);
    $.post($form.attr('action'), $form.serialize()).done((data) => {
      if (data.success && data.refresh) {
        window.location.reload();
      }
      if (data.success && data.location) {
        window.location.href = data.location;
      }
    });
    return false;
  });

  /**
   * Init diff widget
   */
  $('[data-diff-base]').each(function () {
    if ($(this).data('diff-new')) {
      updateDiff($(this).data('diff-base'), $(this).data('diff-new'), $(this));
    } else if ($(this).data('diff-input')) {
      updateOnInput($(this));
    } else if ($(this).data('diff-popover')) {
      createPopover($(this));
    }
  });

  /**
   * Auto expand textarea
   */
  $('textarea.auto-expand').each(function () {
    autoExpandTextarea(this);
  });

  /**
   * Link with modal display `in-modal-display'
   *
   * Possible data attribute options
   * --data-modal-title       Allow to set a text for title
   * --data-modal-no-footer   Allow to render without footer
   * --data-modal-no-header   Allow to render without header
   * --data-modal-render-all  By default, the link content will be render into the modal-body, but you can also re-render
   *                          the full modal-content
   * --data-modal-size        Set the modal size
   *
   * To understand what are footer/header/body, just check the Bootstrap naming:
   * https://getbootstrap.com/docs/3.4/javascript/#static-example
   */
  $(document).on('click', 'a.in-modal-display', function (e) {
    // Do not open in modal if it's a special click
    if (e.button !== 0 || e.ctrlKey || e.altKey || e.metaKey) {
      return;
    }
    const $link = $(this);
    const $modal = new Zebra.Modal({
      title: $link.data('modal-title'),
      content: '<i>Loading...</i>',
      footer: $link.data('modal-no-footer') !== undefined ? '' : null,
      header: $link.data('modal-no-header') !== undefined ? '' : null,
      size: $link.data('modal-size') ?? null,
    });
    $.get($link.attr('href'), (html) => {
      const renderAll = $link.data('modal-full-content') !== undefined;
      const targetElement = renderAll ? '.modal-content' : '.modal-body';
      $modal.find(targetElement).html(html);
      activateDatepickers($modal);
      activateSelect2($modal);
      activateSelect2Async($modal);
      activateTablesorter($modal);
      activateMarkdownEditor($modal);
      Zebra.initZebraVueComponents($modal.get(0));
      Zebra.activateInputEntitySelector($modal.get(0));
    });
    e.preventDefault();
    return false;
  });
});
