/* eslint-disable object-shorthand */
/* eslint-disable max-params */
/* eslint-disable camelcase */
/* eslint-disable arrow-body-style */

import 'jquery.rateit';

/**
 * Overriding the rateit plugin for custom usage
 * * Add custom classes on ratting elements to allow better styling
 * * Add an over state by calling the legend function
 * * Use loader.js to provide a user feedback
 */
$(() => {
  /* This is require to create custom styling (like for the feeling widget) */
  function tagRangeWithValue(widget, rangeName, value) {
    $(widget).find(`.rateit-${rangeName}`).removeClass('v-2 v-1 v0 v1 v2 v3 v4 v5 vnull').addClass(`v${value}`);
  }

  $.fn.ratingWidget = function (options) {
    const defaults = {
      readOnly: false,
      legend: (val) => val,
      parameters: {},
    };
    const config = $.extend({}, defaults, options);
    return this.each(function () {
      const $this = $(this);
      const initialVal = $this.data('rateit-value');

      $this
        .rateit({
          min: config.min,
          max: config.max,
          resetable: false,
          step: 1,
          readonly: config.readOnly,
          starwidth: 28,
          starheight: 24,
          ispreset: true,
        })
        .on('rated', function () {
          const value = $(this).rateit('value');
          if (value === -3) {
            return;
          }

          tagRangeWithValue(this, 'selected', value);

          $.post(config.url, $.extend(config.parameters, { value: value })).done(() => {
            Zebra.notificationsCenter.show('success', 'Rating saved!', {
              replace: true,
            });
          });
        })
        .on('over', function (event, value) {
          if (value !== null && config.legend) {
            $(this).attr('title', config.legend(value));
            tagRangeWithValue(this, 'hover', value);
          }
        });

      /* Already select the initial value */
      tagRangeWithValue(this, 'selected', initialVal);
    });
  };
});
