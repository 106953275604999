<template>
  <div>
    <div class="h6 media-heading">{{ activity.project.name }} <span class="text-muted pdgh--">❯</span> {{ item.entity_name }}</div>
    <div>{{ activity.alias }}</div>
  </div>
</template>

<script>
export default {
  name: 'ActivityItem',

  props: {
    item: {
      required: true,
    },
  },

  computed: {
    activity() {
      return this.item.entity_object;
    },
  },
};
</script>
