import _ from 'lodash';

/**
 * Display different type of notifications
 */
const DEFAULTS = {
  hideDelay: 5000,
  replace: false,
  persistant: false,
  isHTML: false,
};
const TYPES = {
  loading: {
    class: 'notification-loading',
    icon: '',
    message: 'Loading…',
    persistant: true,
  },
  success: {
    class: 'notification-success',
    icon: 'success',
    message: 'Success!',
  },
  error: {
    class: 'notification-error',
    icon: 'exclamation',
    message: 'An error occurred.',
  },
  info: {
    class: 'notification-info',
    icon: 'info',
  },
  warning: {
    class: 'notification-warning',
    icon: 'warning',
  },
};
const LOADER = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 20" class="loader">
  <polygon class="first" points="14.8 20 10.7 17.9 15.3 9 19.9 0 24 2.1 19.4 11 14.8 20"/>
  <polygon class="second" points="4.1 20 0 17.9 4.7 9 9.3 0 13.4 2.1 8.8 11 4.1 20"/>
</svg>`;

export default class NotificationsCenter {
  constructor() {
    this.notifications = [];
  }

  /**
   * Display a message
   * @param  {String} type    Type of notifications according to this.types
   * @param  {String} message The message to display; default: correspond to type
   * @param  {Object} options
   * @return {Obj}            The created item
   */
  show(type, message, options = {}) {
    options = Object.assign({}, DEFAULTS, options);

    if (options.replace) {
      this.clear();
    }

    this.createContainer();

    return this.createItem(type, message, options);
  }

  /**
   * Hide a message
   * @param  {Obj} $notif The notification DOM node as a jQuery object
   */
  hide($notif) {
    const index = this.notifications.indexOf($notif);
    if (index > -1) {
      this.notifications.splice(index, 1);
    }

    $notif.slideUp('fast');
  }

  /**
   * Create the notifications container
   */
  createContainer() {
    if (!this.$el) {
      this.$el = $('<ul/>', {
        class: 'notifications-center list-unstyled',
      });

      this.$el.appendTo('body');
    }
  }

  /**
   * Return a close button with a click listener to close the given notification
   * @param  {Object} $item The jQuery object of the notification
   * @return {Object}       jQuery object of the close button
   */
  getCloseButton($item) {
    const $btn = $('<button type="button" class="close" title="Close">&times;</button>');
    $btn.on('click', this.hide.bind(this, $item));
    return $btn;
  }

  /**
   * Create the notification node
   * @param  {String} type    Type of notifications according to this.types
   * @param  {String} message The message to display; default: correspond to type
   * @return {Obj}            The created item
   */
  createItem(type, message, options) {
    const typeObj = TYPES[type];
    if (typeObj === undefined) {
      throw new TypeError(`this is an error from the Notification center : the type of notification : ${type} is unknown.`);
    }
    let text = message || typeObj.message || '';
    const persistant = typeObj.persistant || options.persistant;
    if (!options.isHTML) {
      text = _.escape(text);
    }
    const $item = $(`
<li class="notification ${typeObj.class}" style="display:none">
  <div class="media notification-media">
    <div class="media-left media-middle">
      <svg aria-hidden="true" class="icon icon-${typeObj.icon} icon-150" focusable="false" role="img">
        ${type === 'loading' ? LOADER : `<use xlink:href="${Zebra.iconsSvgUrl}#${typeObj.icon}"/>`}
      </svg>
    </div>
    <div class="media-body media-middle">
      ${text}
    </div>
  </div>
</li>
`);

    if (persistant) {
      this.getCloseButton($item).prependTo($item.find('.media-body'));
    }

    $item.appendTo(this.$el).slideDown('fast');

    if (!typeObj.persistant && !options.persistant) {
      $item.timeout = setTimeout(() => {
        this.hide($item);
      }, options.hideDelay);
    }

    this.notifications.push($item);

    return $item;
  }

  /**
   * Clear the notifications list and destroy the container
   */
  clear() {
    if (this.$el) {
      this.$el.slideUp('fast', function () {
        $(this).remove();
      });
    }
    this.$el = null;
    this.notifications.length = 0;
  }
}
