<template>
  <div>
    <div class="h6 media-heading">{{ item.entity_name }}</div>
    <ul class="list-inline">
      <li v-for="(job, i) in user.liipch_job_title_names" :key="i">{{ job }}</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'UserItem',

  props: {
    item: {
      required: true,
    },
  },

  computed: {
    user() {
      return this.item.entity_object;
    },
  },
};
</script>
