import '@/js/utils/autoExpandTextarea';
import '@/js/utils/decodeHTMLEntities';
import '@/js/utils/getColorForString';
import '@/js/utils/getOSName';
import '@/js/utils/objectToURLSearchParams';
import '@/js/utils/formatter';
import '@/images/mascot.svg';
import issueTooltip from '@/js/utils/issueTooltip';

const issueElements = document.querySelectorAll('[data-issue-key]');
issueElements.forEach((element) => {
  issueTooltip(element);
});
