import Vue from 'vue';
import Vuex from 'vuex';
import getters from './getters.js';
import bills from './modules/entities/bills.js';
import timesheets from './modules/entities/timesheets.js';
import activities from './modules/entities/activities.js';
import users from './modules/entities/users.js';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    bills,
    timesheets,
    activities,
    users,
  },
  state: function () {
    return window.INITIAL_STATE || {};
  },
  getters,
});
