export default class Period {
  constructor() {
    $('.js-type-change').change(function () {
      const selectedVal = this.value;
      $('[data-hiddenif]').each(function () {
        if ($(this).data('hiddenif') === selectedVal) {
          $(this).slideUp();
        } else {
          $(this).slideDown();
        }
      });
      const $autoTimesheetGroup = $('.form-group.auto-timesheet');
      if ($autoTimesheetGroup.data('visible-types').includes(selectedVal)) {
        $autoTimesheetGroup.slideDown();
      } else {
        $autoTimesheetGroup.slideUp();
      }
    });

    $('.js-type-change,.js-status-change').change(() => {
      const selectedType = $('.js-type-change:checked').val();
      const selectedStatus = $('.js-status-change:checked').val();
      if (selectedType === 'normal' && selectedStatus !== 'employee') {
        $('.trainer_id').slideDown();
      } else {
        $('.trainer_id').slideUp();
      }
    });
    $('.js-type-change:checked,.js-status-change:checked').trigger('change');
  }
}
