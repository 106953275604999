import '@simonwep/pickr/dist/themes/nano.min.css';
import Pickr from '@simonwep/pickr';

export default class CirclePlanning {
  defaultPickrOptions = {
    theme: 'nano',
    components: {
      preview: true,
      hue: true,

      interaction: {
        input: true,
        save: true,
      },
    },
  };

  projectsPickr = [];

  constructor() {
    // Couldn't figure out how to catch `change` select2 event with vanilla js :sad:
    $('#project-to-add').on('change', (event) => {
      document.querySelector('.add-project-button').disabled = event.val === '';
    });
    $('#user-to-add').on('change', (event) => {
      document.querySelector('.add-user-button').disabled = event.val === '';
    });

    const el = document.querySelector('.set-all-projects-color-button');
    if (el) {
      const pickr = Pickr.create({ ...this.defaultPickrOptions, ...{ el } });

      pickr.on('save', async (color, instance) => {
        if (!confirm('Are you sure? It will update all the above projects’ colors!')) {
          instance.hide();
          return;
        }

        await $.post(el.dataset.url, { color: color.toHEXA().toString() });

        this.projectsPickr.forEach((projectPickr) => {
          projectPickr.setColor(color.toHEXA().toString(), true);
          projectPickr.applyColor(true);
        });

        instance.hide();
      });
    }

    document.querySelectorAll('.project-color').forEach((el) => {
      const pickr = Pickr.create({ ...this.defaultPickrOptions, ...{ el, default: el.dataset.value } });

      this.projectsPickr.push(pickr);

      pickr.on('save', async (color, instance) => {
        await $.post(el.dataset.url, { color: color.toHEXA().toString() });

        instance.default = color.toHEXA().toString();
        instance.hide();
      });
    });
  }
}
