/* eslint-disable object-shorthand */
/* eslint-disable max-params */
/* eslint-disable max-len */
/* eslint-disable camelcase */
export default class Invoices {
  constructor() {
    this.$invoiceID = $('#invoice-id');

    this.addEventListeners();
  }

  addEventListeners() {
    $('#generate-invoice').submit((e) => {
      e.preventDefault();
      this.printPDF(true, this.$invoiceID.val(), '', '');
    });

    $('#invoice-no-logo-btn').click((e) => {
      e.preventDefault();
      this.printPDF(false, this.$invoiceID.val(), '', '');
    });

    $('a.logo-link').click((e) => {
      e.preventDefault();
      this.printPDF(true, $(e.currentTarget).closest('td').find('.bill-invnumber').text(), '', '');
    });

    $('a.no-logo-link').click((e) => {
      e.preventDefault();
      this.printPDF(false, $(e.currentTarget).closest('td').find('.bill-invnumber').text(), '', '');
    });

    $('select.pdfs-links').change((e) => {
      const parts = $(e.currentTarget)
        .val()
        .split(',')
        .map((e) => e.trim());
      this.printPDF(parts[0]==='1', parts[1], parts[2], parts[3]);
    });
  }

  printPDF(logo, id, template, titlePrefix) {
    const url = new URL(`https://${window.location.hostname}/finance/rma-invoice/${id}/to-pdf`);
    url.searchParams.append('filename', titlePrefix + id);
    url.searchParams.append('withLogo', logo);
    if (template !== undefined && template !== '') {
      url.searchParams.append('rmaTemplate', template);
    }

    window.open(url, '_blank');
  }
}
