<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 20" class="loader">
    <polygon class="first" points="14.8 20 10.7 17.9 15.3 9 19.9 0 24 2.1 19.4 11 14.8 20" />
    <polygon class="second" points="4.1 20 0 17.9 4.7 9 9.3 0 13.4 2.1 8.8 11 4.1 20" />
  </svg>
</template>

<script>
export default {
  name: 'Loader',
};
</script>
