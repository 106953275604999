<template>
  <div>
    <!-- the property "append-to-body" allow to open this model over an existing modal, used for example during blip creation -->
    <modal :title="'Create new ' + title" append-to-body v-model="open" @hide="close()">
      <div v-if="!loading">
        <div class="form-group">
          <label class="control-label label-required">Name</label>
          <input type="text" class="form-control label-required" v-model="formData.name" required />
        </div>
        <div class="form-group">
          <label class="label-required">Category</label>
          <select v-model="formData.category" class="form-control" required v-if="filters.category.length > 1">
            <option v-for="(label, category) in categories" :value="category" :key="category" :disabled="!isCategoryAvailable(category)">
              {{ label }}
            </option>
          </select>
          <input v-else type="text" :name="filters.category[0]" :value="categories[filters.category[0]]" class="form-control" required disabled />
        </div>
        <div class="form-group">
          <label>Description</label>
          <textarea v-model="formData.description" class="form-control"></textarea>
          <p class="help-block">Just one or 2 lines</p>
        </div>
        <div class="form-group">
          <label>Website URL</label>
          <input type="url" v-model="formData.url" class="form-control" />
        </div>
      </div>
      <div v-else>
        <Loader></Loader>
      </div>
      <div slot="footer">
        <div v-if="!loading">
          <button class="btn btn-primary pull-right" @click="save()">Create</button>

          <button class="btn pull-right btn-default mrgr-" @click="close()">Cancel</button>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { Modal } from 'uiv';
import Loader from '../Loader';

export default {
  name: 'TechnologyCreation',

  components: { Loader, Modal },

  props: {
    title: {
      default: '',
    },
    name: {
      required: true,
    },
    filters: {
      default: () => {},
    },
  },

  mounted() {
    this.formData.name = this.name;
    this.fetchCategories();
  },

  data() {
    return {
      open: true,
      loading: false,
      categories: [],
      formData: {
        name: '',
        category: '',
        description: '',
        url: '',
      },
      createdTechnology: null,
    };
  },

  methods: {
    close() {
      this.open = false;
      this.$emit('close');
    },
    async fetchCategories() {
      this.categories = await this.$publicApi.get('/technologyCategories');
    },
    isCategoryAvailable(category) {
      return !('category' in this.filters) || this.filters.category.includes(category);
    },
    save() {
      this.loading = true;
      if (this.filters.category.length === 1) {
        this.formData.category = this.filters.category[0];
      }
      const tech = {
        name: this.formData.name,
        category: this.formData.category,
        description: this.formData.description,
      };
      if (this.formData.url.length > 0) {
        tech.description += `\n\n${this.formData.url}`;
      }
      this.createdTechnology = this.$publicApi
        .post('/technologies', tech)
        .then((entity) => {
          this.open = false;
          this.$emit('created', entity);
        })
        .catch(() => {
          // There is nothing to do as the error message is automatically displayed
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>

<style lang="scss"></style>
