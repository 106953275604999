<template>
  <Loader />
</template>

<script>
import Loader from './Loader';
export default {
  components: { Loader },
};
</script>
