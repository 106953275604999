/* eslint-disable object-shorthand */
/* eslint-disable max-params */
/* eslint-disable camelcase */
/* eslint-disable arrow-body-style */

import technologyTableCurrent from './templates/technologyTableCurrent';
import technologyTablePrevious from './templates/technologyTablePrevious';
import projectRow from './templates/projectRow';

export default class Product {
  constructor(productId, allTechno, productSelection, participationDetail) {
    this.currentProductId = productId;
    this.allTechno = allTechno;
    this.productSelection = Object.values(productSelection);
    this.participationDetail = participationDetail;

    this.initNewProjectDropdown();
    this.initRemoveProjectLink();
    this.initTechTables();
    this.initTableSorter();
    this.initSparklines();
  }

  // Init the add dropdown
  initNewProjectDropdown() {
    const $control = $('.add-project-field');

    $control.select2({
      placeholder: 'Search for Zebra project',
      minimumInputLength: 2,
      ajax: {
        url: `/product/${this.currentProductId}/search-project`,
        dropdownAutoWidth: true,
        data: function (term) {
          return { q: term };
        },
        results: (data) => {
          const formattedResults = [];

          for (const i in data) {
            const d = data[i];
            const result = {
              text: `${d.name} (${d.id})`,
              id: d.id,
            };

            if (d.product_id !== null) {
              result.disabled = true;
              if (d.product_id === this.currentProductId) {
                result.text += ' - Already selected';
                continue;
              } else {
                result.text += ` - Already used on: ${d.product_name}`;
              }
            }
            formattedResults.push(result);
          }

          return {
            results: formattedResults,
          };
        },
      },
    });

    $control.on('change', () => {
      this.addProject($control.val());
      $control.select2('val', '');
    });
  }

  // Init the remove link
  initRemoveProjectLink() {
    $('#projects').on('click', 'a.table-action-remove', function (e) {
      e.preventDefault();
      const $this = $(this);
      const $line = $this.closest('tr');
      $.post($this.attr('href'), () => {
        $line.fadeOut(() => {
          $line.remove();
        });
      });
    });
  }

  // Init the two tech tables
  initTechTables() {
    $('table.current-technologies').techTable({
      allTechnologies: this.allTechno,
      tableTechnologies: $(this.productSelection).filter((i, obj) => obj.archive_date === null),
      addUrl: `/technology/add-to-product/${this.currentProductId}`,
      postAdd: this.initLineWidgets.bind(this),
      newLineValues: {
        comment: '',
        category_id: 'other',
        expertise: 1,
      },
      template: technologyTableCurrent,
      extraLineData: {
        product_id: this.currentProductId,
      },
    });

    $('table.previous-technologies').techTable({
      allTechnologies: this.allTechno,
      tableTechnologies: $(this.productSelection).filter((i, obj) => obj.archive_date !== null),
      postAdd: this.initLineWidgets.bind(this),
      template: technologyTablePrevious,
      extraLineData: {
        product_id: this.currentProductId,
      },
      readOnly: true,
    });
  }

  initLineWidgets($line) {
    const techId = $line.closest('tr').data('tech-id');

    $line.find('.tech-adequacy-rating').ratingWidget({
      min: -3,
      max: 2,
      url: '/technology/update-product-adequacy',
      legend: (val) => {
        $(`.legend .adequacy li.val${val}`).text();
      },
      parameters: {
        technology_id: techId,
        product_id: this.currentProductId,
      },
    });

    // Custom init of edit in place for comment
    $line.find('.editable').editable({
      params: (params) => ({
        technology_id: techId,
        product_id: this.currentProductId,
        value: params.value,
      }),
      mode: 'popup',
      type: 'textarea',
    });

    $line.find('.table-action-move').on('click', function (e) {
      e.preventDefault();
      const $this = $(this);
      const $table = $this.closest('table');
      const direction = $table.hasClass('current-technologies') ? 'right' : 'left';
      const $line = $this.closest('tr');

      $.post($this.attr('href'), { direction: direction }, () => {
        // Add or remove the date
        if (direction === 'right') {
          const d = new Date();
          $line.prepend(`<td>${d.getDate()}.${d.getMonth() + 1}.${d.getFullYear()}</td>`);
        } else {
          $line.find('td:first').remove();
        }

        // Move
        $line.appendTo(direction === 'right' ? 'table.previous-technologies' : 'table.current-technologies');
      });
    });
  }

  // Init the sorter on the tables
  initTableSorter() {
    $('#projects').tablesorter({
      dateFormat: 'ddmmyyyy',
      sortList: [[3, 0]],
      headers: {
        4: { sorter: false },
        9: { sorter: false },
      },
      widgetOptions: {
        columns_tfoot: false,
      },
    });

    $('#product_liipers').tablesorter({
      dateFormat: 'ddmmyyyy',
      sortList: [[3, 0]],
    });
  }

  // Create the sparklines graphs
  initSparklines() {
    // Collaborator sparklines
    for (const userid in this.participationDetail) {
      $(`#sparkline_liiper_${userid}`).weekSparkline(this.participationDetail[userid]);
    }

    // Total sparkline
    const totals = {};
    let max = 40;

    $.each(this.participationDetail, (liiper_id, week_sums) => {
      $.each(week_sums, (week, hours) => {
        totals[week] = totals[week] || 0;
        totals[week] += hours;
      });
    });

    $.each(totals, (week, hours) => {
      max = Math.max(max, hours);
    });

    $('#sparkline_liiper_totals').weekSparkline(totals, {
      chart: {
        height: 30,
      },
      yAxis: {
        max: max,
      },
      series: [
        {
          color: '#aaa',
        },
      ],
    });
  }

  // Add a project to the list
  addProject(id) {
    $.post(
      `/product/${this.currentProductId}/add-project`,
      {
        project_id: id,
      },
      (data) => {
        $('table#projects tbody').append(projectRow(data));
        $('#noProjectNotice').remove();
      },
    );
  }
}
