/* eslint-disable object-shorthand */
/* eslint-disable max-params */
/* eslint-disable camelcase */
/* eslint-disable arrow-body-style */
export default class UserSettings {
  constructor() {
    // Animation around the token generation
    $('#generate-new-token').click(function (e) {
      e.preventDefault();
      $.post($(this).closest('form').attr('action'), (data) => {
        $('#api-token').fadeOut('fast', function () {
          $(this).text(data);
          $('#api-token').fadeIn('slow');
        });
      });
    });

    // Auto post choice when selecting a notification media choice
    $('input[name="selected-media"]').click(function () {
      $(this).closest('tr').find('form input[name="selected-media"]').prop('checked', false);
      $(this).prop('checked', true);
      const $form = $(this).closest('form');
      $.post($form.attr('action'), $form.serialize());
    });

    // Auto post choice when changing some checkboxes
    $('input[name="satisfaction_participation"], input[name="ldap_picture_sync"]').click(function () {
      $(this).closest('form').submit();
    });

    // Request the pipdrive status by ajax because pipedrive is sometime slow to answer
    $.ajax({
      url: '/user/settings/pipedrive-status',
      success: function (data) {
        $('#pipedrive_status').html(data);
      },
    });

    new Zebra.UserJobTitles();
  }
}
