export default class TimesheetsList {
  constructor({ routePrefix } = { routePrefix: '/timesheet' }) {
    $('.timesheet-delete').on('click', function () {
      const desc = $(this).closest('tr').find('.timesheet-description').text().trim();

      return confirm(`Are you sure you want to delete the item with the following description?

${desc}`);
    });

    function jumpToDate() {
      const chosenDate = $('#jumpToDateInput').datepicker('getDate').toLocaleDateString('en-ZA');
      window.location = `${routePrefix}/day/${chosenDate}`;
    }

    $('#jumpToDateInput')
      .datepicker({
        format: 'dd.mm.yyyy',
        autoclose: true,
        weekStart: 1,
        todayBtn: 'linked',
        clearBtn: true,
      })
      .on('changeDate', jumpToDate);

    $('#jumpToDateForm').on('submit', (e) => {
      e.preventDefault();
      jumpToDate();
    });

    $('#jump_to_user_selector').change(function () {
      const $selector = $(this);
      const url = $selector.data('url');
      window.location = url.replace('user_id', $selector.val());
    });

    Zebra.shortcut.register('g', {
      label: 'Display date picker',
      action: () => {
        $('#jumpToDateInput').trigger('focus');
      },
      category: 'Navigation',
      hint: false,
    });
  }
}
