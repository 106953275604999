import _ from 'lodash';

export default class MergeTable {
  selectionId = null;

  constructor(model) {
    this.model = model;
    this.updatePreview();
    this.updateTitleAndLink();

    $('select[name=into]').on('change', () => {
      this.updateTitleAndLink();
      this.loadNewDestination();
    });
  }

  getSelection() {
    return {
      id: parseInt($('select[name=into]').val()),
      name: $('select[name=into] :selected').text(),
    };
  }

  updateTitleAndLink() {
    const destination = this.getSelection();
    const $switchLink = $('.switch-link');

    if (!destination.id) {
      $switchLink.attr('href', '#').attr('disabled', true);
      $('#merge').attr('disabled', true);
      return;
    }

    const destinationPath = `/${location.pathname.split('/')[1]}/${destination.id}`;
    $('.result.name').html(`<a href="${destinationPath}">${destination.name}</a>`);
    $('.destination.title').html(destination.name);

    $switchLink.attr('href', `${destinationPath}/merge?into=${$switchLink.data('merge-into')}`).attr('disabled', false);

    $('#merge').attr('disabled', false);
  }

  updatePreview() {
    const $rows = $(`#${this.model}-merge-table tr`);

    $.each($rows, (i, el) => {
      const $el = $(el);
      const operation = $el.data('merge-operation');
      const current = $el.find('.current').html();
      const destination = $el.find('.destination').html();

      if (operation === 'replace') {
        $el.find('.preview').html(destination.length ? destination : current);
      }
      if (operation === 'addition') {
        $el.find('.preview').html(parseInt(current, 10) + parseInt(destination, 10));
      }
    });
  }

  loadNewDestination() {
    const destination = this.getSelection();

    if (!destination.id) {
      return;
    }

    $.get(`/api/v2/${this.model}/${destination.id}`).done((response) => {
      const destination = response.data;
      $.each($(`#${this.model}-merge-table tr`), (i, el) => {
        const $this = $(el);
        let value = destination[$this.data('merge-field')];
        value = _.isObjectLike(value) ? _.size(value) : value;
        $this.find('.destination').html(value);
      });
      this.updatePreview();
    });
  }

  initObserver() {
    const callback = (mutationsList) => {
      for (const mutation of mutationsList) {
        if (mutation.type !== 'attributes' || mutation.attributeName !== 'value') {
          continue;
        }

        this.selectionId = mutation.target.value;
        this.updateTitleAndLink();
        this.loadNewDestination();
      }
    };

    return new MutationObserver(callback);
  }
}
