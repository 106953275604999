export default class RecurringBill {
  constructor() {
    const $form = $('.recurring-bill-form');

    function loadExample() {
      const f = $('input[name="frequency"]:checked', $form).val();
      const d = $('input[name="next_creation_date"]', $form).val();
      const b = $('#bill-template-id', $form).val();
      const $exampleDiv = $('.example', $form);
      if (f && d && b) {
        $.post('/project/recurring-bill-config/preview', { frequency: f, next_date: d, bill_id: b }, (data) => {
          $exampleDiv.text(data);
        });
      } else {
        $exampleDiv.text('please fill the form first...');
      }
    }

    // Require a load at page load + at any form change
    loadExample();
    $('#bill-template-id, input[name="frequency"], #next-creation-date', $form).change(loadExample);
  }
}
