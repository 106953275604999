/* eslint-disable object-shorthand */
/* eslint-disable max-params */
/* eslint-disable camelcase */
import getColorForString from '@/js/utils/getColorForString';

export default class TimesheetsReport {
  constructor() {
    $('#user_by_user, #user_by_group, #custom, #internal, #external, #date-selection-from-to, #date-selection-period').click(
      this.updateForm.bind(this),
    );

    // Allow to highlights projects activities with a dedicated optgroup
    const $activities = $('#activities');
    $activities.append('<optgroup id="project-activities" label="Selected project(s) activities"></optgroup>');
    $activities.append('<optgroup id="all-activities" label="All activities"></optgroup>');
    $activities.find('option').appendTo('#all-activities');
    $('#projects').on('change', this.updateForm.bind(this));

    this.updateForm();
    this.initHighCharts();
  }

  updateForm() {
    const $projects = $('#projects');
    const $projectActivities = $('#project-activities');

    // user selection
    if (document.querySelector('#user_by_user').checked) {
      $('#group').addClass('hidden');
      $('#users').removeClass('hidden');
    } else {
      $('#group').removeClass('hidden');
      $('#users').addClass('hidden');
    }

    // date selection
    const $filterByPeriod = document.querySelector('#date-selection-period').checked;
    document.querySelector('#date-period-inputs').classList.toggle('hidden', !$filterByPeriod);
    document.querySelector('#date-from-to-inputs').classList.toggle('hidden', $filterByPeriod);

    // project selection
    const customProjectChecked = document.querySelector('#custom').checked;
    if (customProjectChecked) {
      $projects.removeClass('hidden');
      $projectActivities.removeClass('hidden');
    } else {
      $projects.addClass('hidden');
      $projectActivities.addClass('hidden');
    }

    //  activity proposal based on projects
    const projectIds = $projects.val() || [];
    if (projectIds.length > 0 && customProjectChecked) {
      $projectActivities.removeClass('hidden');
      $.get('/api/v2/activities', { project_ids: projectIds }, (data) => {
        $projectActivities.empty();
        $.each(data.data.list, (key, value) => {
          $projectActivities.append(`<option value="${value.id}">${value.name} (${value.rate})</option>`);
        });
        $('#activities').trigger('change');
      });
    } else {
      $projectActivities.addClass('hidden');
    }
  }

  initHighCharts() {
    $('.graphHours').each(function (index, elem) {
      const graphType = elem.classList[1].split('By')[1];
      const $table = $(`#totalBy${graphType}`);
      const data = [];

      $table.find('tbody tr').each(function (index) {
        if (index + 1 !== $table.find('tbody tr').length) {
          const hrefExploreLink = $(this).find('td:nth-child(3) a').attr('href');

          data.push({
            name: $(this).find('td:nth-child(1)').text(),
            y: parseFloat($(this).find('td:nth-child(2)').text()),
            color: getColorForString($(this).find('td:nth-child(1)').text()),
            events: {
              click: function () {
                window.location.href = hrefExploreLink;
              },
            },
          });
          $(this)
            .find('td:nth-child(4)')
            .css('background-color', getColorForString($(this).find('td:nth-child(1)').text()));
        }
      });

      $(this).highcharts({
        chart: {
          type: 'pie',
          spacing: [0, 0, 0, 0],
          height: 250,
        },
        title: { text: null },
        tooltip: {
          pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
          style: {
            width: 200,
          },
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: false,
            },
          },
        },
        series: [
          {
            name: 'Percentage',
            colorByPoint: true,
            data: data,
          },
        ],
        credits: false,
      });
    });
  }
}
