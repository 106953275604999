export default class ProductsList {
  constructor() {
    // Init change category form
    $('.category-value').on('click', function () {
      $(this).hide().siblings('.category-value-form').show();
    });

    $('.category-value-form select').on('change', function () {
      const $form = $(this).closest('form');

      $.post($form.attr('action'), $form.serialize(), (data) => {
        $form.hide().siblings('.category-value').show().text(data);
      });
    });
  }
}
