import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {}

  disable(e) {
    const icon = e.target.querySelector('svg > use');
    if ((e.target.getAttribute('data-strict') || '0') === '0' && e.target.getAttribute('data-clicked') === '1') {
      return confirm('Do you want to resubmit?');
    }
    e.target.classList.add('disabled');
    e.target.setAttribute('data-clicked', 1);
    const iconSvg = e.target.querySelector('svg ');

    // replace icon with spinning hourglass
    if (icon) {
      const href = icon.getAttribute('xlink:href');
      const splithref = href.split('#');
      splithref[1] = 'hourglass';
      const newhref = splithref.join('#');
      icon.setAttribute('xlink:href', newhref);
      iconSvg.classList.add('spinner');
    }

    if (e.target.getAttribute('data-backdrop') === '1') {
      // Add a fullpage loader backdrop
      const elemDiv = document.createElement('div');
      elemDiv.id = 'loader-backdrop';
      elemDiv.innerHTML =
        '<div class="dancing-mascot"><img src="/static/dist/images/mascot.svg"/></div>Please wait, some magic is happening in the background 🧚🏽';
      document.body.appendChild(elemDiv);
    }

    return true;
  }
}
