<template>
  <div>
    <div class="h5">{{ breadcrumb }}</div>
    <div class="text-small">{{ firstMatch[0] }}</div>
  </div>
</template>

<script>
export default {
  name: 'DocumentationItem',

  props: {
    item: {
      required: true,
    },
    currentSearch: {
      required: false,
    },
  },

  computed: {
    firstMatch() {
      const text = this.item.content;
      const search = this.currentSearch;
      const regexp = new RegExp(`.*(${search}).*`, 'mi');

      return text.match(regexp) || '';
    },

    breadcrumb() {
      const breadcrumb = this.item.entity_name.split('/');

      return breadcrumb.join(' > ');
    },
  },
};
</script>
