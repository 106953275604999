export default class ChartsFrame {
  constructor() {
    $('.js-charts-url').focus(function () {
      $(this).select();
    });
    $('.js-show-charts-url-button').click(this.showChartsUrl);
  }

  showChartsUrl() {
    $('.js-charts-url').css({ visibility: 'visible' });
    $('.js-charts-url').val(document.getElementById('js-charts-iframe').contentDocument.location.href);
  }
}
