/* eslint-disable object-shorthand */
/* eslint-disable max-params */
/* eslint-disable camelcase */
import { initExpertiseRatingWidget, initFeelingRatingWidget, initEditableComment } from '@/legacy/helpers/widgets';

export default class TechProfile {
  constructor(allTechno, userSelection, readOnly, newDefaults) {
    this.readOnly = readOnly;

    $('table.user-technologies')
      .techTable({
        allTechnologies: allTechno,
        tableTechnologies: userSelection,
        addUrl: '/technology/add-to-user',
        readOnly: this.readOnly,
        postAdd: this.initLineWidgets.bind(this),
        newLineValues: newDefaults,
      })
      .tablesorter();

    if (!this.readOnly) {
      this.initTechnologiesSuggestionEvents();
    }

    // Init the archived table
    const archivedRows = Array.from(document.querySelectorAll('#archived-table tbody tr'));
    archivedRows.map((row) => {
      initExpertiseRatingWidget($(row), '.tech-expertise-rating', true);
      initFeelingRatingWidget($(row), '.tech-feeling-rating', true);
    });
  }

  initLineWidgets($line) {
    initExpertiseRatingWidget($line, '.tech-expertise-rating', this.readOnly);
    initFeelingRatingWidget($line, '.tech-feeling-rating', this.readOnly);
    initEditableComment($line, '.tech-comment', this.readOnly);
  }

  initTechnologiesSuggestionEvents() {
    $('.suggestion-add').on('click', function (e) {
      e.preventDefault();

      const $techno = $(this).closest('[data-techno-id]');
      const id = $techno.data('techno-id');

      Zebra.addTechnoToTechTable(id);

      $(`[data-techno-id="${id}"]`).remove();
    });

    $('.suggestion-ignore').on('click', function (e) {
      e.preventDefault();

      const $techno = $(this).closest('[data-techno-id]');
      const technoId = $techno.data('techno-id');

      $.post(`/technology/${technoId}/ignore-suggestion`, {}, () => {
        $(`[data-techno-id="${technoId}"]`).remove();
      });
    });
  }
}
