/* eslint-disable object-shorthand */
/* eslint-disable max-params */
/* eslint-disable camelcase */
$(() => {
  // Define some global behavior while interacting with the server
  $(document).ajaxError((event, jqXHR, settings, thrownError) => {
    // Do no warn when there is a message from the backend, it will be done in ajaxComplete
    if (jqXHR.getResponseHeader('X-flash-messages')) {
      return;
    }

    // Some error can be hidden by the backend
    const json = jqXHR.responseJSON;
    if (json && 'notification_center' in json && json.notification_center === 'hidden') {
      return;
    }

    const message = jqXHR.responseText || thrownError || 'An error occurred.';

    // Do not warn user about requests being aborted
    // It usually doesn’t make sense as a new request will be sent right after
    if (message === 'abort') {
      return;
    }

    Zebra.notificationsCenter.show('error', message);
  });

  $(document).ajaxComplete((event, jqXHR) => {
    const messages = [];

    // Read messages from the dedicated HTTP header
    const header = jqXHR.getResponseHeader('X-flash-messages');
    if (header !== null) {
      messages.push(...jQuery.parseJSON(header));
    }

    // Read message from the API v2 JSON response
    if (jqXHR.responseJSON !== undefined && 'messages' in jqXHR.responseJSON) {
      messages.push(...jqXHR.responseJSON.messages);
    }

    $.each(messages, function () {
      Zebra.notificationsCenter.show(this.type, this.text, { persistant: this.persistant, isHTML: this.isHTML });
    });
  });
});
