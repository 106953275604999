/* eslint-disable arrow-body-style */
import { initExpertiseRatingWidget, initFeelingRatingWidget, initEditableComment } from '@/legacy/helpers/widgets';

export default class Technology {
  constructor(technologyId, trendsData) {
    this.trendsData = trendsData;

    this.initTrendsGraph();
    this.initTableSorters();
    this.initForm();
    this.initWidgets();
  }

  initWidgets() {
    const technoRows = Array.from(document.querySelectorAll('#technology-liipers tbody tr'));

    technoRows.map((row) => {
      const isCurrentUser = row.getAttribute('data-current-user');

      initExpertiseRatingWidget($(row), '.tech-expertise-rating', !isCurrentUser);
      initFeelingRatingWidget($(row), '.tech-feeling-rating', !isCurrentUser);
      initEditableComment($(row), '.tech-comment', !isCurrentUser);
    });
  }

  initForm() {
    $('#technology-categorisation form').submit(function (event) {
      const $this = $(this);
      event.preventDefault();
      $.ajax({
        url: $this.attr('action'),
        type: 'POST',
        data: $this.serialize(),
      });
    });

    $('#technology-categorisation form #category')
      .change(function () {
        const cat = $(this).val();
        $('.technology-categorisation-form-item[data-for-category]').each(function () {
          $(this).toggle($(this).data('for-category') === cat);
        });
      })
      .trigger('change');
  }

  initTableSorters() {
    $('#technology-liipers').tablesorter({
      sortList: [[2, 1]],
      dateFormat: 'ddmmyyyy',
    });

    $('#technology-products-active').tablesorter({
      dateFormat: 'ddmmyyyy',
      sortList: [[0, 0]],
      headers: { 2: { sorter: false } },
    });

    $('#technology-products-removed').tablesorter({
      dateFormat: 'ddmmyyyy',
      sortList: [[0, 0]],
      headers: { 3: { sorter: false } },
    });
  }

  initTrendsGraph() {
    $('#trends-container').highcharts({
      chart: {
        alignTicks: false,
      },
      xAxis: {
        categories: this.trendsData.months,
      },
      yAxis: [
        {
          /* Feeling and Adequacy */
          min: -2.0,
          max: 2.0,
          gridLineWidth: 0,
          title: {
            text: 'Feeling and adequacy',
            style: {
              color: '#ce5d5a',
            },
          },
          labels: {
            formatter: () => this.value,
            style: {
              color: '#ce5d5a',
            },
          },
        },
        {
          /* Product axis */
          min: 0,
          gridLineWidth: 0,
          title: {
            text: 'Product usage',
            style: {
              color: '#68ac68',
            },
          },
          labels: {
            style: {
              color: '#68ac68',
            },
          },
        },
        {
          /* Expertise axis */
          min: 0,
          labels: {
            style: {
              color: '#e2ab5c',
            },
          },
          title: {
            text: 'Expertise',
            style: {
              color: '#e2ab5c',
            },
          },
          opposite: true,
        },
      ],
      title: null,
      legend: {
        layout: 'horizontal',
        align: 'center',
        verticalAlign: 'bottom',
        borderWidth: 0,
      },
      plotOptions: {
        series: {
          marker: {
            enabled: false,
          },
        },
      },
      series: [
        {
          name: 'Common expertise',
          type: 'spline',
          data: this.trendsData.expertise,
          color: '#e2ab5c',
          yAxis: 2,
        },
        {
          name: 'Avg. feelings',
          type: 'spline',
          data: this.trendsData.feeling,
          yAxis: 0,
          color: '#ce5d5a',
        },
        {
          name: 'Avg. project adequacy',
          type: 'spline',
          data: this.trendsData.adequacy,
          yAxis: 0,
          color: '#6ab8cf',
        },
        {
          name: 'Product usage',
          type: 'spline',
          data: this.trendsData.product_count,
          yAxis: 1,
          color: '#68ac68',
        },
      ],
      credits: {
        enabled: false,
      },
    });
  }
}
