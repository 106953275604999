jQuery.fn.highlight = function () {
  return $(this)
    .addClass('highlight')
    .each(function () {
      setTimeout(
        ($el) => {
          $el.removeClass('highlight');
        },
        2000,
        $(this),
      );
    });
};
