export default class BillRead {
  constructor() {
    // We init the editable from here instead of the basic editable, because we need to display
    // the pdf-outdated-alert-box in case of success
    $('.editable-metadata-fields').each(function () {
      const $element = $(this);
      const elementId = $element.attr('id');

      let editableOption = {};

      if (elementId === 'language') {
        editableOption = {
          value: $element.html().toLowerCase(),
          source: [
            { text: 'English', value: 'en' },
            { text: 'French', value: 'fr' },
            { text: 'German', value: 'de' },
          ],
          display(value, sourceData) {
            $.grep(sourceData, (o) => o.value === value);
          },
          success(response, newValue) {
            $('#pdf-outdated-alert-box').removeClass('hide');
            $(this).html(newValue.toUpperCase());
          },
        };
      }

      $element.editable(editableOption);
    });
  }
}
