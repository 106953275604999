/* eslint-disable object-shorthand */
/* eslint-disable max-params */
/* eslint-disable camelcase */
/* eslint-disable arrow-body-style */
/**
 * A week sparkline visualization widget
 *  based on highcharts.js
 */
$(() => {
  $.fn.weekSparkline = function (data, options) {
    const defaults = {
      chart: {
        type: 'column',
        height: 24,
        margin: [0, 0, 0, 0],
        backgroundColor: 'transparent',
      },
      title: { text: '' },
      credits: { enabled: false },
      xAxis: {
        labels: { enabled: false },
        categories: $.map(data, (value, index) => [index]),
      },
      yAxis: {
        maxPadding: 0,
        minPadding: 0,
        labels: { enabled: false },
        max: 40,
      },
      legend: { enabled: false },
      tooltip: {
        useHTML: true,
        borderWidth: 0,
        style: { padding: 0 },
        hideDelay: 0,
        formatter: function () {
          const dateParts = this.x.split('.');
          return `${dateParts[0]} Week ${dateParts[1]}: ${this.y}h`;
        },
      },
      series: [
        {
          data: $.map(data, (value) => [value]),
          pointPadding: 0,
          groupPadding: 0,
          borderWidth: Object.keys(data).length < 120 ? 1 : 0,
          shadow: false,
          color: '#68528f',
        },
      ],
    };
    options = options || {};
    $(this).highcharts($.extend(true, defaults, options));
  };
});
