import Xhr from '@/vue/services/xhr';

export default class ActivityForm {
  constructor(currentActivityId) {
    this.currentActivityId = currentActivityId;
    this.$budgetInput = $('input[name="budget"]');
    this.$hourlyRate = $('input[name="hourly_rate"]');
    this.$cappedGroup = $('.capped-form-group');
    this.$budgetThresholdGroup = $('.budget-threshold-form-group');
    this.$aliasInput = $('#alias');

    this.updateForm();
    this.addEventListeners();
    this.allowAliasTakeOver();
  }

  addEventListeners() {
    this.$budgetInput.add(this.$hourlyRate).on('keyup', this.updateForm.bind(this));
    this.$aliasInput.on('blur', this.allowAliasTakeOver.bind(this));
  }

  updateForm() {
    this.$budgetInput.WDEquivalentHelper({
      rate: this.$hourlyRate.val(),
    });

    this.$cappedGroup.toggle(this.$budgetInput.val() > 0);
    this.$budgetThresholdGroup.toggle(this.$budgetInput.val() > 0);
  }

  // Check if the alias is already used and allow to take over by displaying a specific part of the form
  allowAliasTakeOver() {
    const currentActivityId = this.currentActivityId;
    const alias = this.$aliasInput.val();
    function hideTakeOverFormOption() {
      $('.alias-transfer-options .warning-message').html('');
      $('.alias-transfer-options').addClass('hidden');
    }

    // No alias, no issue
    if (alias === '') {
      hideTakeOverFormOption();
      return;
    }

    Xhr.getAPIInstance(false)
      .get(`/activities/${alias}`)
      .then((response) => {
        const existing = response.data.data;
        // If it's the current activity, nothing to do
        if (currentActivityId !== null && parseInt(existing.id) === currentActivityId) {
          hideTakeOverFormOption();
          return;
        }
        // Show the takeover option
        const htmlMsg = `⚠️ Alias <strong>${alias}</strong> is currently used on activity
           <a href="/project/${existing.project_id}#activity-${existing.activity_id}" target="_blank">
              ${existing.project_name}: ${existing.name}
           </a>. Please confirm you would like to take it over`;
        $('.alias-transfer-options .warning-message').html(htmlMsg);
        $('.alias-transfer-options').removeClass('hidden');
        $('#alias-transfer-confirmation').focus();
      })
      .catch((error) => {
        if (error.response.status !== 404) {
          throw error;
        }
        hideTakeOverFormOption();
      });
  }
}
