import { format, differenceInDays } from 'date-fns';
import { toCHF, toHours } from '@/js/utils/formatter';
import Vue from 'vue';

Vue.filter('date', (value, formatting = 'DD.MM.YYYY') => format(value, formatting));

Vue.filter('daysLeft', (value) => {
  if (typeof value !== Date) {
    value = new Date(value);
  }
  return differenceInDays(value, new Date());
});

Vue.filter('toCHF', (value) => toCHF(value));

Vue.filter('toHours', (hours) => toHours(hours));
