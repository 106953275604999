/**
 * Common vendors
 */
import * as Sentry from '@sentry/browser';

if (!Zebra.debug) {
  if (Zebra.currentUserEmail && Zebra.currentUserId) {
    Sentry.setUser({ id: Zebra.currentUserId, email: Zebra.currentUserEmail });
  }
  Sentry.init({
    dsn: Zebra.sentryUrl,
    environment: Zebra.env,

    // We can safely ignore this error. More info here: https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded
    ignoreErrors: [/^ResizeObserver loop limit exceeded$/, /^ResizeObserver loop completed with undelivered notifications.$/],

    beforeSend(event, hint) {
      const error = hint.originalException;

      // We do not report XHR with 400 response code, as those are validation issue, already handle by our JS
      if (error && error.request && error.request.status && error.request.status === 400) {
        return null;
      }
      return event;
    },
  });
}

// Import and expose jQuery in the global context
import $ from 'expose-loader?exposes=$,jQuery!jquery';
import '@/legacy/common/serverManager';

// Table sorter
import 'tablesorter/dist/js/jquery.tablesorter';
import 'tablesorter/dist/js/widgets/widget-filter.min';
import 'tablesorter/dist/js/widgets/widget-storage.min';
import 'tablesorter/dist/js/widgets/widget-stickyHeaders.min';
import 'tablesorter/dist/js/widgets/widget-saveSort.min';
import 'tablesorter/dist/js/widgets/widget-cssStickyHeaders.min';
import 'tablesorter/dist/js/widgets/widget-filter-formatter-select2.min';
import './common/tablesorter-widget-zebra-export';

// Bootstrap UI components
import 'bootstrap-sass/assets/javascripts/bootstrap/transition';
import 'bootstrap-sass/assets/javascripts/bootstrap/collapse';
import 'bootstrap-sass/assets/javascripts/bootstrap/dropdown';
import 'bootstrap-sass/assets/javascripts/bootstrap/tooltip';
import 'bootstrap-sass/assets/javascripts/bootstrap/popover';
import 'bootstrap-sass/assets/javascripts/bootstrap/modal';
import 'bootstrap-sass/assets/javascripts/bootstrap/tab';

// Highcharts with modules
import Highcharts from 'highcharts';
import Exporting from 'highcharts/modules/exporting';
Exporting(Highcharts);
Highcharts.setOptions({
  exporting: {
    enabled: false,
  },
});

// Misc
import 'select2';

import 'bootstrap-datepicker';

/**
 * Application common modules
 */
import '@/scss/zebra.scss';
import '@/legacy/ui';

import NotificationsCenter from '@/legacy/common/NotificationsCenter';
Zebra.notificationsCenter = new NotificationsCenter();

import ViewMore from '@/legacy/common/ViewMore';
Zebra.viewMore = new ViewMore();

import Modal from '@/legacy/common/Modal';
Zebra.Modal = Modal;

import ShortcutManager from '@/legacy/common/ShortcutManager';
Zebra.shortcut = new ShortcutManager();
Zebra.shortcut.init();

/**
 * Page specific modules
 */
import SanityCheck from '@/legacy/apps/timesheets/SanityCheck';
Zebra.SanityCheck = SanityCheck;

import Timesheet from '@/legacy/apps/timesheets/Timesheet';
Zebra.Timesheet = Timesheet;

import TimesheetsList from '@/legacy/apps/timesheets/TimesheetsList';
Zebra.TimesheetsList = TimesheetsList;

import TimesheetsReport from '@/legacy/apps/timesheets/TimesheetsReport';
Zebra.TimesheetsReport = TimesheetsReport;

import TimeAccount from '@/legacy/apps/timesheets/TimeAccount';
Zebra.TimeAccount = TimeAccount;

import Product from '@/legacy/apps/products/Product';
Zebra.Product = Product;

import ProductsList from '@/legacy/apps/products/ProductsList';
Zebra.ProductsList = ProductsList;

import Project from '@/legacy/apps/projects/Project';
Zebra.Project = Project;

import SalaryEvaluation from '@/legacy/apps/salary/Evaluation';
Zebra.SalaryEvaluation = SalaryEvaluation;

import SalaryUpdateForm from '@/legacy/apps/salary/SalaryUpdateForm';
Zebra.SalaryUpdateForm = SalaryUpdateForm;

import SalaryList from '@/legacy/apps/salary/List';
Zebra.SalaryList = SalaryList;

import ActivityForm from '@/legacy/apps/activities/ActivityForm';
Zebra.ActivityForm = ActivityForm;

import ActivityRateStats from '@/legacy/apps/activities/ActivityRateStats';
Zebra.ActivityRateStats = ActivityRateStats;

import Tracking from '@/legacy/apps/Tracking';
Zebra.Tracking = Tracking;

import Technology from '@/legacy/apps/technologies/Technology';
Zebra.Technology = Technology;

import TechnologyTrends from '@/legacy/apps/technologies/TechnologyTrends';
Zebra.TechnologyTrends = TechnologyTrends;

import TechProfile from '@/legacy/apps/user/TechProfile';
Zebra.TechProfile = TechProfile;

import Invoices from '@/legacy/apps/Invoices';
Zebra.Invoices = Invoices;

import Circle from '@/legacy/apps/holacracy/Circle';
Zebra.Circle = Circle;

import CircleComposition from '@/legacy/apps/holacracy/CircleComposition';
Zebra.CircleComposition = CircleComposition;

import CirclePlanning from '@/legacy/apps/holacracy/CirclePlanning';
Zebra.CirclePlanning = CirclePlanning;

import CircleWorkExperience from '@/legacy/apps/holacracy/CircleWorkExperience';
Zebra.CircleWorkExperience = CircleWorkExperience;

import UserSettings from '@/legacy/user/UserSettings';
Zebra.UserSettings = UserSettings;

import UserJobTitles from '@/legacy/user/UserJobTitles';
Zebra.UserJobTitles = UserJobTitles;

import UserWorkContract from '@/legacy/user/UserWorkContract';
Zebra.UserWorkContract = UserWorkContract;

import BillRead from '@/legacy/apps/bills/BillRead';
Zebra.BillRead = BillRead;

import Period from '@/legacy/apps/periods/Period';
Zebra.Period = Period;

import RecurringBill from '@/legacy/apps/bills/RecurringBill';
Zebra.RecurringBill = RecurringBill;

import ChartsFrame from '@/legacy/apps/charts/ChartsFrame';
Zebra.ChartsFrame = ChartsFrame;

import OfferRead from '@/legacy/apps/projects/OfferRead';
Zebra.OfferRead = OfferRead;

import AutoUpdate from '@/legacy/apps/autoUpdate/AutoUpdate';
Zebra.AutoUpdate = AutoUpdate;

import AutoUpdateSettings from '@/legacy/apps/autoUpdate/AutoUpdateSettings';
Zebra.AutoUpdateSettings = AutoUpdateSettings;

import SatisfactionSurveyEvaluation from '@/legacy/apps/SatisfactionSurveyEvaluation/SatisfactionSurveyEvaluation';
Zebra.SatisfactionSurveyEvaluation = SatisfactionSurveyEvaluation;

import MergeTable from '@/legacy/apps/MergeTable';
Zebra.MergeTable = MergeTable;

import HoursBreakdown from '@/legacy/apps/activities/HoursBreakdown';
Zebra.HoursBreakdown = HoursBreakdown;

import DocumentAcknowledgement from '@/legacy/apps/DocumentAcknowledgement';
Zebra.DocumentAcknowledgement = DocumentAcknowledgement;

// allow it's use on -> product/:id/broadcast-email
import ClipboardJS from 'clipboard/dist/clipboard';
Zebra.Clipboard = ClipboardJS;

// Fix select2 required
// https://stackoverflow.com/a/35470951
$('form select').each(function () {
  this.addEventListener(
    'invalid',
    (e) => {
      const select2Id = `s2id_${e.target.id}`;
      const select2El = $(`#${select2Id}`);
      if (select2El && `${e.target.id}`) {
        $(`#${e.target.id}`).attr('style', `z-index:-1;margin-top:${select2El.position().top - select2El.outerHeight()}px;`);
      }
      return true;
    },
    false,
  );
});

$('.markdown-check').each(function () {
  localStorage.getItem(`markdown-check-${this.id}`) === 'true' && $(this).prop('checked', true);
  $(this).on('change', function () {
    localStorage.setItem(`markdown-check-${this.id}`, $(this).prop('checked') ? 'true' : 'false');
  });
});
